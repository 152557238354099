import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../../css/products.css'
import { useGlobalContext } from '../../../hooks/useGlobalContext'
import config from '../../../config';

function AddTag( {tagType, title, checkedTags, setCheckedTags} ) {

  const {user, tagsDispatch} =  useGlobalContext()

  const [Name, setNewTagName] = useState("")
  const [error, setError] = useState(null)

  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  const addTag = async () => {

    const newTag = {
      Name: Name,
      tagType:tagType
    }
    
    const response = await fetch(`${config.BASE_URL}/api/tags`, {
      method: 'POST',
      body: JSON.stringify(newTag),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()
    if(!response.ok){
      setError(json.error)
    }
    if(response.ok){
      tagsDispatch({type: 'CREATE_TAG', payload: json})
      
      const addTag = {
        name:Name,
        id:json._id,
        tagType:tagType
      }
      setCheckedTags([...checkedTags, addTag])
      
      setNewTagName('')
      
      setError(null)
      handleClose()
    }


  }

  return (
    <>
      <div className="btnAdd" onClick={handleShow}>+</div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <input 
          type="text"
          className="inputCategory"
          value={Name}
          onChange={(e)=>{
            setNewTagName(e.target.value)
          }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={addTag}>Ajouter</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTag