import { useEffect, useState } from "react";
import orderIcon from "../../imgs/launchpad/commandes.png";
import projetIcon from "../../imgs/launchpad/projet.png";
import { Link } from "react-router-dom";

const OrderCard = ({ order }) => {
    const [daysUntillPickup, setDaysUntillPickup] = useState(0);

    useEffect(() => {
        console.log("Order set total Days: ", order);

        // Calculate the difference between current date and dateDepart
        const currentDate = new Date();
        const pickupDate = new Date(order.dateDepart);
        const timeDifference = pickupDate - currentDate;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        setDaysUntillPickup(daysDifference);
    }, [order.dateDepart]);

    useEffect(() => {
        console.log("Order PLease : ", order)
    },[])

    // Function to format the createdAt date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        
        <div className="col">
            <Link to={`/Commandes/${order._id}`}>
                <div className="card order-card">
                    <div className="numero-row">
                        <img src={orderIcon} alt="" />
                        <div>
                            <div className="numero">Commande numéro</div>
                            <div className="texte">{order.Numero}</div>
                        </div>
                    </div>
                    <div className="card-spacer"></div>
                    <div className="projet-order">
                        <img src={projetIcon} height="60" alt="" />
                        <div style={{ marginLeft: "20px" }}>
                            <div style={{ fontSize: "16px", fontWeight: "800" }}>
                                {order.Projet.Nom}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                                {order.Projet.projectType.Name}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                                {order.Projet.Compagnie.companyName}
                            </div>
                        </div>
                    </div>
                    <div className="card-spacer"></div>
                    {order.commandePour.client &&
                    <div className="projet-client">
                    <img src={order?.commandePour.client.avatar} height="60" alt="" />
                    <div style={{ marginLeft: "20px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "800" }}>
                            {order?.commandePour.client.userFirstName}{" "}
                            {order?.commandePour.client.userLastName}
                        </div>
                        <div style={{ fontSize: "12px" }}>{order.commandePour.poste}</div>
                        <div style={{ fontSize: "12px" }}>
                            {order?.commandePour.client.userCell}
                        </div>
                    </div>
                </div>
                    }
                    <div className="card-spacer"></div>
                    <div>
                        <div className="text-center" style={{ fontWeight: "800" }}>
                            Statut: {order?.statut}
                        </div>
                        <div className="text-center" style={{ fontSize: "14px" }}>
                            Créer le : {formatDate(order.createdAt)}
                        </div>
                        <div className="text-center" style={{ fontSize: "14px" }}>
                            Date de départ : {formatDate(order.dateDepart)}
                        </div>
                        <div className="text-center" style={{ fontSize: "14px" }}>
                            Départ dans : {daysUntillPickup} jours
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default OrderCard;



/*

<div className="col">
            <Link to={`/Commandes/${order._id}`}>
                <div className="card order-card">
                    <div className="numero-row">
                        <img src={orderIcon} alt="" />
                        <div>
                            <div className="numero">Commande numéro</div>
                            <div className="texte">{order.Numero}</div>
                        </div>
                    </div>
                    <div className="card-spacer"></div>
                    <div className="projet-order">
                        <img src={projetIcon} height="60" alt="" />
                        <div style={{ marginLeft: "20px" }}>
                            <div style={{ fontSize: "16px", fontWeight: "800" }}>
                                {order.Projet.Nom}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                                {order.Projet.projectType.Name}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                                {order.Projet.Compagnie.companyName}
                            </div>
                        </div>
                    </div>
                    <div className="card-spacer"></div>
                    <div className="projet-client">
                        <img src={order?.commandePour.client.avatar} height="60" alt="" />
                        <div style={{ marginLeft: "20px" }}>
                            <div style={{ fontSize: "16px", fontWeight: "800" }}>
                                {order?.commandePour.client.userFirstName}{" "}
                                {order?.commandePour.client.userLastName}
                            </div>
                            <div style={{ fontSize: "12px" }}>{order.commandePour.poste}</div>
                            <div style={{ fontSize: "12px" }}>
                                {order?.commandePour.client.userCell}
                            </div>
                        </div>
                    </div>
                    <div className="card-spacer"></div>
                    <div>
                        <div className="text-center" style={{ fontWeight: "800" }}>
                            Statut: {order?.statut}
                        </div>
                        <div className="text-center" style={{ fontSize: "14px" }}>
                            Créer le : {formatDate(order.createdAt)}
                        </div>
                        <div className="text-center" style={{ fontSize: "14px" }}>
                            Date de départ : {formatDate(order.dateDepart)}
                        </div>
                        <div className="text-center" style={{ fontSize: "14px" }}>
                            Départ dans : {daysUntillPickup} jours
                        </div>
                    </div>
                </div>
            </Link>
        </div>


*/