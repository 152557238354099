import { useNotify } from "../../hooks/useNotify";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const AddCompany = ({ setTitrePage, addCompany, setAddCompany }) => {

    const { user } = useGlobalContext();
    const { notify } = useNotify();
    const navigate = useNavigate();
    
    const [companyInfo, setCompanyInfo] = useState({
        Name: "",
        Tel: "",
        Adresse: {
            adresse: "",
            ville: "",
            province: "",
            pays: "",
            codePostal: ""
        },
        email: "",
        projets: [],
        users: []
        
    })

    useEffect(() => {
        setTitrePage("Nouvelle compagnie");
    }, []);

    const addCompanyToDb = async () => {
        if (
          companyInfo.Name === "" ||
          companyInfo.Tel === "" ||
          companyInfo.Adresse.adresse === "" ||
          companyInfo.Adresse.ville === "" ||
          companyInfo.Adresse.province === "" ||
          companyInfo.Adresse.pays === "" ||
          companyInfo.Adresse.codePostal === ""
        ) {
          notify(
            "error",
            "Assurez-vous de remplir tous les champs marqués d'un astérisque."
          );
        } else {
          try {
            const response = await fetch(`${config.BASE_URL}/api/Company/`, {
              method: "POST",
              body: JSON.stringify(companyInfo),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
            });
      
            if (!response.ok) {
              const json = await response.json();
              throw new Error(json.error);
            }
      
            notify("success", "Nouvelle compagnie ajouté avec succès.");
            navigate(-1);
          } catch (error) {
            notify("error", error.message);
          }
        }
        setAddCompany(false);
      };
      
    useEffect(()=>{
        if(addCompany)
        {
            addCompanyToDb()
        }
    },[addCompany])

    const handleInputChange = (e, field) => {
        const value = e.target.value;

            setCompanyInfo({
                ...companyInfo,
                Adresse: {
                    ...companyInfo.Adresse,
                    [field]:  value,
                    },
            });
        
    };


    return (
        <div className="height120">
            <div
                className="container-fluid"
            >
                <div className="row" style={{ marginTop: "40px" }}>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="companyNameInput"
                                onChange={(e) => {
                                    setCompanyInfo({
                                        ...companyInfo,
                                        Name: e.target.value
                                    });
                                }}
                                value={companyInfo.Name}
                                placeholder="Nom compagnie"
                            />
                            <label htmlFor="companyNameInput">Nom compagnie*</label>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="rueInput"
                                onChange={(e) => handleInputChange(e,  "adresse")}
                                value={companyInfo.Adresse.adresse}
                                placeholder="Adresse"
                            />
                            <label htmlFor="rueInput">Adresse*</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="villeInput"
                                        onChange={(e) => handleInputChange(e,  "ville")}
                                        value={companyInfo.Adresse.ville}
                                        placeholder="Ville"
                                    />
                                    <label htmlFor="villeInput">Ville*</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="provinceInput"
                                        onChange={(e) => handleInputChange(e, "province")}
                                        value={companyInfo.Adresse.province}
                                        placeholder="Province"
                                    />
                                    <label htmlFor="provinceInput">Province*</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="paysInput"
                                        onChange={(e) => handleInputChange(e, "pays")}
                                        value={companyInfo.Adresse.pays}
                                        placeholder="Country"
                                    />
                                    <label htmlFor="paysInput">Pays*</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="codeInput"
                                        onChange={(e) => handleInputChange(e, "codePostal")}
                                        value={companyInfo.Adresse.codePostal}
                                        placeholder="Code postal"
                                    />
                                    <label htmlFor="codeInput">Code postal*</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="cellulaireInput"
                                onChange={(e) => {
                                    setCompanyInfo({
                                        ...companyInfo,
                                        Tel: e.target.value
                                    });
                                }}
                                value={companyInfo.Tel}
                                placeholder="Téléphone"
                            />
                            <label htmlFor="cellulaireInput">Téléphone*</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="courrielInput"
                                onChange={(e) => {
                                    setCompanyInfo({
                                        ...companyInfo,
                                        email: e.target.value
                                    });
                                }}
                                value={companyInfo.email}
                                placeholder="Courriel"
                            />
                            <label htmlFor="courrielInput">Courriel</label>
                        </div>
                    </div>
                </div>
                
                





            </div>
        </div>

    );
};

export default AddCompany;

