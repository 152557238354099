import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../../css/products.css'
import { useGlobalContext } from '../../../hooks/useGlobalContext'
import trashIcon from '../../../imgs/interface/trash.png'
import config from '../../../config';
function DeleteCategory(props) {

  const {categories, categoriesDispatch} =  useGlobalContext()

  const [Name, setNewCategoryName] = useState("")
  const [parentId, setnewCategoryparentId] = useState("")
  const [error, setError] = useState(null)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleClickDelete = async () => {
    console.log("delete")
    if(props.nomSub == "sous-catégorie")
    {
       
        for(let category of categories)
        {
            if(props.id == category.parentId)
            {
                const response = await fetch(`${config.BASE_URL}/api/categories/${category._id}`, {
                    method: 'DELETE'
                  })
                  const json = await response.json()
                  if(response.ok)
                  {
                    categoriesDispatch({type:'DELETE_CATEGORY', payload: json})
                  }
            }
        }

        
    }else if(props.nomSub == "catégorie")
    {
        for(let category of categories)
        {
            if(props.id == category.parentId)
            {

                for(let subCategory of categories)
                {
                    if(subCategory.parentId == category._id)
                    {
                        const response = await fetch(`${config.BASE_URL}/api/categories/${subCategory._id}`, {
                            method: 'DELETE'
                          })
                          const json = await response.json()
                          if(response.ok)
                          {
                            categoriesDispatch({type:'DELETE_CATEGORY', payload: json})
                            
                          }

                    }
                }
                const response = await fetch(`${config.BASE_URL}/api/categories/${category._id}`, {
                    method: 'DELETE'
                  })
                  const json = await response.json()
                  if(response.ok)
                  {
                    categoriesDispatch({type:'DELETE_CATEGORY', payload: json})
                    
                  }
            }
        }






    }


    const response = await fetch(`${config.BASE_URL}/api/categories/${props.id}`, {
        method: 'DELETE'
      })
      const json = await response.json()
      if(response.ok)
      {
        categoriesDispatch({type:'DELETE_CATEGORY', payload: json})
        
      }


    handleClose()
  }

  return (
    <>
      <div className="trashBtn"  onClick={() =>{
                if(props.id)
                {
                    handleShow()
                }
                
      }} />
        

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ props.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        Êtes-vous certain de vouloir supprimer la {props.nomSub} <span className="lowerCase">{props.nom}</span> et tous ces sous catégories?

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleClickDelete}>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteCategory