import { useNotify } from "../../hooks/useNotify";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";

const ModifyCompany = ({ setTitrePage, saveCompany, setSaveCompany }) => {


    const params = useParams();
    const { id } = params;

    const { user } = useGlobalContext();
    const { notify } = useNotify();
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);

    const [activeCompany, setActiveCompany] = useState()
    const [companyInfo, setCompanyInfo] = useState({
        companyName: "",
        companyTel: "",
        companyAdresse: {
            adresse: "",
            ville: "",
            province: "",
            pays: "",
            codePostal: ""
        },
        companyEmail: "",
        projets: [],
        users: []
    })

    const fetchcompany = async () => {
        setIsloading(true)
        const response = await fetch(
            `${config.BASE_URL}/api/Company/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            }
        );
        const json = await response.json();

        if (response.ok) {
            setActiveCompany(json);
            setIsloading(false);
        }
        if (!response.ok) {
            notify("error", json.error);
        }
    };


    useEffect(() => {
        setTitrePage("Modifier compagnie");
        fetchcompany()
    }, []);

    useEffect(() => {
        if(activeCompany)
        {
            setCompanyInfo({
                companyName: activeCompany.companyName,
        companyTel: activeCompany.companyTel,
        companyAdresse: {
            adresse: activeCompany.companyAdresse.adresse,
            ville: activeCompany.companyAdresse.ville,
            province: activeCompany.companyAdresse.province,
            pays:activeCompany.companyAdresse.pays,
            codePostal: activeCompany.companyAdresse.codePostal
        },
        companyEmail: activeCompany.companyEmail,
            })
        }
    }, [activeCompany]);


    const saveCompanyToDb = async () => {
        if (
          companyInfo.companyName === "" ||
          companyInfo.companyTel === "" ||
          companyInfo.companyAdresse.adresse === "" ||
          companyInfo.companyAdresse.ville === "" ||
          companyInfo.companyAdresse.province === "" ||
          companyInfo.companyAdresse.pays === "" ||
          companyInfo.companyAdresse.codePostal === ""
        ) {
          notify(
            "error",
            "Assurez-vous de remplir tous les champs marqués d'un astérisque."
          );
        } else {
          try {
            const response = await fetch(`${config.BASE_URL}/api/Company/${id}`, {
              method: "PATCH",
              body: JSON.stringify(companyInfo),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
            });
      
            if (!response.ok) {
              const json = await response.json();
              throw new Error(json.error);
            }
      
            notify("success", "Compagnie enregistré avec succès.");
            navigate(-1);
          } catch (error) {
            notify("error", error.message);
          }
        }
        setSaveCompany(false);
      };
      
    useEffect(()=>{
        if(saveCompany)
        {
            saveCompanyToDb()
        }
    },[saveCompany])

    const handleInputChange = (e, field) => {
        const value = e.target.value;

            setCompanyInfo({
                ...companyInfo,
                companyAdresse: {
                    ...companyInfo.companyAdresse,
                    [field]:  value,
                    },
            });
        
    };


    return (
        <div className="height120">
            <div
                className="container-fluid"
            >
                <div className="row" style={{ marginTop: "40px" }}>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="companyNameInput"
                                onChange={(e) => {
                                    setCompanyInfo({
                                        ...companyInfo,
                                        companyName: e.target.value
                                    });
                                }}
                                value={companyInfo.companyName}
                                placeholder="Nom compagnie"
                            />
                            <label htmlFor="companyNameInput">Nom compagnie*</label>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="rueInput"
                                onChange={(e) => handleInputChange(e,  "adresse")}
                                value={companyInfo.companyAdresse.adresse}
                                placeholder="Adresse"
                            />
                            <label htmlFor="rueInput">Adresse*</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="villeInput"
                                        onChange={(e) => handleInputChange(e,  "ville")}
                                        value={companyInfo.companyAdresse.ville}
                                        placeholder="Ville"
                                    />
                                    <label htmlFor="villeInput">Ville*</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="provinceInput"
                                        onChange={(e) => handleInputChange(e, "province")}
                                        value={companyInfo.companyAdresse.province}
                                        placeholder="Province"
                                    />
                                    <label htmlFor="provinceInput">Province*</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="paysInput"
                                        onChange={(e) => handleInputChange(e, "pays")}
                                        value={companyInfo.companyAdresse.pays}
                                        placeholder="Country"
                                    />
                                    <label htmlFor="paysInput">Pays*</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control inputHeight"
                                        id="codeInput"
                                        onChange={(e) => handleInputChange(e, "codePostal")}
                                        value={companyInfo.companyAdresse.codePostal}
                                        placeholder="Code postal"
                                    />
                                    <label htmlFor="codeInput">Code postal*</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="cellulaireInput"
                                onChange={(e) => {
                                    setCompanyInfo({
                                        ...companyInfo,
                                        companyTel: e.target.value
                                    });
                                }}
                                value={companyInfo.companyTel}
                                placeholder="Téléphone"
                            />
                            <label htmlFor="cellulaireInput">Téléphone*</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control inputHeight"
                                id="courrielInput"
                                onChange={(e) => {
                                    setCompanyInfo({
                                        ...companyInfo,
                                        companyEmail: e.target.value
                                    });
                                }}
                                value={companyInfo.companyEmail}
                                placeholder="Courriel"
                            />
                            <label htmlFor="courrielInput">Courriel</label>
                        </div>
                    </div>
                </div>
                





            </div>
        </div>

    );
};

export default ModifyCompany;

