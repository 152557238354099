import { useEffect } from "react"
import SearchBar from "../../components/Commandes/searchBar"
import { useGlobalContext } from "../../hooks/useGlobalContext"
import { useState } from "react"
import { useNotify } from "../../hooks/useNotify"
import config from "../../config"
import { BallTriangle } from "react-loader-spinner"
import OrderCard from "../../components/Commandes/commandeCard"

import "../../css/order.css"

const Commandes = ({ setTitrePage }) => {


    const [orders, setOrders] = useState([])
    const { user } = useGlobalContext()
    const { notify } = useNotify()
    const [isLoading, setIsLoading] = useState(true)



    const loadCommandes = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(`${config.BASE_URL}/api/Commandes`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch commandes: ${response.status} ${response.statusText}`);
            }
            const json = await response.json();
            setOrders(json);
        } catch (error) {
            notify("error", error.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setTitrePage("Commandes")
        loadCommandes()
    }, [])

    useEffect(() => {
        console.log("Orders is ok", orders);

    }, [orders.length]);
    

    return (
        <div className="height120">
          <div className="container">
            <SearchBar />
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
              {isLoading ? (
                <div className="col w-100">
                  <div style={{ display: "flex", height: "400px", paddingTop: "100px", justifyContent: "center", justifyItems: "center" }}>
                    <BallTriangle
                      height={200}
                      width={200}
                      radius={5}
                      color="#ff9900"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  </div>
                </div>
              ) : (
                orders.map((commande) => {
                  console.log("Commande : ", commande);
                  return <OrderCard key={commande._id} order={commande} />;
                })
              )}
            </div>
          </div>
        </div>
      );

}

export default Commandes