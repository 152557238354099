import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";

import { BallTriangle } from "react-loader-spinner";

import userIcon from "../../imgs/launchpad/compagnie.png";
import config from "../../config";
import "../../css/details.css"

import btnPlus from "../../imgs/interface/plus.png";
import ProjectCard from "../../components/Projets/projectCard";

const CompanyDetails = ({ setTitrePage, activeCompany, setActiveCompany }) => {
    const params = useParams();
    const { id } = params;
    const { user } = useGlobalContext();
    const { notify } = useNotify();
    const [isLoading, setIsloading] = useState(true);

    const [myProjets, setMyProjets] = useState()

    const getMyProjets = async () => {
        try {

            const response = await fetch(`${config.BASE_URL}/api/projects/Company/${id}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch companies");
            }
            const json = await response.json();

            if (json.length != 0) {
                setMyProjets(json);
            }



        } catch (error) {
            notify("error", error.message); // Set error message in state variable
        }
        setIsloading(false)
    }


    const fetchcompany = async () => {
        setIsloading(true)
        const response = await fetch(
            `${config.BASE_URL}/api/Company/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            }
        );
        const json = await response.json();

        if (response.ok) {
            setActiveCompany(json);
            getMyProjets()

        }
        if (!response.ok) {
            notify("error", json.error);
            setIsloading(false);
        }

    };

    useEffect(() => {

        fetchcompany();

        window.scrollTo(0, 0);
        setTitrePage("Détails compagnie")
    }, [id]);

    useEffect(() => {
        console.log(activeCompany)
    }, [activeCompany])

    return (
        <div className="height120">
            <div className="cointainer-fluid">
                {isLoading &&
                    <div className="container">
                        <div className="row" style={{ marginTop: "40px", marginBottom: "40px" }}>
                            <div className="col w-100">
                                <div style={{ display: "flex", height: "400px", paddingTop: "100px", justifyContent: "center", justifyItems: "center" }}>
                                    <BallTriangle
                                        height={200}
                                        width={200}
                                        radius={5}
                                        color="#ff9900"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperClass={{}}
                                        wrapperStyle=""
                                        visible={true}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {!isLoading &&
                    <div className="container">
                        <div className="row" style={{ marginTop: "40px", marginBottom: "40px" }}>
                            <div className="col text-end">
                                <img width="160px" height="160px" src={userIcon} alt="" />
                            </div>
                            <div className="col">
                                <div style={{ fontSize: "18px", paddingBottom: "10px" }}>
                                    {activeCompany && activeCompany.companyName}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                    {activeCompany && activeCompany.companyTel}
                                </div>
                                <div style={{ fontSize: "14px", paddingBottom: "10px" }}>
                                    {activeCompany && activeCompany.companyEmail}
                                </div>

                                <div style={{ fontSize: "14px" }}>
                                    {
                                        activeCompany && activeCompany.companyAdresse &&
                                        activeCompany.companyAdresse.adresse}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                    {activeCompany &&
                                        activeCompany.companyAdresse &&
                                        activeCompany.companyAdresse.ville}
                                    ,{" "}
                                    {activeCompany &&
                                        activeCompany.companyAdresse &&
                                        activeCompany.companyAdresse.province}
                                </div>
                                <div style={{ fontSize: "14px", paddingBottom: "10px" }}>
                                    {activeCompany &&
                                        activeCompany.companyAdresse &&
                                        activeCompany.companyAdresse.pays}
                                    ,{" "}
                                    {activeCompany &&
                                        activeCompany.companyAdresse &&
                                        activeCompany.companyAdresse.codePostal}
                                </div>

                            </div>
                        </div>

                        <div className="subTitleBar">
                            <div className="texte">
                                Projets</div>
                            <Link to="/Projets/Ajouter" >
                                <div className="btnPlus">

                                </div>
                            </Link>
                        </div>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mt-1">
                            {myProjets && myProjets.map((projet) => {
                                return (
                                    <ProjectCard key={projet._id} project={projet} />
                                )
                            })
                            }
                        </div>

                        {!myProjets &&
                            <div className="text-center">Aucun Projet</div>
                        }
                        <div className="subTitleBar">
                            <div className="texte">Commandes</div>
                            <Link to="/Commandes/Ajouter" >
                            <div className="btnPlus">

                            </div>
                            </Link>
                        </div>
                        <div className="text-center mt-4">Aucunne Commande</div>
                        <div className="subTitleBar">
                            <div className="texte">Factures</div>
                        </div>
                        <div className="text-center mt-4">Aucunne Facture</div>
                    </div>
                }

            </div>
        </div>
    );
};

export default CompanyDetails;