import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";

import { BallTriangle } from "react-loader-spinner";

import userIcon from "../../imgs/interface/user.png";
import config from "../../config";

const DetailsEmploye = ({ setTitrePage, activeEmploye, setActiveEmploye }) => {
  const params = useParams();
  const { id } = params;
  const { user } = useGlobalContext();
  const { notify } = useNotify();
  const [isLoading, setIsloading] = useState(true);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("fr-CA", options);
  }

  const fetchEmploye = async () => {
    setIsloading(true)
    const response = await fetch(
      `${config.BASE_URL}/api/employes/${id}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      setActiveEmploye(json);
      console.log(json);
      setIsloading(false);
    }
    if (!response.ok) {
      notify("error", json.error);
    }
  };

  useEffect(() => {

    fetchEmploye();

    window.scrollTo(0, 0);
    setTitrePage("Détails employé")
  }, [id]);

  return (
    <div className="height120">
      <div className="cointainer-fluid">
        {isLoading &&
          <div className="container">
            <div className="row" style={{ marginTop: "40px" }}>

              <div className="col w-100">
                <div style={{ display: "flex", height: "400px", paddingTop: "100px", justifyContent: "center", justifyItems: "center" }}>
                  <BallTriangle
                    height={200}
                    width={200}
                    radius={5}
                    color="#ff9900"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                </div>

              </div>
            </div>
          </div>

        }
        {!isLoading &&
          <div className="container">
          <div className="row" style={{ marginTop: "40px" }}>

            <div className="col text-end">
              <img width="160px" height="160px" src={activeEmploye.user.avatar ? activeEmploye.user.avatar : userIcon} alt="" style={{borderRadius:"10px"}} />
            </div>
            <div className="col">
              <div style={{ fontSize: "18px" }}>
                {!isLoading && activeEmploye.user.userFirstName}{" "}
                {!isLoading && activeEmploye.user.userLastName}
              </div>
              <div style={{ paddingBottom: "10px" }}>
                {!isLoading && activeEmploye.poste && activeEmploye.poste.Name}
              </div>
              <div style={{ paddingBottom: "10px", fontSize: "14px", display: 'flex' }}>
                {!isLoading && activeEmploye.user.userVerified
                  ? (<><div className='green-dot' /> Compte actif</>)
                  : (<><div className='red-dot' /> Compte inactif</>)}
              </div>
              <div style={{ fontSize: "14px" }}>
                {!isLoading && activeEmploye.user.userEmail}
              </div>
              <div style={{ fontSize: "14px", paddingBottom: "10px" }}>
                {!isLoading && activeEmploye.user.userCell}
              </div>
              <div style={{ fontSize: "14px" }}>
                {!isLoading &&
                  activeEmploye.user.userAdresse &&
                  activeEmploye.user.userAdresse.adresse}
              </div>
              <div style={{ fontSize: "14px" }}>
                {!isLoading &&
                  activeEmploye.user.userAdresse &&
                  activeEmploye.user.userAdresse.ville}
                ,{" "}
                {!isLoading &&
                  activeEmploye.user.userAdresse &&
                  activeEmploye.user.userAdresse.province}
              </div>
              <div style={{ fontSize: "14px", paddingBottom: "10px" }}>
                {!isLoading &&
                  activeEmploye.user.userAdresse &&
                  activeEmploye.user.userAdresse.pays}
                ,{" "}
                {!isLoading &&
                  activeEmploye.user.userAdresse &&
                  activeEmploye.user.userAdresse.codePostal}
              </div>
              {activeEmploye.NAS && (
                <>
                  <div style={{ fontSize: "14px" }}>NAS</div>
                  <div style={{ fontSize: "14px", paddingBottom: "10px" }}>
                    {activeEmploye.NAS}
                  </div>
                </>
              )}
              {activeEmploye.salaire && (
                <div style={{ fontSize: "14px", paddingBottom: "10px" }}>
                  {activeEmploye.salaire.montant}$/{activeEmploye.salaire.method}
                </div>
              )}

              {activeEmploye.dateDebut && (
                <div style={{ fontSize: "14px" }}>
                  Date de début : {formatDate(activeEmploye.dateDebut)}
                </div>
              )}
              {activeEmploye.dateFin && (
                <div style={{ fontSize: "14px" }}>
                  Date de fin : {formatDate(activeEmploye.dateFin)}
                </div>
              )}


              <div style={{ marginTop: '10px' }}>Permission</div>
              <div className="row">
                <div className="col-2 text-end">Dashboard :</div>
                <div className="col">
                  {activeEmploye.permissions && activeEmploye.permissions.dashboard
                    ? "oui"
                    : "non"}
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-end">Produits :</div>
                <div className="col">
                  {activeEmploye.permissions && activeEmploye.permissions.produits
                    ? "oui"
                    : "non"}
                </div>
              </div>
              <div className="row">
                <div className="col-3 text-end">Employés :</div>
                <div className="col">
                  {activeEmploye.permissions && activeEmploye.permissions.employes
                    ? "oui"
                    : "non"}
                </div>
              </div>
              <div className="row">
                <div className="col-3 text-end">Paramètres :</div>
                <div className="col">
                  {activeEmploye.permissions && activeEmploye.permissions.settings
                    ? "oui"
                    : "non"}
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default DetailsEmploye;
