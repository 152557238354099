import { useState, useEffect } from "react";
import userIcon from "../../imgs/interface/userGrey.png";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import backBtn from "../../imgs/interface/back-Arrow-02.png";
import config from "../../config";
import "../../css/sidebar1.css";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ products, searching, setSearching, setState, categoryTree }) => {
  const { user, tags, tagsDispatch } = useGlobalContext();
  const [collapsed, setCollapsed] = useState({});
  const [showSidebarSubSub, setShowSidebarSubSub] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isSlideOut, setIsSlideOut] = useState(false);
  const [isSlideIn, setIsSlideIn] = useState(false);
  const [decorTagsWithProducts, setDecorTagsWithProducts] = useState([]);
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);


  const navigate = useNavigate();
  const location = useLocation();

  const toggleCollapse = (categoryName) => {
    setCollapsed((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
  };

  const handleSlideBack = () => {
    setIsSlideOut(false);
    setIsSlideIn(false);
  };

  const hasAssociatedProductsForTag = (tag) => {
    if (!products) return false;
    const associatedProducts = products.filter(
      (product) =>
        product.Tags && product.Tags.some((tagId) => tagId === tag._id)
    );
    return associatedProducts.length > 0;
  };

  
 const hasAssociatedProductsForCategory = (category) => {
  if (!products) return false;

  const associatedProducts = products.filter((product) => {
    if (
      product.Categories.some((cat) => cat.toString() === category._id.toString()) ||
      (category.children &&
        category.children.some((subcategory) =>
          product.Categories.includes(subcategory.toString())
        ))
    ) {
      return true;
    }
    return false;
  });

  return associatedProducts.length > 0;
};

  useEffect(() => {
    const fetchTags = async () => {
      const response = await fetch(`${config.BASE_URL}/api/tags`);
      const json = await response.json();
      tagsDispatch({ type: "SET_TAGS", payload: json });
      const decorTags = json.filter(
        (tag) => tag.tagType === "Décor" && hasAssociatedProductsForTag(tag)
      );
      setDecorTagsWithProducts(decorTags);
    };

    if (!tags) {
      fetchTags();
    }
  }, []);

  useEffect(() => {
    if (tags && products) {
      const decorTags = tags.filter(
        (tag) => tag.tagType === "Décor" && hasAssociatedProductsForTag(tag)
      );
      setDecorTagsWithProducts(decorTags);
    }
  }, [tags, products]);


  useEffect(() => {
    console.log(categoryTree)
    if (categoryTree && products) {
      const categories = categoryTree.filter((category) =>
        hasAssociatedProductsForCategory(category)
      );
      setCategoriesWithProducts(categories);
    }
  }, [categoryTree, products]);

  
  useEffect(() => {
    console.log(categoriesWithProducts)
  }, [categoriesWithProducts]);

  const handleSubcategoryClick = (subcategory) => {
    if (subcategory.children) {
      setSelectedSubcategory(subcategory);
      setIsSlideOut(true);
      setTimeout(() => setIsSlideIn(true), 200);
    }
  };

  return (
    <>
      <div className={`sidebar${isSlideOut ? " slide-out" : ""}`}>
        <div className="sidebar-title">
          <img src={userIcon} alt="User Icon" />
          <div className="title-text">Bonjour, {user && user.userFirstName}</div>
        </div>

        {decorTagsWithProducts.length > 0 && (
          <div className="sidebar-section">
            <div className="section-title">Décors</div>
            {decorTagsWithProducts.slice(0, 4).map((tag) => {
              return <div key={tag._id} className="section-link" onClick={() => {
                setSearching({
                  ...searching,
                  page:1,
                  categorie: "Tous",
                  decor: tag,
                });
                setState({ isPaneOpenLeft: false });
                if (location.pathname !== '/Produits') {
                  navigate('/Produits');
                }
              }}>{tag.Name}</div>;
            })}
            {decorTagsWithProducts.length > 4 && (
              <div className="collapse" id="decor-collapse">
                {decorTagsWithProducts.slice(4).map((tag) => {
                  return <div key={tag._id} className="section-link"  
                  onClick={() => {
                    setSearching({
                      ...searching,
                      page:1,
                      categorie: "Tous",
                      decor: tag,
                    });
                    setState({ isPaneOpenLeft: false });
                    if (location.pathname !== '/Produits') {
                      navigate('/Produits');
                    }
                  }}>{tag.Name}</div>;
                })}
              </div>
            )}
            {decorTagsWithProducts.length > 4 && (
              <div
                className={`section-${
                  collapsed["decor-collapse"] ? "moins" : "plus"
                }`}
                onClick={() => toggleCollapse("decor-collapse")}
                data-toggle="collapse"
                data-target="#decor-collapse"
                aria-expanded="false"
                aria-controls="decor-collapse"
              >
                {collapsed["decor-collapse"] ? "Voir moins" : "Voir plus"}
              </div>
            )}
          </div>
        )}

        {categoriesWithProducts.length > 0 &&
          categoriesWithProducts.map((category, index) => {
            return (
              <div className="sidebar-section" key={`category-${index}`}>
                <div className="section-title">{category.Name}</div>
                {category.children &&
                  category.children.slice(0, 4).map((subcategory, subIndex) => {
                    return (
                      <div
                        className={`section-link${
                          subcategory.children ? " subsubIcon" : ""
                        }`}
                        key={`subcategory-${subcategory._id}`}
                        onClick={() => {
                          handleSubcategoryClick(subcategory);
                          setSearching({
                            ...searching,
                            page:1,
                            categorie: subcategory,
                            decor: "Tous",
                          });
                          if (location.pathname !== '/Produits') {
                            navigate('/Produits');
                          }
                          if (!subcategory.children) {
                            setState({ isPaneOpenLeft: false });
                          }
                        }}
                      >
                        {subcategory.Name}
                      </div>
                    );
                  })}

                {category.children && category.children.length > 4 && (
                  <div className="collapse" id={`collapse-${category.Name}`}>
                    {category.children.slice(4).map((subcategory, subIndex) => {
                      return (
                        <div
                          className={`section-link${
                            subcategory.children ? " subsubIcon" : ""
                          }`}
                          key={`collapsed-subcategory-${subIndex}`}
                          onClick={() => {
                            handleSubcategoryClick(subcategory);

                            setSearching({
                              ...searching,
                              page:1,
                              categorie: subcategory,
                              decor: "Tous",
                            });
                            if (location.pathname !== '/Produits') {
                              navigate('/Produits');
                            }
                            if (!subcategory.children) {
                              setState({ isPaneOpenLeft: false });
                            }
                          }}
                        >
                          {subcategory.Name}
                        </div>
                      );
                    })}
                  </div>
                )}
                {category.children && category.children.length > 4 && (
                  <div
                    className={`section-${
                      collapsed[category.Name] ? "moins" : "plus"
                    }`}
                    onClick={() => toggleCollapse(category.Name)}
                    data-toggle="collapse"
                    data-target={`#collapse-${category.Name}`}
                    aria-expanded="false"
                    aria-controls={`collapse-${category.Name}`}
                  >
                    {collapsed[category.Name] ? "Voir moins" : "Voir plus"}
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <div className={`sidebarSub${isSlideIn ? " slide-in" : ""}`}>
        <div className="sidebar-title" onClick={handleSlideBack}>
          <img src={backBtn} alt="Back Button" />
          <div className="title-text">Menu principale</div>
        </div>
        <div className="sidebar-section">
          {selectedSubcategory && (
            <div className="section-title ">{selectedSubcategory.Name}</div>
          )}
          {selectedSubcategory &&
            selectedSubcategory.children.map((category, index) => {
              return (
                <div
                  className="section-link"
                  key={`subsub${index}`}
                  onClick={() => {
                    setSearching({
                      ...searching,
                      page:1,
                      categorie: category,
                      decor: "Tous",
                    });
                    setState({ isPaneOpenLeft: false });
                    if (location.pathname !== '/Produits') {
                      navigate('/Produits');
                    }
                  }}
                >
                  {category.Name}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
