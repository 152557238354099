import { useEffect, useState } from 'react'
import{ useParams } from 'react-router-dom'
import ProductDetails from '../../components/products/productDetails'

import config from '../../config'
import { BallTriangle } from 'react-loader-spinner'

const Details = ({setTitrePage,activeProduct, setActiveProduct}) => {
 
    
    const params = useParams();
    const { id } = params;
    const [isLoading, setIsLoading] = useState(true)
    
    const fetchProduit = async () => {
      setIsLoading(true)
      const response = await fetch(`${config.BASE_URL}/api/produits/${id}`)
      const json = await response.json()

      if(response.ok)
      {

          setActiveProduct(json)
          setIsLoading(false)
      }else{
          console.log("Nada")
      }
    }
     
        

    useEffect(() => {
      
      fetchProduit()
      window.scrollTo(0, 0)
      setTitrePage("Détails produit")
    }, [ id ])

    
  
    return (
        
    <div className="height120">
      <div className="container">
        {isLoading && 
        <div style={{display:"flex", height:"400px",paddingTop:"100px", justifyContent:"center", justifyItems:"center"}}>
        <BallTriangle
          height={200}
          width={200}
          radius={5}
          color="#ff9900"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
        </div>
        }
        {activeProduct &&
        <ProductDetails products = {activeProduct} isLoading={isLoading} setIsLoading={setIsLoading}/>
        }
      </div>
    </div>     
    )
  }
  
  export default Details