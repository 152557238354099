import { useState, useEffect, useRef, useCallback } from "react";
import useProducts from "../../hooks/useProducts";
import ProductCard from "./productCardSmall";
import SearchBar from "./searchBarSmall";



const SidebarProduct = ({ searching, setSearching, commandeActive, setCommandeActive }) => {

    const [searchQuery, setSearchQuery] = useState()

    const { isLoading, isError, error, products, setProducts, hasNextPage, categoryPath, totalProducts } =
        useProducts({ searchQuery, searching });

    const intObserver = useRef();

// Function to format the createdAt date
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};
    const lastProductRef = useCallback(
        (product) => {
            if (isLoading) return;

            if (intObserver.current) intObserver.current.disconnect();

            intObserver.current = new IntersectionObserver((products) => {
                if (products[0].isIntersecting && hasNextPage) {
                    console.log("We are near the last product!!");
                    setSearching({
                        ...searching,
                        page: searching.page + 1,
                    });
                }
            });

            if (product) intObserver.current.observe(product);
        },
        [isLoading, hasNextPage]
    );


    useEffect(()=>{
        setSearching({
            ...searching,
            page: 1,
            categorie: "Tous",
            decor: "Tous"
          })
    },[])


    if (isError) return <p>Error : {error.message}</p>;

    const content = products.map((product, i) => {
        if (products.length === i + 1) {
            return (
                <ProductCard
                    ref={lastProductRef}
                    key={product._id}
                    product={product}
                    setProducts={setProducts}
                    commandeActive={commandeActive}
                    setCommandeActive={setCommandeActive}
                />
            );
        }
        return <ProductCard key={product._id} product={product} commandeActive={commandeActive}
        setCommandeActive={setCommandeActive} setProducts={setProducts} />;
    });

    
    

    return (

        <div className="p-2 scrollable">
            <div className="resume" style={{borderBottom:"solid thin #232f3e", paddingBottom:"5px"}}>
                <div className="text-center">Items disponible du</div>
                
                <div className="text-center">{formatDate(commandeActive.dateDepart)}</div>
                <div className="text-center">{formatDate(commandeActive.dateRetour)}</div>


            </div>
            <SearchBar searching={searching} setSearching={setSearching} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <div
                className="row row-cols-1"
                id="searchResult"
            >
                {content}
            </div>
        </div>

    )

}

export default SidebarProduct