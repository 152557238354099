import { useEffect, useState } from "react";
import "../../../css/products.css";
import CreateCategory from "./createCategory";
import DeleteCategory from "./deleteCategory";
import CreateSubCategory from "./CreateSubCategory";
import { useGlobalContext } from "../../../hooks/useGlobalContext";

const CategoriesCheckboxes = ({ checkedCategories, setCheckedCategories }) => {
  const { categories } = useGlobalContext();
  const [activeCategory, setActiveCategory] = useState({
    name: null,
    _id: null,
  });
  const [activeSubCategory, setActiveSubCategory] = useState({
    name: null,
    _id: null,
  });
  const [activeSubSubCategory, setActiveSubSubCategory] = useState({
    name: null,
    _id: null,
  });

  const handleDivClick = (e) => {
    let catType = e.target.getAttribute("cattype") || e.currentTarget.getAttribute("cattype");
    let catName = e.target.getAttribute("nom") || e.currentTarget.getAttribute("nom");
    
    let catID = e.currentTarget.getAttribute("id");
    if (catType == 1) {
      if (activeCategory._id !== catID) {
        setActiveSubCategory({
          name: null,
          _id: null,
        });
        setActiveSubSubCategory({
          name: null,
          _id: null,
        });
        setActiveCategory({
          name: catName,
          _id: catID,
        });
      }
    }
    if (catType == 2) {
      if (activeSubCategory._id !== catID) {

      
        setActiveSubSubCategory({
          name: null,
          _id: null,
        });
        setActiveSubCategory({
          name: catName,
          _id: catID,
        });
      }
    }
    if (catType == 3) {
      if (activeSubSubCategory._id !== catID) {
        setActiveSubSubCategory({
          name: catName,
          _id: catID,
        });
      }
    }
  };

  const handleSelect = (e) => {
    const currentTarget = e.target.dataset.cattype;
    let parentID = e.target.getAttribute("parentId") || e.currentTarget.getAttribute("parentId") || null;
    const currentCategory = {
      name: e.target.dataset.nom,
      _id: e.target.dataset.id,
      parentId: parentID || null
    };
  
    if (e.target.checked) {
      // Add the category to checkedCategories
      setCheckedCategories(prevCategories => [...prevCategories, currentCategory]);
      // Add all parent categories to checkedCategories if they're not already included
      let parentId = currentCategory.parentId;
      while (parentId) {
        let parentCategory = categories.find(category => category._id === parentId);
        if (!checkedCategories.find(category => category._id === parentCategory._id)) {
          setCheckedCategories(prevCategories => [...prevCategories, parentCategory]);
        }
        parentId = parentCategory.parentId;
      }
    } else {
      // Remove the category itself
      setCheckedCategories(prevCategories => prevCategories.filter(category => category._id !== currentCategory._id));
      // Remove all child categories recursively
      const removeChildCategories = (categoryId) => {
        const childCategories = categories.filter(cat => cat.parentId === categoryId);
        
        childCategories.forEach(childCategory => {
          setCheckedCategories(prevCategories => prevCategories.filter(category => category._id !== childCategory._id));
          
          removeChildCategories(childCategory._id);
        });
      };
      
      removeChildCategories(currentCategory._id);
    }
  }
  

  return (
    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 ">
      <div className="col">
        <div className="card shadow-sm cardCheckboxList">
          <div className="card-body">
            <h4>Catégories</h4>
          </div>
          <div className="checkboxList">
            
            {categories &&
              categories.map((category) => {
                if (!category.parentId) {
                  return (
                    <div
                      className={
                        activeCategory._id === category._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={category._id}
                      id={category._id}
                      nom={category.Name}
                      cattype={1}
                      onClick={handleDivClick}
                    >
                      <label htmlFor={`label-${category._id}`}>
                        <input
                          type="checkbox"
                          id={`label-${category._id}`}
                          defaultChecked={false}
                          checked={checkedCategories?.find((c) => c?._id === category?._id)}
                          onChange={handleSelect}
                          data-cattype={1}
                          data-id={category._id}
                          data-nom={category.Name}
                          data-parentid=""
                        />
                        {category.Name}
                      </label>
                    </div>
                  );
                }
              })}
          </div>
          <div className="menuAddanDelete">
            <CreateCategory title="Nouvelle catégorie" typeAction="Ajouter" />
            <DeleteCategory
              title="Supprimer une catégorie"
              id={activeCategory._id}
              nomSub="catégorie"
              nom={activeCategory.name}
            />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card shadow-sm cardCheckboxList">
          <div className="card-body">
            <h4>Sous-catégories</h4>
          </div>
          <div className="checkboxList">
            {activeCategory._id && (
              <>
                {categories &&
                  categories.map((subCategory) => {
                    if (subCategory.parentId == activeCategory._id) {
                      return (
                        <div
                          className={
                            activeSubCategory._id == subCategory._id
                              ? "inputCheck active"
                              : "inputCheck"
                          }
                          key={subCategory._id}
                          id={subCategory._id}
                          nom={subCategory.Name}
                          cattype={2}
                          onClick={handleDivClick}
                        >
                          <label htmlFor={`label-${subCategory._id}`}>
                            <input
                              type="checkbox"
                              id={`label-${subCategory._id}`}
                              defaultChecked={false}
                              checked={checkedCategories.find((c) => c._id === subCategory._id)}
                              onChange={handleSelect}
                              parentId={subCategory.parentId}
                              data-cattype={2}
                              data-id={subCategory._id}
                              data-nom={subCategory.Name}
                            />
                            {subCategory.Name}
                          </label>
                        </div>
                      );
                    }
                  })}
              </>
            )}
          </div>
          <div className="menuAddanDelete">
            <CreateSubCategory
              title="Nouvelle sous-catégorie"
              parentId={activeCategory._id}
              typeAction="Ajouter"
            />
            <DeleteCategory
              title="Supprimer une catégorie"
              id={activeSubCategory._id}
              nomSub="sous-catégorie"
            />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card shadow-sm cardCheckboxList">
          <div className="card-body">
            <h4>Sous-catégories 2</h4>
          </div>
          <div className="checkboxList">
            {activeSubCategory._id && (
              <>
                {categories &&
                  categories.map((subSubCategory) => {
                    if (subSubCategory.parentId == activeSubCategory._id) {
                      return (
                        <div
                          className={
                            activeSubSubCategory._id == subSubCategory._id
                              ? "inputCheck active"
                              : "inputCheck"
                          }
                          key={subSubCategory._id}
                          id={subSubCategory._id}
                          nom={subSubCategory.Name}
                          cattype={3}
                          parentId={subSubCategory.parentId}
                          onClick={handleDivClick}
                        >
                          <label htmlFor={`label-${subSubCategory._id}`}>
                            <input
                              type="checkbox"
                              id={`label-${subSubCategory._id}`}
                              defaultChecked={false}
                              checked={checkedCategories.find((c) => c._id === subSubCategory._id)}
                              onChange={handleSelect}
                              parentId={subSubCategory.parentId}
                              data-cattype={3}
                              data-id={subSubCategory._id}
                              data-nom={subSubCategory.Name}

                            />
                            {subSubCategory.Name}
                          </label>
                        </div>
                      );
                    }
                  })}
              </>
            )}
          </div>
          <div className="menuAddanDelete">
            <CreateSubCategory
              title="Nouvelle sous-catégorie"
              parentId={activeSubCategory._id}
              typeAction="Ajouter"
            />
            <DeleteCategory
              title="Supprimer une sous-catégorie"
              id={activeSubSubCategory._id}
              nomSub="sous-catégorie 2"
              nom={activeSubSubCategory.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesCheckboxes;