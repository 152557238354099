import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../css/products.css";
import { useGlobalContext } from "../../hooks/useGlobalContext";

import btnPlus from "../../imgs/interface/plus.png";
import config from "../../config";

const AddPoste = ({ notify, postes, setPostes }) => {

    const { user } = useGlobalContext()

    const [Name, setNewPosteName] = useState("");
    const [error, setError] = useState(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const addPoste = async () => {

        const payload = { Name: Name, Client: false };

        /// Vérifier si il existe déjè

        if (postes) {
            const posteExiste = postes.filter(
                (poste) => poste.Name.toLowerCase() === Name.toLowerCase()
            );
            if (posteExiste.length != 0) {
                notify("warning", "Ce poste existe déjà dans la liste.");
                setNewPosteName("");
            } else {
                const response = await fetch(`${config.BASE_URL}/api/postes`, {
                    method: "POST",
                    body: JSON.stringify(payload),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                const json = await response.json();
                if (!response.ok) {
                    setError(json.error);
                }
                if (response.ok) {
                    setPostes([...postes, json])
                    setNewPosteName("");
                    notify("success", "Poste ajouté.");
                    setError(null);
                    handleClose();
                }
            }
        }

        /*
            /// Vérifier si il existe déjè
            if (postes) {
              const posteExiste = postes.filter(
                (poste) => poste.Name.toLowerCase() === Name.toLowerCase()
              );
              if (posteExiste.length != 0) {
                notify("warning", "Ce poste existe déjà dans la liste.");
                setNewPosteName("");
              } else {
                const response = await fetch("http://96.127.218.7:4000/api/postes", {
                  method: "POST",
                  body: JSON.stringify(payload),
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                  },
                });
                const json = await response.json();
                if (!response.ok) {
                  setError(json.error);
                }
                if (response.ok) {
                  postesDispatch({ type: "ADD_POSTE", payload: json });
                  setNewPosteName("");
                  notify("success", "Poste ajouté.");
                  setError(null);
                  handleClose();
                }
              }
            }
            */
    };

    return (
        <>
            <div className="btnPlus" onClick={handleShow}>
                <img src={btnPlus} alt="" />
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un poste</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="nomPoste"
                            placeholder="Now du poste"
                            onChange={(e) => setNewPosteName(e.target.value)}
                            value={Name}
                        />
                        <label htmlFor="nomPoste">Nom du poste</label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                    <Button variant="primary" onClick={addPoste}>
                        Ajouter
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddPoste;
