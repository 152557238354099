import React, { useRef } from "react";
import defaultIcon from '../../../imgs/interface/no-image-icon.png';
import config from "../../../config.js";
import axios from "axios";

const FormData = require("form-data");

const ImgBoxMenu = ({ isActive, defaultIcon, setIsActive, setActiveImage, imagesList, setImagesList }) => {

  const fileInputRef = useRef();

  const deleteImage = async () => {
    if (imagesList.length > 0) {
      // Filter out the image with the same file property as the isActive state
      setImagesList(imagesList.filter((c) => c.file !== isActive));
      // Set the isActive state to the first image in the list, or null if the list is empty
      setIsActive(imagesList.length > 0 ? imagesList[0].file : null);

      if (imagesList.length === 1) {
        setActiveImage(defaultIcon);
      }
    }
  };

  // Function to convert a base64 string to a Blob
function base64ToBlob(base64, mime) {
  mime = mime || '';
  var sliceSize = 1024;
  var byteChars = atob(base64);
  var byteArrays = [];

  for (var offset = 0; offset < byteChars.length; offset += sliceSize) {
    var slice = byteChars.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
}

// Function to convert a Blob to a File
function blobToFile(theBlob, fileName, fileType) {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return new File([theBlob], fileName, { type: fileType });
}


  const removeBackground = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image_file', imageFile);
  
      const response = await axios.post(`${config.BASE_URL}/api/images/removeBackground`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        const { base64_result } = response.data;
  
        if (base64_result) {
          console.log('Background removal successful.');
  
          // Convert base64 to Blob
          const blob = base64ToBlob(base64_result, 'image/png');
  
          // Create a File with the blob
          const file = blobToFile(blob, 'processed-image.png', 'image/png');
  
          // Include the File in a FormData
          const imageFormData = new FormData();
          imageFormData.append('productImage', file); // Adjust the field name as needed
  
          // Return the FormData
          return file;
        } else {
          console.error('Background removal failed. Response data:', response.data);
          return null;
        }
      } else {
        console.error('Background removal request failed.');
        return null;
      }
    } catch (error) {
      console.error('Error removing background:', error);
      return null;
    }
  };
  



  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Remove the background of the image
      const processedImageUrl = await removeBackground(file);


      if (processedImageUrl) {
        // Add the file and order properties to the image object
        const image = { file: processedImageUrl, order: imagesList.length };
        setImagesList([...imagesList, image]);
        setActiveImage(image.file);
        setIsActive(image.file);
      } else {
        // Add the file and order properties to the image object
        const image = { file: file, order: imagesList.length };
        setImagesList([...imagesList, image]);
        setActiveImage(image.file);
        setIsActive(image.file);
      }
    }
  };

  return (
    <div className="menuAddanDelete">
      <form>
        <input
          type="file"
          className="hiddenInput"
          accept="image/*"
          ref={fileInputRef}
          name="image"
          onChange={(event) => {
            handleFileInputChange(event);
          }}
        />
      </form>

      <div
        className="btnAdd"
        onClick={(event) => {
          event.preventDefault();
          fileInputRef.current.click();
        }}
      >
        +
      </div>
      <div className="trashBtn" onClick={() => deleteImage()}></div>
    </div>
  );
};

export default ImgBoxMenu;
