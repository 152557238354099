import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../css/products.css";
import { useNotify } from "../../hooks/useNotify";

import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNavigate } from "react-router-dom";
import config from "../../config";

function DeleteProduit({ product }) {
  const { user } = useGlobalContext();

  const [error, setError] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { notify } = useNotify();

  const navigate = useNavigate();

  const handleClickDelete = async () => {
    handleClose();
    const response = await fetch(
      `${config.BASE_URL}/api/produits/${product._id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();
    navigate(-1);
    if (response.ok) {
      notify(
        "success",
        `Produit numéro ${product.Number} a été supprimé avec succès.`
      );
    } else {
      notify("error", "Produit n'a pas pu être supprimé");
    }
  };

  return (
    <>
    <div className="btn-delete" onClick={() => {
          handleShow();
        }}>
                    Supprimer
                </div>
      

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Supprimer un produit.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous certain de vouloir supprimer le produit numéro{" "}
          <span className="lowerCase">{product.Number}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleClickDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteProduit;
