import { Link } from "react-router-dom";
import "../../css/card.css";
import userIcon from "../../imgs/interface/user.png";
import config from "../../config.js";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";

const ClientCardProjet = ({
  client,
  setNewProject,
  activeProjet,
  setActiveProjet,
}) => {
  const { user } = useGlobalContext();
  const { notify } = useNotify();

  const removeClientFromEquipe = async (e) => {
    e.preventDefault();
    if (activeProjet) {
      const newEquipe = activeProjet.Equipe.map((teamMate) => ({
        client: teamMate.client._id,
        fullName: teamMate.fullName,
        poste: teamMate.poste._id || teamMate.poste.id,
      })).filter((teamMate) => teamMate.client !== client.client._id); // Filter out the client to be removed

      console.log("Équipe", newEquipe);

      const Equipe = {
        Equipe: newEquipe,
      };

      try {
        const response = await fetch(
          `${config.BASE_URL}/api/projects/${activeProjet._id}`,
          {
            method: "PATCH",
            body: JSON.stringify(Equipe), // Use the updated project object
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          const json = await response.json();
          throw new Error(json.error);
        }
      } catch (error) {
        notify("error", error.message);
      }

      // Now you can set the updated Equipe in activeProjet
      setActiveProjet({
        ...activeProjet,
        Equipe: activeProjet.Equipe.filter(
          (equipeMember) => equipeMember.client._id !== client.client._id
        ),
      });
    } else {
      setNewProject((prevNewProject) => ({
        ...prevNewProject,
        Equipe: prevNewProject.Equipe.filter(
          (equipeMember) => equipeMember.client._id !== client.client._id
        ),
      }));
    }
  };

  return (
    <div className="col">
      <Link
        className="noDecoration"
        to={`/Clients/Details/${client.client._id}`}
      >
        <div className="card card-list" style={{ position: "relative" }}>
          <div
            className="redX"
            title={`Retirer ${client.client.userFirstName} de l'équipe`}
            onClick={(e) => {
              removeClientFromEquipe(e);
            }}
          ></div>
          <div className="card-body">
            <div className="row">
              <div className="col-4">
                <img
                  src={client.client.avatar ? client.client.avatar : userIcon}
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-8">
                <div className="row">
                  {client.client.userFirstName && (
                    <>{client.client.userFirstName}</>
                  )}
                  {client.client.userLastName && (
                    <> {client.client.userLastName}</>
                  )}
                </div>
                <div className="row" style={{ fontSize: "12px" }}>
                  {client.poste.Name && <>{client.poste.Name}</>}
                </div>
                <div className="row" style={{ fontSize: "12px" }}>
                  {client.client.userCell && <>{client.client.userCell}</>}
                </div>
                <div className="row" style={{ fontSize: "12px" }}>
                  {client.client.userEmail && <>{client.client.userEmail}</>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ClientCardProjet;
