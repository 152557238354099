import { useEffect, useState } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";

import "../../css/filtersList.css"

const FiltersList = ({filtres, setFiltres}) => {

  const { tags } = useGlobalContext();

  // Initialize the filter list with an empty array
  const [filtreListe, setFiltreListe] = useState([]);
  

const deleteFilter = (event) => {
    const id = event.currentTarget.getAttribute("data-id");
  
    // Create a new state object with updated arrays
    const updatedFiltres = {
      Couleur: filtres.Couleur.filter(item => item !== id),
      Materiaux: filtres.Materiaux.filter(item => item !== id),
      Caracteristique: filtres.Caracteristique.filter(item => item !== id),
    };
  
    // Check if the updated arrays are empty and add "All" if needed
    if (updatedFiltres.Couleur.length === 0) {
      updatedFiltres.Couleur.push("All");
    }
    if (updatedFiltres.Materiaux.length === 0) {
      updatedFiltres.Materiaux.push("All");
    }
    if (updatedFiltres.Caracteristique.length === 0) {
      updatedFiltres.Caracteristique.push("All");
    }
  
    // Update the state with the new filtres object
    setFiltres(updatedFiltres);
  };
  
  


  useEffect(() => {
    const couleurs = filtres.Couleur;
    const materiaux = filtres.Materiaux;
    const caracterisiques = filtres.Caracteristique;

    const updatedFiltreListe = [];

    if (Array.isArray(couleurs) && couleurs.length >= 1 && !couleurs.includes('All')) {
      couleurs.forEach(id => {
        const tag = tags.find(tag => tag._id === id);
        if (tag) {
          updatedFiltreListe.push({ id, name: tag.Name });
        }
      });
    }

    if (Array.isArray(materiaux) && materiaux.length >= 1 && !materiaux.includes('All')) {
      materiaux.forEach(id => {
        const tag = tags.find(tag => tag._id === id);
        if (tag) {
          updatedFiltreListe.push({ id, name: tag.Name });
        }
      });
    }

    if (Array.isArray(caracterisiques) && caracterisiques.length >= 1 && !caracterisiques.includes('All')) {
      caracterisiques.forEach(id => {
        const tag = tags.find(tag => tag._id === id);
        if (tag) {
          updatedFiltreListe.push({ id, name: tag.Name });
        }
      });
    }

    // Update the state with the new filter list
    setFiltreListe(updatedFiltreListe);
  }, [tags, filtres]);

  return (
    <div className="w-100">
      {filtreListe.map((filtre) => (
        <div className="listBtn" title="Enlever le filtre" data-id={filtre.id} key={filtre.id} onClick={(e)=>{deleteFilter(e)}}>
          {filtre.name} X

        </div>
      ))}
    </div>
  );
};

export default FiltersList;
