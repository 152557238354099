import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../../css/products.css'
import { useGlobalContext } from '../../../hooks/useGlobalContext'
import config from '../../../config';


function DeleteTag({nom, id, checkedTags, setCheckedTags, title}) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { user, tagsDispatch } = useGlobalContext()

  const handleClickDelete = async () => {


    const response = await fetch(`${config.BASE_URL}/api/tags${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
      
    })
    const json = await response.json()
    if(response.ok)
    {
      
      tagsDispatch({type:'DELETE_TAG', payload: json})

      setCheckedTags(checkedTags.filter(tag=>tag.id !== id))
      
    }


    handleClose()
  }

  return (
    <>
      <div className="trashBtn"  onClick={() =>{
                if(id)
                {
                    handleShow()
                }
                
      }} />
      

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        Êtes-vous certain de vouloir supprimer <span className="lowerCase">{nom}</span>?

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleClickDelete}>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteTag