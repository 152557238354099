
import { useState, useCallback } from "react";

import ImgBoxMenu from "./imgBoxMenu"
import defaultIcon from '../../../imgs/interface/imgIcon.png'
import loadingIcon from '../../../imgs/interface/loading.gif'
import { useEffect } from "react";



const ImgBox = ({ titrePage, isLoading, imagesList, setImagesList }) => {

    const [activeImage, setActiveImage] = useState(defaultIcon)
    const [isActive, setIsActive] = useState()
    
    const handleDrop = useCallback(
        (e) => {
            e.preventDefault();
            const newImagesList = [...imagesList];
            const draggedImageIndex = parseInt(
                e.dataTransfer.getData("draggedImageIndex")
            );
            const droppedImageIndex = parseInt(e.target.getAttribute("data-index"));
            const [draggedImage] = newImagesList.splice(draggedImageIndex, 1);
            newImagesList.splice(droppedImageIndex, 0, draggedImage);
            setImagesList(newImagesList);
        },
        [imagesList]
    );

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
    }, []);

    const handleDragStart = useCallback((e, image, index) => {
        e.dataTransfer.setData("draggedImageIndex", index);
    }, []);

    const handleImgClick = useCallback((image) => {
        setActiveImage(URL.createObjectURL(image.file));
        setIsActive(image.file);
    }, []);

    useEffect(()=>{
        if(imagesList[0])
        {
            setActiveImage(URL.createObjectURL(imagesList[0].file));
            setIsActive(imagesList[0].file);
        }
    },[imagesList])

    return (
        <div className="card shadow-sm imgBox">
            <div className="imgBig">
                <img src={isLoading? loadingIcon : activeImage} className="bigImage" alt="" id="bigImage" />
            </div>

            <div
                className="imgSmall"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >   
                {isActive && imagesList.length !=0 && imagesList.map((image, index) => (
                    <div
                        key={image.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, image, index)}
                        onClick={() => {
                            handleImgClick(image);
                        }}
                    >
                        <img
                            className={isActive === image.file ? "active" : ""}
                            src={URL.createObjectURL(image.file)}
                            alt={`image-${index}`}
                            data-index={index}
                        />
                    </div>
                ))}
            </div>
            {titrePage !== "Détails produit" && 
            <ImgBoxMenu defaultIcon={defaultIcon} isActive={isActive} setIsActive={setIsActive} setActiveImage={setActiveImage} imagesList={imagesList} setImagesList={setImagesList} />
            }
            
        </div>
    )

}

export default ImgBox