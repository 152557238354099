import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../css/products.css";
import { useNotify } from "../../hooks/useNotify";

import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNavigate } from "react-router-dom";
import config from "../../config";

function DeleteProjet({ projet }) {
  const { user } = useGlobalContext();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { notify } = useNotify();

  const navigate = useNavigate();

  const handleClickDelete = async () => {
    
      handleClose();
      const response = await fetch(
        `${config.BASE_URL}/api/projects/${projet._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      navigate(-1);
      if (response.ok) {
        notify(
          "success",
          `Le projet ${projet.Nom} a été supprimé avec succès.`
        );
      } else {
        notify("error", json.message);
      }
    
    
  };

  return (
    <>
    <div className="btn-delete" onClick={() => {
          handleShow();
        }}>
                    Supprimer
                </div>
      

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Supprimer un projet.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous certain de vouloir supprimer le projet{" "}
          <span className="lowerCase">{projet.Nom}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleClickDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteProjet;
