import "../../css/cssSwitch.css";
import { useNotify } from "../../hooks/useNotify";
import { useState } from "react";
import AddPoste from "../../components/Employes/addPoste";
import { useEffect } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Navigate, useNavigate } from "react-router-dom";
import config from "../../config";

const AddEmployee = ({ saveEmploye, setSaveEmploye, setTitrePage }) => {
  const [userFirstName, setUserFirstName] = useState();
  const [userLastName, setUserLastName] = useState();
  const [userNAS, setUserNAS] = useState();
  const [userCourriel, setUserCourriel] = useState();
  const [userCellulaire, setUserCellulaire] = useState();
  const [userSalaire, setUserSalaire] = useState();
  const [userSalaireType, setUserSalaireType] = useState("heure");
  const [userAdresse, setUserAdresse] = useState();
  const [userVille, setUserVille] = useState();
  const [userProvince, setUserProvince] = useState();
  const [userCodePostal, setUserCodePostal] = useState();
  const [userPays, setUserPays] = useState();
  const [envoyerEmail, setEnvoyerEmail] = useState(true);
  const [userGenre, setUserGenre] = useState("Male")

  const [dateDebut, setDateDebut] = useState();
  const [dateFin, setDateFin] = useState();

  const [showMiniLaunchPad, setShowMiniLaunchPad] = useState(false);
  const [showMiniLaunchPad2, setShowMiniLaunchPad2] = useState(false);

  const [postes, setPostes] = useState();
  const [selectedPoste, setSelectedPoste] = useState({ id: "", name: "" });

  const [permissionDashboard, setPermissionDashboard] = useState(false);
  const [permissionProduits, setPermissionProduits] = useState(true);
  const [permissionEmployes, setPermissionEmployes] = useState(false);

  const { user } = useGlobalContext();

  const { notify } = useNotify();
  const navigate = useNavigate();

  useEffect(() => {
    if (saveEmploye) {
      handleSave()
    }
  }, [saveEmploye])

  const handleSave = async () => {
    if (
      !userFirstName ||
      !userLastName ||
      !userCourriel ||
      !userCellulaire ||
      selectedPoste === ""
    ) {
      notify(
        "warning",
        "Assurez-vous de remplir tous les champs marqués d'un astérisque."
      );
    } else {
      
      // Make sure it does not exist
      const newEmploye = {
        salaire: {
          montant: userSalaire,
          method: userSalaireType,
        },
        poste: selectedPoste.id,
        NAS: userNAS,
        dateDebut: dateDebut,
        dateFin: dateFin,
        envoyerEmail: envoyerEmail,
        permissions: {
          dashboard: permissionDashboard,
          employes: permissionEmployes,
          produits: permissionProduits,
        },
      };
      const newUser = {
        userEmail: userCourriel,
        userFirstName: userFirstName,
        userLastName: userLastName,
        userCell: userCellulaire,
        userAdresse: {
          adresse: userAdresse,
          ville: userVille,
          province: userProvince,
          pays: userPays,
          codePostal: userCodePostal,
        },
        genre:userGenre
      };
  
      const userData = { newEmploye, newUser };
  
      const response = await fetch(`${config.BASE_URL}/api/employes`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (!response.ok) {
        notify("error", json.error); // Show the error message from the response
      }
      if (response.ok) {
        notify("success", "Nouvel employé ajouté avec succès.");
        navigate(-1);
      }
    }
    setSaveEmploye(false);
  };
  

  const getPostes = async () => {
    const response = await fetch(`${config.BASE_URL}/api/postes`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();
    if (response.ok) {
      const filteredPostes = json.filter((poste) => !poste.Client);
      setPostes(filteredPostes);
    }
  };

  useEffect(() => {
    getPostes();
    setTitrePage("Nouvel employé");
  }, []);

  const handleChangePoste = (e) => {
    const id = e.target.options[e.target.selectedIndex].getAttribute("id");
    const name = e.target.value;
    const newPoste = { id, name };
    setSelectedPoste(newPoste);
  };

  return (
    <div className="height120">
      <div
        className="container-fluid"
      >

        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="prenomInput"
                onChange={(e) => setUserFirstName(e.target.value)}
                value={userFirstName}
                placeholder="Prénom"
              />
              <label htmlFor="prenomInput">Prénom*</label>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="nomInput"
                onChange={(e) => setUserLastName(e.target.value)}
                value={userLastName}
                placeholder="Nom"
              />
              <label htmlFor="nomInput">Nom de famille*</label>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-10">
                <div className="form-floating mb-3">
                  <select
                    className="form-control downIcon"
                    id="posteEmploye"
                    placeholder="Poste"
                    onChange={(e) => {
                      handleChangePoste(e);
                    }}
                    value={selectedPoste.name}
                  >
                    <option value=""></option>
                    {postes &&
                      postes.map((poste) => {
                        return (
                          <option
                            key={poste._id}
                            value={poste.Name}
                            id={poste._id}
                          >
                            {poste.Name}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="posteEmploye">Poste*</label>
                </div>
              </div>
              <div className="col-2">
                <AddPoste
                  notify={notify}
                  postes={postes}
                  setPostes={setPostes}
                />
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="cellulaireInput"
                onChange={(e) => setUserCellulaire(e.target.value)}
                value={userCellulaire}
                placeholder="Téléphone"
              />
              <label htmlFor="cellulaireInput">Téléphone*</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="courrielInput"
                onChange={(e) => setUserCourriel(e.target.value)}
                value={userCourriel}
                placeholder="Courriel"
              />
              <label htmlFor="courrielInput">Courriel*</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="prenomInput"
                onChange={(e) => setUserNAS(e.target.value)}
                value={userNAS}
                placeholder="NAS"
              />
              <label htmlFor="prenomInput">NAS</label>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight dollarSign"
                    id="salaireInput"
                    onChange={(e) => setUserSalaire(e.target.value)}
                    value={userSalaire}
                    placeholder="Salaire"
                  />
                  <label htmlFor="salaireInput">Salaire</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <select
                    className="form-control downIcon"
                    id="salaireType"
                    placeholder="Salaire type"
                    onChange={(e) => setUserSalaireType(e.target.value)}
                    value={userSalaireType}
                  >
                    <option value="heure">heure</option>
                    <option value="jour">jour</option>
                    <option value="semaine">semaine</option>
                    <option value="année">année</option>
                  </select>
                  <label htmlFor="salaireType">Type</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="rueInput"
                onChange={(e) => setUserAdresse(e.target.value)}
                value={userAdresse}
                placeholder="Adresse"
              />
              <label htmlFor="rueInput">Adresse</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="villeInput"
                    onChange={(e) => setUserVille(e.target.value)}
                    value={userVille}
                    placeholder="Ville"
                  />
                  <label htmlFor="villeInput">Ville</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="provinceInput"
                    onChange={(e) => setUserProvince(e.target.value)}
                    value={userProvince}
                    placeholder="Province"
                  />
                  <label htmlFor="provinceInput">Province</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="paysInput"
                    onChange={(e) => setUserPays(e.target.value)}
                    value={userPays}
                    placeholder="Country"
                  />
                  <label htmlFor="paysInput">Pays</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="codeInput"
                    onChange={(e) => setUserCodePostal(e.target.value)}
                    value={userCodePostal}
                    placeholder="Code postal"
                  />
                  <label htmlFor="codeInput">Code postal</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <DatePicker
                    className="form-control inputHeight"
                    placeholderText={"Date de début"}
                    selected={dateDebut}
                    onChange={(date) => setDateDebut(date)}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <DatePicker
                    className="form-control inputHeight"
                    placeholderText={"Date de fin"}
                    selected={dateFin}
                    onChange={(date) => setDateFin(date)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
              <div className="row mb-2 mt-2">
                <div className="col" style={{display:"flex", alignItems:"center", justifyItems:"center", justifyContent:"center"}}>
                <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="maleRadio"
                name="genre"
                value="Male"
                checked={userGenre === "Male"}
                onChange={(e) => {
                  setUserGenre(e.target.value)
                  
                }}
                
              />
              <label className="form-check-label" htmlFor="maleRadio">
                Male
              </label>
            </div>
            
            </div>
              <div className="col" style={{display:"flex", alignItems:"center", justifyItems:"center", justifyContent:"center"}}>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="femaleRadio"
                name="genre"
                value="Female"
                checked={userGenre === "Female"}
                onChange={(e) => {
                  setUserGenre(e.target.value)
                  
                }}
              />
              <label className="form-check-label" htmlFor="femaleRadio">
                Female
              </label>
            </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col text-center" style={{fontSize:"12px"}}>
                  Vous pouvez attribuer le genre que vous voulez. Il ne sert qu'a généré un avatar automatiquement.
                </div>
              </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div
            className="col-md-4"
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Permissions
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col" style={{ marginTop: "10px" }}>
                    Dashboard:
                  </div>
                  <div className="col">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="dashboard"
                        id="dashboard"
                        checked={permissionDashboard}
                        onChange={(e) => {
                          if (permissionDashboard) {
                            setPermissionDashboard(false);
                          } else {
                            setPermissionDashboard(true);
                          }
                        }}
                        value={permissionDashboard}
                      />
                      <label htmlFor="dashboard">
                        <h2>OFF</h2>
                        <h1>ON</h1>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col" style={{ marginTop: "10px" }}>
                    Produits:
                  </div>
                  <div className="col">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="produits"
                        id="produits"
                        checked={permissionProduits}
                        onChange={() => {
                          if (permissionProduits) {
                            setPermissionProduits(false);
                          } else {
                            setPermissionProduits(true);
                          }
                        }}
                      />
                      <label htmlFor="produits">
                        <h2>OFF</h2>
                        <h1>ON</h1>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col" style={{ marginTop: "10px" }}>
                    Employés:
                  </div>
                  <div className="col">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="employes"
                        id="employes"
                        checked={permissionEmployes}
                        onChange={() => {
                          if (permissionEmployes) {
                            setPermissionEmployes(false);
                          } else {
                            setPermissionEmployes(true);
                          }
                        }}
                      />
                      <label htmlFor="employes">
                        <h2>OFF</h2>
                        <h1>ON</h1>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div
                className="col"
                style={{ textAlign: "right", marginTop: "5px" }}
              >
                Envoyer un courriel d'inscription
              </div>
              <div className="col">
                <div className="toggle">
                  <input
                    type="checkbox"
                    id="actif"
                    name="actif"
                    checked={envoyerEmail}
                    onChange={() => {
                      if (envoyerEmail) {
                        setEnvoyerEmail(false);
                      } else {
                        setEnvoyerEmail(true);
                      }
                    }}
                  />
                  <label htmlFor="actif">
                    <h2>OFF</h2>
                    <h1>ON</h1>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>

  );
};

export default AddEmployee;
