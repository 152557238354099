
import projetLogo from "../../imgs/launchpad/projet.png"

import "../../css/projet.css"

const ProjectCardSidebar = ({ project, setNewOrder, newOrder }) => {

  const handleClick = () => {
    console.log("handle click")
    setNewOrder({
      ...newOrder,
      project: project
    })
  }


  return (
    <div className="col">

      <div className="card projectCard" style={{marginTop:"10px"}} onClick={() => { handleClick() }}>
        <div className="card-body">
          <div className="row">
            <div className="col-4"  style={{ display:"flex", justifyContent:"center", justifyItems:"center" }}>
              <img src={projetLogo} alt="" className="projetLogo" width="100%" />
              <div>

              </div>
            </div>
            <div className="col">
              <div style={{ fontSize: "16px" }}> {project.Nom}</div>
              <div style={{ fontSize: "12px" }}>{project.projectType.Name}</div>
              <div style={{ fontSize: "12px" }}>{project.Compagnie.companyName}</div>

            </div>
          </div>


        </div>
      </div>

    </div>

  );

};

export default ProjectCardSidebar;
