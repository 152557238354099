import { useEffect } from "react"



const Horaires = ({setTitrePage}) =>{

    useEffect(()=>{
        setTitrePage("Horaires")
    },[])



    return (
        <div className="container">
            
        </div>
    )

}

export default Horaires