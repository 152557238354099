import "../../css/cssSwitch.css";
import { useNotify } from "../../hooks/useNotify";
import { useState } from "react";
import { useEffect } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import config from "../../config";

const ModifierClient = ({ setTitrePage, saveClient, setSaveClient }) => {



  const params = useParams();
  const { id } = params;

  const { user } = useGlobalContext();
  const { notify } = useNotify();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true)

  const [clientInfo, setClientInfo] = useState({
    firstName: "",
    lastName: "",
    Tel: "",
    Adresse: {
      adresse: "",
      ville: "",
      province: "",
      pays: "",
      codePostal: ""
    },
    Email: "",
  })

  const fetchClient = async () => {
    setIsLoading(true)
    const response = await fetch(
      `${config.BASE_URL}/api/clients/${id}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      setClientInfo({
        firstName: json.userFirstName,
        lastName: json.userLastName,
        Tel: json.userCell,
        Adresse: {
          adresse: json.userAdresse.adresse,
          ville: json.userAdresse.ville,
          province: json.userAdresse.province,
          pays: json.userAdresse.pays,
          codePostal: json.userAdresse.codePostal
        },
        Email: json.userEmail,
      })
    }
    if (!response.ok) {
      notify("error", json.error);
    }
    setIsLoading(false);
  };


  useEffect(() => {
    setTitrePage("Modifier client");
    fetchClient()
  }, []);



  const handleInputChange = (e, field) => {
    const value = e.target.value;

    setClientInfo({
      ...clientInfo,
      Adresse: {
        ...clientInfo.Adresse,
        [field]: value,
      },
    });

  };
  
  useEffect(() => {
    if (saveClient) {

      handleSave()
    }
  }, [saveClient])

  const handleSave = async () => {
    console.log("Saving Client")
    if (
      !clientInfo.firstName ||
      !clientInfo.lastName ||
      !clientInfo.Email ||
      !clientInfo.Tel

    ) {
      notify(
        "warning",
        "Assurez-vous de remplir tous les champs marqués d'un astérisque."
      );
    }
    else {
      try {
        const newUser = {
          userEmail: clientInfo.Email,
          userFirstName: clientInfo.firstName,
          userLastName: clientInfo.lastName,
          userCell: clientInfo.Tel,
          userAdresse: {
            adresse: clientInfo.Adresse.adresse,
            ville: clientInfo.Adresse.ville,
            province: clientInfo.Adresse.province,
            pays: clientInfo.Adresse.pays,
            codePostal: clientInfo.Adresse.codePostal,
          },
        };
        console.log("newUser", newUser)
        const response = await fetch(`${config.BASE_URL}/api/clients/${id}`, {
          method: "PATCH",
          body: JSON.stringify(newUser),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const json = await response.json();

        if (response.ok) {
          notify("success", "Nouveau client ajouté avec succès.");
          navigate(-1);

        }
        if (!response.ok) {
          notify("error", json.error); // Show the error message from the response
        }

      } catch (error) {
        notify("error", error.response.data.message);
      }


    }

    setSaveClient(false);
  };

  return (
    <div className="height120">
      <div
        className="container-fluid"
      >
        <div className="row mt-5">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="firstNameInput"
                    onChange={(e) => {
                      setClientInfo({
                        ...clientInfo,
                        firstName: e.target.value
                      });
                    }}
                    value={clientInfo.firstName}
                    placeholder="firstName"
                  />
                  <label htmlFor="firstNameInput">Prénom*</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="lastNameInput"
                    onChange={(e) => {
                      setClientInfo({
                        ...clientInfo,
                        lastName: e.target.value
                      });
                    }}
                    value={clientInfo.lastName}
                    placeholder="lastName"
                  />
                  <label htmlFor="lastNameInput">Nom de famille*</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="cellulaireInput"
                onChange={(e) => {
                  setClientInfo({
                    ...clientInfo,
                    Tel: e.target.value
                  });
                }}
                value={clientInfo.Tel}
                placeholder="Téléphone"
              />
              <label htmlFor="cellulaireInput">Téléphone*</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="courrielInput"
                onChange={(e) => {
                  setClientInfo({
                    ...clientInfo,
                    Email: e.target.value
                  });
                }}
                value={clientInfo.Email}
                placeholder="Courriel"
              />
              <label htmlFor="courrielInput">Courriel*</label>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control inputHeight"
                id="rueInput"
                onChange={(e) => handleInputChange(e, "adresse")}
                value={clientInfo.Adresse.adresse}
                placeholder="Adresse"
              />
              <label htmlFor="rueInput">Adresse</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="villeInput"
                    onChange={(e) => handleInputChange(e, "ville")}
                    value={clientInfo.Adresse.ville}
                    placeholder="Ville"
                  />
                  <label htmlFor="villeInput">Ville</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="provinceInput"
                    onChange={(e) => handleInputChange(e, "province")}
                    value={clientInfo.Adresse.province}
                    placeholder="Province"
                  />
                  <label htmlFor="provinceInput">Province</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="paysInput"
                    onChange={(e) => handleInputChange(e, "pays")}
                    value={clientInfo.Adresse.pays}
                    placeholder="Country"
                  />
                  <label htmlFor="paysInput">Pays</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control inputHeight"
                    id="codeInput"
                    onChange={(e) => handleInputChange(e, "codePostal")}
                    value={clientInfo.Adresse.codePostal}
                    placeholder="Code postal"
                  />
                  <label htmlFor="codeInput">Code postal</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>

  );
};

export default ModifierClient;

