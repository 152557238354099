import { createContext, useReducer } from 'react'
import { categoriesReducer } from './CategoryContext';
import { productsReducer } from './ProductContext';
import { tagsReducer } from './TagsContext';
import { selectedCategoryReducer } from './selectedCategoryContext';
import { selectedTagsReducer } from './selectedTagContext';
import { userReducer } from './userContext';
import { useEffect } from 'react';

export const GlobalContext = createContext()


export const GlobalContextProvider = ({ children }) => {

    const [producsState, productsDispatch] = useReducer(productsReducer, {
      products: null  
    });
    const [categoriesState, categoriesDispatch] = useReducer(categoriesReducer, {
        categories: null  
      });
     
      const [tagsState, tagsDispatch] = useReducer(tagsReducer, {
        tags: null  
      });
      const [selectedTagsState, selectedTagsDispatch] = useReducer(selectedTagsReducer, {
        selectedTags: []  
      });
      const [selectedCategoriesState, selectedCategoriesDispatch] = useReducer(selectedCategoryReducer, {
        selectedCategories: []  
      });
      const [userState, userDispatch] = useReducer(userReducer, {
        user: null
      });

      useEffect(()=>{

        
        
    }, []);


    return (
        <GlobalContext.Provider value={{...userState, userDispatch, ...producsState, productsDispatch, ...categoriesState, categoriesDispatch, ...tagsState, tagsDispatch, ...selectedCategoriesState, selectedCategoriesDispatch, ...selectedTagsState, selectedTagsDispatch}}>
            { children }
        </GlobalContext.Provider>
    )

}