
import React from 'react';
import userIcon from "../../imgs/interface/user.png"

const ClientCardSidebar = ({ scrollToTop, client, newWorker, setNewWorker, setSelectedClient, noOnClick }) => {

 

  return (
    <div
      className="sidebarCard"
      title={`Ajouter ${client.userFirstName} à l'équipe.`}
      onClick={() => {
        if(!noOnClick)
        {
          scrollToTop()
          const fullName = client.userFirstName + " " + client.userLastName
          setNewWorker({
            ...newWorker,
            fullName,
            client:client
          })
          setSelectedClient(client)
        }
      }}
    >
      <div className="row">
        <div className="col-4">
          
          <img width="100%" src={client.avatar ? client.avatar : userIcon} alt="" style={{ borderRadius: "10px" }} />
        </div>
        <div className="col">
          <div>
            {client.userFirstName} {client.userLastName}
          </div>
          <div>
            {client.userCell}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCardSidebar;
