
import { Link } from "react-router-dom"
import "../../css/card.css"

import companyLogo from "../../imgs/launchpad/compagnie.png"

const CompanyCard = ({ company }) => {


    return (
        <div className="col">
            <Link className="noDecoration" to={`/Compagnies/Details/${company._id}`}>
                <div className="card card-list">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <img src={companyLogo} alt="" />
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    {company.companyName &&
                                        (<>{company.companyName}</>)
                                    }
                                </div>
                               
                                <div className="row" style={{fontSize:"12px"}}>
                                    {company.companyTel &&
                                        (<>{company.companyTel}</>)
                                    }
                                </div>
                                <div className="row" style={{fontSize:"12px"}}>
                                    {company.companyEmail &&
                                        (<>{company.companyEmail}</>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )


}

export default CompanyCard