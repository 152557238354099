import { useEffect, useState } from "react";
import isLoadingIcon from '../../imgs/interface/loading.gif'
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";


const Dashboard = ({setTitrePage, setSelectedApp }) => {

  const {user}= useGlobalContext()

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rows, setRows] = useState([]);

  const [totalProduit, setTotalProduit] = useState(0);
  const [totalInventaire, setTotalInventaire] = useState(0);
  const [totalValeur, setTotalValeur] = useState(0);

  const [isLoading, setIsLoading] = useState(true) 


  const [totalOnline, setTotalOnline] = useState(0)

  const navigate = useNavigate()


  const calculateTotals = () => {
    let newRows = [];
    let grandTotalProduit = 0;
    let grandTotalInventaire = 0;
    let grandTotalValeur = 0;


    categories &&
      categories.map((category) => {
        const filteredProducts = products.filter((product) =>
          product.Categories.some((categoryId) => categoryId === category._id)
        );

        let Row = {
          name: category.Name,
          totalProduit: filteredProducts.length,
          totalInventaire: 0,
          totalValeur: 0,
        };

        let totalI = 0;
        let totalV = 0;

        filteredProducts &&
          filteredProducts.map((product) => {
            totalI = totalI + product.Quantite;
            const totalValeur = product.Quantite * product.Valeur;
            totalV = totalV + totalValeur;
          });
        Row.totalInventaire = totalI;
        Row.totalValeur = totalV;

        newRows = [...newRows, Row];
        grandTotalProduit = grandTotalProduit + Row.totalProduit;
        grandTotalInventaire = grandTotalInventaire + Row.totalInventaire;
        grandTotalValeur = grandTotalValeur + Row.totalValeur;
      });

    setRows(newRows);
    setTotalProduit(grandTotalProduit);
    setTotalInventaire(grandTotalInventaire);
    setTotalValeur(grandTotalValeur);
    //setIsLoading(false)

    const online = products.filter((product) => product.EnLigne)
    setTotalOnline(online.length)
  };

  useEffect(()=>{

    if(totalValeur)
    {
      setIsLoading(false)
    }

  },[totalValeur])

  useEffect(()=>{if(!user)
    {
      navigate("/Login")
    }},[user])

  useEffect(() => {
    
    setTitrePage("Dashboard")
    setIsLoading(true)
    const fetchData = async () => {
      const productsRes = await fetch(
        `${config.BASE_URL}/api/produits/all`
      );
      const categoriesRes = await fetch(
        `${config.BASE_URL}/api/categories`
      );
      const productsData = await productsRes.json();
      const categoriesData = await categoriesRes.json();
      const filteredCategories = categoriesData.filter(
        (category) => !category.parentId
      );
      const sortedCategories = filteredCategories.sort((a, b) =>
        a.Name.localeCompare(b.Name)
      );
      setProducts(productsData);
      setCategories(sortedCategories);
    };
    fetchData();
  }, []);

  useEffect(()=>{
    calculateTotals();
  },[categories])


  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  return (
    <div className="height120">
    <div className="container">
      <h2
        style={{
          marginTop: "20px",
          paddingBottom: "20px",
          borderBottom: "solid thin #a7e4e2",
        }}
      >
        Produits
      </h2>

      <div className="card card-body ">
        <div className="text-end mb-2">
          Total en ligne : {totalOnline}
        </div>
        <div
          className="row"
          style={{ paddingBottom: "10px", borderBottom: "solid thin #a7e4e2" }}
        >
          <div className="col">Catégories</div>
          <div className="col text-center">Total produits</div>
          <div className="col text-center">Total inventaire</div>
          <div className="col text-end">Valeur total</div>
        </div>
        {isLoading && <div className="text-center"><img src={isLoadingIcon} height="160" width="160"/></div>}
        {rows.map((row, index) => (
          <div key={index} className="row" style={{paddingBottom:'10px'}}>
            <div className="col">{row.name}</div>
            <div className="col text-center">{row.totalProduit}</div>
            <div className="col text-center">{row.totalInventaire}</div>
            <div className="col text-end">
              {formatCurrency(row.totalValeur)}
            </div>
          </div>
        ))
        }
        <div
          className="row"
          style={{ borderTop: "solid thin #a7e4e2", paddingTop: "10px" }}
        >
          <div className="col">Total général</div>
          <div className="col text-center">{totalProduit}</div>
          <div className="col text-center">{totalInventaire}</div>
          <div className="col text-end">{formatCurrency(totalValeur)}</div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
