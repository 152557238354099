import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";
import { useState } from "react";
import config from "../../config";
import { BallTriangle } from "react-loader-spinner";

import orderIcon from "../../imgs/launchpad/commandes.png";
import projetIcon from "../../imgs/launchpad/projet.png";
import OrderRow from "../../components/Commandes/orderRow";

const CommandesDetails = ({ setTitrePage, setStateProductsList, commandeActive, setCommandeActive }) => {
    const params = useParams();
    const { id } = params;
    const { user } = useGlobalContext();
    const { notify } = useNotify();
    const [isLoading, setIsloading] = useState(true);

    const [totalDays, setTotalDays] = useState()


    const [items, setItems] = useState([])

    const [item, setItem] = useState({
        produit: "",
        quant: "",
        valeur: "",
        valeurTotal: ""
    })

    const getOrder = async () => {
        try {
            const response = await fetch(`${config.BASE_URL}/api/Commandes/${id}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch commande");
            }
            const json = await response.json();

            console.log(json)
            setCommandeActive(json);
        } catch (error) {
            notify("error", error.message); // Set error message in state variable
        }
        setIsloading(false);
    };
    // Function to format the createdAt date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    useEffect(() => {
        getOrder();
        setTitrePage("Détails commande");

    }, []);

    useEffect(() => {
        if (commandeActive) {
            console.log("My Order : ", commandeActive)
            if (commandeActive.dateDepart && commandeActive.dateRetour) {
                const startDate = new Date(commandeActive.dateDepart);
                const endDate = new Date(commandeActive.dateRetour);
                const timeDifference = endDate - startDate; // Difference in milliseconds

                // Convert the time difference to days
                const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

                // Update the totalDays state with the calculated value
                setTotalDays(daysDifference);
            }
        }


    }, [commandeActive])

    return (
        <div className="height120">
            {isLoading && (
                <div className="container">
                    <div
                        className="row"
                        style={{ marginTop: "40px", marginBottom: "40px" }}
                    >
                        <div className="col w-100">
                            <div
                                style={{
                                    display: "flex",
                                    height: "400px",
                                    paddingTop: "100px",
                                    justifyContent: "center",
                                    justifyItems: "center",
                                }}
                            >
                                <BallTriangle
                                    height={200}
                                    width={200}
                                    radius={5}
                                    color="#ff9900"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && commandeActive && (
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 mt-3">
                        <div className="col">
                            <div className="numero-row-order">
                                <img src={orderIcon} alt="" />
                                <div>
                                    <div className="numero">Commande numéro</div>
                                    <div className="texte">{commandeActive.Numero}</div>
                                </div>
                            </div>

                        </div>
                        <div className="col">
                            <div className="projet-order-2">

                                <img src={projetIcon} alt="" />

                                <div style={{ marginTop: "2px", marginLeft: "20px" }}>
                                    <div style={{ fontSize: "18px", fontWeight: "800" }}>
                                        {commandeActive.Projet.Nom}
                                    </div>
                                    <div style={{ fontSize: "14px" }}>
                                        {commandeActive.Projet.projectType.Name}
                                    </div>
                                    <div style={{ fontSize: "14px" }}>
                                        {commandeActive.Projet.Compagnie.companyName}
                                    </div>

                                </div>


                            </div>

                        </div>
                        <div className="col">

                            <div className="projet-client">
                                <img
                                    src={commandeActive.commandePour.client.avatar}
                                    alt=""
                                />
                                <div style={{ marginLeft: "10px" }}>
                                    <div style={{ fontSize: "14px", fontWeight: "800" }}>
                                        {commandeActive.commandePour.client.userFirstName}{" "}
                                        {commandeActive.commandePour.client.userLastName}
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                        {commandeActive.commandePour.poste}
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                        {commandeActive.commandePour.client.userCell}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3" style={{ fontWeight: "bold" }}>
                        <div className="col text-center" style={{ marginBottom: "20px" }}>
                            Date : {formatDate(commandeActive.createdAt)}
                        </div>
                        <div className="col text-center" style={{ marginBottom: "20px" }}>
                            <div>
                                Date départ : {formatDate(commandeActive.dateDepart)}
                            </div>
                            <div>
                                Date retour : {formatDate(commandeActive.dateRetour)}
                            </div>
                        </div>
                        <div className="col text-center" style={{ marginBottom: "20px" }}>
                            Durée location : {totalDays} jours
                        </div>
                    </div>
                    <div className="spacer"></div>

                    <div>
                    {commandeActive.Produits && commandeActive.Produits.map((product, index)=>{
                        return (<OrderRow key={product._id} index={index} product={product} />)
                    })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommandesDetails;
