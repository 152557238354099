import { useState } from "react";

import { addDays } from "date-fns";
import '../../css/searchBar.css'


const SearchBar = ({fetchData,searching, setSearching, searchQuery, setSearchQuery}) => {


    const [datesLocation, setDatesLocation] = useState("");
    const [pickDate, setPickDate] = useState(false);
    const [dateSortie, setDateSortie] = useState(true);
    const [dateRetour, setDateRetour] = useState(true);
  


    const [state, setState] = useState([
        {
          startDate: addDays(new Date(), 1),
          endDate: addDays(new Date(), 8),
          key: "selection",
        },
      ]);    


    const handleClearDates = (e) => {
      e.preventDefault();
      setDatesLocation("");
      setDateSortie("");
      setDateRetour("");
      //setPickDate(false)
    };
  

    return (
        <div className="searchBar">
            <form className="searchForm" style={{width:'100%'}} onSubmit={(e)=>{
              e.preventDefault()
              setSearching({
                ...searching,
                page:1,
                categorie:"Tous",
                decor:"Tous"
              })
              }}>
                  <input
                    className={searchQuery? "form-control searchText":"form-control searchText backgroundText" }
                    style={{
                      height: "100%",
                      width: "100%",
                      lineHeight: "40px"
                    }}
                    type="search"
                    id="rechercher"
                    placeholder="Rechercher"
                    value={searchQuery} onChange={(e) => {
                      setSearchQuery(e.target.value)
                    }}
                  />
            </form>
        </div>

    )



}

export default SearchBar