import { useEffect, useState } from "react";


import { useGlobalContext } from "../../hooks/useGlobalContext";

const Parametres = ({setTitrePage, setIpadMode, ipadMode}) => {
    
    const{user}=useGlobalContext()
    
    
    const changeIpadMode = () => {



    }
    
    useEffect(()=>{
        
      setTitrePage("Paramètres")
  
},[])

  return (
    <div className="cointainer-fluid">      
      <div className="container">
      <h2
        style={{
          marginTop: "20px",
          paddingBottom: "20px",
          borderBottom: "solid thin #232f3e",
        }}
      >
        Paramètres
      </h2>
      <div>
        <div className="row">
          <div className="col">
            Mode iPad
          </div>
          <div className="col">
            switch on/off
          </div>
        </div>
        <div className="row">
          <div className="col">
            Gestion des postes.
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Parametres