import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../../css/products.css'
import { useGlobalContext } from '../../../hooks/useGlobalContext'
import config from '../../../config';

function CreateCategory(props) {

  const {categoriesDispatch} =  useGlobalContext()

  const [Name, setNewCategoryName] = useState("")
  const [parentId, setnewCategoryparentId] = useState("")
  const [error, setError] = useState(null)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  const addCategory = async () => {

    const newCategory = {Name}

    const response = await fetch(`${config.BASE_URL}/api/categories`, {
      method: 'POST',
      body: JSON.stringify(newCategory),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if(!response.ok){
      setError(json.error)
    }
    if(response.ok){
      categoriesDispatch({type: 'CREATE_CATEGORY', payload: json})
      setNewCategoryName('')
      setnewCategoryparentId('')
      
      setError(null)
      console.log("New Category added")
      
      handleClose()
    }


  }




  return (
    <>
      <div className="btnAdd" onClick={handleShow}>+</div>
      

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ props.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <input 
          type="text"
          className="inputCategory"
          value={Name}
          onChange={(e)=>{
            setNewCategoryName(e.target.value)
          }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={addCategory}>Ajouter</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateCategory