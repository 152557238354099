
import { Link } from "react-router-dom"
import "../../css/card.css"

import companyLogo from "../../imgs/launchpad/compagnie.png"
import userIcon from "../../imgs/interface/user.png"

const ClientCard = ({ client }) => {


    return (
        <div className="col">
            <Link className="noDecoration" to={`/Clients/Details/${client._id}`}>
                <div className="card card-list">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <img src={client.avatar ? client.avatar : userIcon} alt="" style={{borderRadius:"10px"}} />
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    {client.userFirstName &&
                                        (<>{client.userFirstName}</>)
                                    }
                                    {client.userLastName &&
                                        (<> {client.userLastName}</>)
                                    }
                                </div>
                                <div className="row" style={{fontSize:"12px"}}>
                                    {client.userCell &&
                                        (<>{client.userCell}</>)
                                    }
                                </div>
                                <div className="row" style={{fontSize:"12px"}}>
                                    {client.userEmail &&
                                        (<>{client.userEmail}</>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )


}

export default ClientCard