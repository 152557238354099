
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "bootstrap/dist/css/bootstrap.min.css";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import AddProduct from "./pages/Products/addProduct";
import Details from "./pages/Products/details";
import ProductList from "./pages/Products";
import LoginPage from "./pages/login.js";
import Dashboard from "./pages/Dashboard";
import Parametres from "./pages/Parametres";

import AddEmployee from "./pages/Employes/nouveau.js";
import DetailsEmploye from "./pages/Employes/details";

import Employes from "./pages/Employes";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "./hooks/useGlobalContext";
import ModifyProduct from "./pages/Products/modifyProduct";
import Header from "./components/System/header";


import MobileFooter from "./components/System/mobileFooter";
import config from "./config";
import Sidebar from "./components/System/sidebar";
import SidebarFilter from "./components/System/sidebarFilter";
import PasswordResetPage from "./pages/passwordRest";
import ModifyEmployee from "./pages/Employes/modifier";
import Clients from "./pages/Clients";
import Compagnies from "./pages/Compagnies";
import Commandes from "./pages/Commandes";
import Factures from "./pages/Factures";
import Horaires from "./pages/Horaires";
import Projets from "./pages/Projets";
import AddCompany from "./pages/Compagnies/add";

import AddClient from "./pages/Clients/add";
import CompanyDetails from "./pages/Compagnies/details";
import ModifyCompany from "./pages/Compagnies/modifier";
import Home from "./pages/Home";
import DetailsClient from "./pages/Clients/details";
import ModifierClient from "./pages/Clients/modifier";
import NewProject from "./pages/Projets/newProject"
import SidebarClients from "./components/Clients/sidebarClients";
import ProjetDetails from "./pages/Projets/details";
import ModifyProject from "./pages/Projets/modifyProjet";
import AddWorker from "./components/Clients/addWorker";
import NewOrder from "./pages/Commandes/newOrder";
import SidebarProjects from "./components/Projets/sidebarProjet";
import CommandesDetails from "./pages/Commandes/details";
import SidebarProduct from "./components/products/sidebar";


function App() {

  const [addProduct, setAddProduct] = useState(false);
  const [addEmploye, setAddEmploye] = useState(false);
  const [addCompany, setAddCompany] = useState(false);
  
  const [saveProduct, setSaveProduct] = useState(false);
  const [saveEmploye, setSaveEmploye] = useState(false);
  const [saveCompany, setSaveCompany] = useState(false);
  const [saveClient, setSaveClient] = useState(false);
  const [saveProjet, setSaveProjet] = useState(false);
  const [saveCommande, setSaveCommande] = useState(false)
  

  
  const [commandeActive, setCommandeActive] = useState()
  const [activeProduct, setActiveProduct] = useState({});
  const [activeEmploye, setActiveEmploye] = useState({});
  const [activeCompany, setActiveCompany] = useState({});
  const [activeClient, setActiveClient] = useState({});
  const [activeProjet, setActiveProjet] = useState(
    {
      Nom:"",
      projetType:"",
      Compagnie:"",
      Start:"",
      End:"",
      Equipe:[],
      Commandes:[],
      Factures:[]
    }
  );
  
  const [newOrder, setNewOrder] = useState({
    dateRetour: "",
    dateDepart: "",
    project: "",
    commandePour: ""
})
  


  
  const [categoryTree, setCategoryTree] = useState()


  const [products, setProducts] = useState([]);

  const { user, userDispatch, categories, categoriesDispatch } = useGlobalContext();


  const [titrePage, setTitrePage] = useState();

  const [openPanel, setOpenPanel] = useState(false);

  

  //////    Variables de Recherche    //////
  const [isSearching, setIsSearching] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Tous");
  const [selectedTag, setSelectedTag] = useState("All")

  const [searching, setSearching] = useState({
    page: 1,
    limit: 24,
    sortBy: "Number",
    orderBy: 1,
    searchQuery: "",
    categorie: "Tous",
    decor: "Tous"
  })

  const [filtres, setFiltres] = useState({
    Couleur: ["All"],
    Materiaux: ["All"],
    Caracteristique: ["All"],
  });
  


  //////////////////////////////////////////


  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [stateFilter, setStateFilter] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  
  const [stateClientList, setStateClientList] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  
  const [stateProductsList, setStateProductsList] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [stateSidebarProjects, setStateSidebarProjects] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });


  const [ newProject, setNewProject] = useState({
    Nom:"",
    projetType:{
      Name:"",
      id:""
    },
    Compagnie:{
      nom:"",
      id:""
    },
    Start:"",
    End:"",
    Equipe:[]
  })
  
  
  /// Creer la liste des categories
  const fetchCategories = async () => {
    const response = await fetch(`${config.BASE_URL}/api/categories`);
    const json = await response.json();
    categoriesDispatch({ type: "SET_CATEGORIES", payload: json });
  };

  const createCategoryTree = (category, categories) => {
    const subCategories = categories.filter(cat => cat.parentId === category._id);
    if (subCategories.length === 0) {
      return { ...category };
    }
    return {
      ...category,
      children: subCategories.map(subCategory => createCategoryTree(subCategory, categories))
    };
  };

  const createCategories = () => {
    const categoryList = [];
    const rootCategories = categories.filter(cat => cat.parentId == undefined);
    for (let category of rootCategories) {
      categoryList.push(createCategoryTree(category, categories));
    }
    setCategoryTree(categoryList)
  };

  const fetchAllProducts = async () => {
    const response = await fetch(`${config.BASE_URL}/api/produits/all`);
    const json = await response.json();
    if (response.ok) {
      setProducts(json);
    }
  };

  useEffect(() => {
    fetchAllProducts();
    const userConnected = JSON.parse(localStorage.getItem("user"));
    if (!user && userConnected) {
      userDispatch({ type: "LOGIN", payload: userConnected });
    }
  }, []);


  useEffect(() => {
    if (!categories) {
      fetchCategories();
    } else {
      createCategories()
    }
  }, [categories]);

  useState(() => {
    setSelectedTag("All")
  }, [selectedCategory])


  

  return (
    <div className="App">
      <BrowserRouter>
        <div className="Pages">
          {user && (
            <>
            <Header
              setState={setState}
              openPanel={openPanel}
              setOpenPanel={setOpenPanel}
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              titrePage={titrePage}
              setTitrePage={setTitrePage}
              activeProduct={activeProduct}
              searching={searching}
              setSearching={setSearching}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <Routes>
            <Route
              path="/"
              element= {<Home setTitrePage={setTitrePage}/> }
            />
            <Route
              path="/Dashboard"
              element= {<Dashboard setTitrePage={setTitrePage}/> }
            />
            <Route
              path="/Clients"
              element= {<Clients  setTitrePage={setTitrePage} />}
            />
            <Route
              path="/Clients/Details/:id"
              element={
                user ?
                <DetailsClient
                  setTitrePage={setTitrePage}
                  activeClient={activeClient}
                  setActiveClient={setActiveClient}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Clients/Ajouter"
              element= {<AddClient  setTitrePage={setTitrePage} saveClient={saveClient} setSaveClient={setSaveClient} />}
            />
            <Route
              path="/Compagnies"
              element= {<Compagnies  setTitrePage={setTitrePage} />}
            />
            <Route
              path="/Compagnies/Ajouter"
              element= {<AddCompany  setTitrePage={setTitrePage} setAddCompany={setAddCompany} addCompany={addCompany} />}
            />
            <Route
              path="/Compagnies/Details/:id"
              element={
                user ?
                <CompanyDetails
                  setTitrePage={setTitrePage}
                  activeCompany={activeCompany}
                  setActiveCompany={setActiveCompany}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Compagnies/Modifier/:id"
              element={
                user ?
                <ModifyCompany
                  setTitrePage={setTitrePage}
                  saveCompany={saveCompany}
                  setSaveCompany={setSaveCompany}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Clients/Modifier/:id"
              element={
                user ?
                <ModifierClient
                  setTitrePage={setTitrePage}
                  saveClient={saveClient}
                  setSaveClient={setSaveClient}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Projets/Details/:id"
              element={
                user ?
                <ProjetDetails
                  setTitrePage={setTitrePage}
                  activeProjet={activeProjet}
                  setActiveProjet={setActiveProjet}
                  setStateClientList={setStateClientList}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Commandes"
              element= {<Commandes  setTitrePage={setTitrePage} />}
            />
            <Route
              path="/Factures"
              element= {<Factures  setTitrePage={setTitrePage} />}
            />
            <Route
              path="/Projets"
              element= {<Projets  setTitrePage={setTitrePage} setActiveProjet={setActiveProjet} />}
            />
            <Route
              path="/Projets/Ajouter"
              element= {<NewProject setActiveProjet={setActiveProjet} saveProjet={saveProjet} setSaveProjet={setSaveProjet}  setTitrePage={setTitrePage} setStateClientList={setStateClientList} newProject={newProject} setNewProject={setNewProject} />}
            />
            <Route
              path="/Horaires"
              element= {<Horaires  setTitrePage={setTitrePage} />}
            />
            <Route
              path="/Produits"
              element={
                
                <ProductList
                  isSearching={isSearching}
                  setIsSearching={setIsSearching}
                  setTitrePage={setTitrePage}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                  searching={searching}
                  setSearching={setSearching}
                  filtres={filtres}
                  setFiltres={setFiltres}
                />

              }
            />

            <Route
              path="/Produits/Ajouter"
              element={
                user ?
                <AddProduct
                  titrePage={titrePage}
                  setTitrePage={setTitrePage}
                  addProduct={addProduct}
                  setAddProduct={setAddProduct}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Commandes/Ajouter"
              element={
                user ?
                <NewOrder
                  
                  setTitrePage={setTitrePage}
                  setStateSidebarProjects={setStateSidebarProjects}
                  newOrder={newOrder}
                  setNewOrder={setNewOrder}
                  activeProjet={activeProjet}
                  saveCommande={saveCommande}
                  setSaveCommande={setSaveCommande}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Commandes/:id"
              element= {<CommandesDetails  setTitrePage={setTitrePage} setStateProductsList={setStateProductsList} commandeActive={commandeActive} setCommandeActive={setCommandeActive} />}
            />
            <Route
              path="/Produits/Modifier/:id"
              element={
                user ?
                <ModifyProduct
                  titrePage={titrePage}
                  setTitrePage={setTitrePage}
                  activeProduct={activeProduct}
                  setActiveProduct={setActiveProduct}
                  saveProduct={saveProduct}
                  setSaveProduct={setSaveProduct}
                />
                :
                <Navigate to="/" />
              }
            />
<Route
              path="/Projets/Modifier/:id"
              element={
                user ?
                <ModifyProject
                  titrePage={titrePage}
                  setTitrePage={setTitrePage}
                  activeProjet={activeProjet}
                  setActiveProjet={setActiveProjet}
                  saveProjet={saveProjet}
                  setSaveProjet={setSaveProjet}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Produits/:id"
              element={
                user ?
                <Details
                  titrePage={titrePage}
                  setTitrePage={setTitrePage}
                  activeProduct={activeProduct}
                  setActiveProduct={setActiveProduct}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route path="/reset-password/:email/:token" element={<PasswordResetPage />} />

            <Route
              path="/Parametres"
              element={
              user ?
              <Parametres setTitrePage={setTitrePage} />
              :
              <Navigate to="/" />
            }
            />
            <Route
              path="/Employes"
              element={
                user ?
                <Employes
                  setActiveEmploye={setActiveEmploye}
                  setTitrePage={setTitrePage}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Employes/Nouveau"
              element={
                user ?
                <AddEmployee
                  addEmploye={addEmploye}
                  setAddEmploye={setAddEmploye}
                  setTitrePage={setTitrePage}
                  saveEmploye={saveEmploye}
                  setSaveEmploye={setSaveEmploye}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Employes/Details/:id"
              element={
                user ?
                <DetailsEmploye
                  
                  activeEmploye={activeEmploye}
                  setActiveEmploye={setActiveEmploye}
                  setTitrePage={setTitrePage}
                />
                :
                <Navigate to="/" />
              }
            />
            <Route
              path="/Employes/Modifier"
              element={
                user ?
                <ModifyEmployee
                  saveEmploye={saveEmploye}
                  setSaveEmploye={setSaveEmploye}
                  activeEmploye={activeEmploye}
                  setActiveEmploye={setActiveEmploye}
                  setTitrePage={setTitrePage}
                />
                :
                <Navigate to="/" />
              }
            />
          </Routes>
          <MobileFooter
              activeProduct={activeProduct}
              setAddProduct={setAddProduct}
              setSaveProduct={setSaveProduct}
              
              activeEmploye={activeEmploye}
              setSaveEmploye={setSaveEmploye}

              commandeActive={commandeActive}

              activeCompany={activeCompany}
              setSaveCompany={setSaveCompany}
              setAddCompany={setAddCompany}

              setSaveCommande={setSaveCommande}

              setSaveProjet={setSaveProjet}
              activeProjet={activeProjet}

              activeClient={activeClient} 
              setActiveClient={setActiveClient}
              setSaveClient={setSaveClient}

              setStateFilter={setStateFilter}
              filtres={filtres}
              setFiltres={setFiltres}
              
              titrePage={titrePage}

              setStateProductsList={setStateProductsList}
              

            />
            </>
            
            
          )}


          {!user && (
          <Routes>
          <Route path="/" element={<LoginPage />} />
          </Routes>
          )}



          <SlidingPane
            isOpen={state.isPaneOpenLeft}
            from="left"
            width="250px"
            hideHeader={true}
            onRequestClose={() => setState({ isPaneOpenLeft: false })}
            overlayClassName="noPadding"
          >
            <Sidebar products={products} searching={searching} setSearching={setSearching} setSelectedTag={setSelectedTag} setState={setState} setSelectedCategory={setSelectedCategory} categoryTree={categoryTree} />
          </SlidingPane>

          <SlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            width="250px"
            isOpen={stateFilter.isPaneOpen}
            title="Filtres"
            subtitle="De recherche"
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              setStateFilter({ isPaneOpen: false });
            }}
          >
            <SidebarFilter filtres={filtres} setFiltres={setFiltres} searching={searching} setSearching={setSearching} />
          </SlidingPane>

          <SlidingPane
            width="250px"
            isOpen={stateClientList.isPaneOpen}
            title="Ajouter des clients"
            subtitle="Projet"
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              setStateClientList({ isPaneOpen: false });
            }}
          >
            <AddWorker setStateClientList={setStateClientList} newProject={newProject} setNewProject={setNewProject} activeProjet={activeProjet} setActiveProjet={setActiveProjet} />
          </SlidingPane>
          <SlidingPane
            width="250px"
            isOpen={stateSidebarProjects.isPaneOpen}
            title="Ajouter un projet"
            subtitle="Commande"
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              setStateSidebarProjects({ isPaneOpen: false });
            }}
          >
            <SidebarProjects setNewOrder={setNewOrder} newOrder={newOrder} />
          </SlidingPane>

          <SlidingPane
            width="250px"
            isOpen={stateProductsList.isPaneOpen}
            title="Ajouter un produit"
            subtitle="Commande"
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              setStateProductsList({ isPaneOpen: false });
            }}
          >
            <SidebarProduct commandeActive={commandeActive} setCommandeActive={setCommandeActive} searching={searching} setSearching={setSearching} />
          </SlidingPane>

        </div>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        theme="colored"
      />

    </div>
  );
}

export default App;
