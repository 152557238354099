import { useEffect, useState } from "react";
import SearchBar from "../../components/Projets/searchBar";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import config from "../../config.js";
import { useNotify } from "../../hooks/useNotify";
import { BallTriangle } from "react-loader-spinner";
import ProjectCard from "../../components/Projets/projectCard";

const Projets = ({ setTitrePage, setActiveProjet }) => {
  const { user } = useGlobalContext();

  const [projets, setProjets] = useState();
  const [isLoading, setIsLoading] = useState(true);
  
  
  const loadProjets = async () => {
    setIsLoading(true);
    
    try {
      const response = await fetch(`${config.BASE_URL}/api/projects`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }
      const json = await response.json();

      if (json.length != 0) {
        setProjets(json);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTitrePage("Projets");
    if (!projets) {
      loadProjets();
    }
    setActiveProjet({
      Nom: "",
      projetType: "",
      Compagnie: "",
      Start: "",
      End: "",
      Equipe: [],
      Commandes: [],
      Factures: [],
    });
  }, []);

  return (
    <div className="height120">
      <div className="container">
        <SearchBar />
        {isLoading && (
          <div className="col w-100">
            <div
              style={{
                display: "flex",
                height: "400px",
                paddingTop: "100px",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <BallTriangle
                height={200}
                width={200}
                radius={5}
                color="#ff9900"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
            {projets &&
              projets.map((projet) => (
                <ProjectCard key={projet._id} project={projet} />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projets;
