import { useState } from "react";

import { addDays } from "date-fns";
import '../../css/searchBar.css'


const SearchBarMini = () => {
   
  const [searchClient, setSearchClient] = useState()
  

    return (
        <div className="searchBar mb-3">
            <form className="searchForm" style={{width:'100%'}} onSubmit={(e)=>{
              e.preventDefault()
              }}>
                  <input
                    className={searchClient? "form-control searchText":"form-control searchText backgroundTextMini" }
                    style={{
                      height: "100%",
                      width: "100%",
                      lineHeight: "40px"
                    }}
                    type="search"
                    id="rechercher"
                    placeholder="Rechercher"
                    value={searchClient} onChange={(e) => {
                      setSearchClient(e.target.value)
                    }}
                  />
            </form>
        </div>

    )



}

export default SearchBarMini