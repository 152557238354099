import { useState, useEffect } from "react";
import { addDays } from "date-fns";
import { DateRange } from "react-date-range";

import * as locales from "react-date-range/dist/locale";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import ProjectCard from "../../components/Projets/projectCard";
import { useNotify } from "../../hooks/useNotify";
import config from "../../config";
import { useNavigate } from "react-router-dom";

const NewOrder = ({
    setTitrePage,
    setStateSidebarProjects,
    newOrder,
    setNewOrder,
    activeProjet,
    saveCommande,
    setSaveCommande
}) => {
    const { user } = useGlobalContext();
    const [numberOfMonths, setNumberOfMonths] = useState(2);
    const { notify } = useNotify()
    const [period, setPeriod] = useState([
        {
            startDate: addDays(new Date(), 1),
            endDate: addDays(new Date(), 1),
            key: "selection",
        },
    ]);

    const navigate = useNavigate()

    const [totalDays, setTotalDays] = useState();

    useEffect(() => {
        if (period.length === 1) {
            const startDate = new Date(period[0].startDate);
            const endDate = new Date(period[0].endDate);
            const timeDifference = endDate - startDate; // Difference in milliseconds

            // Convert the time difference to days
            const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

            // Update the totalDays state with the calculated value
            setTotalDays(daysDifference);
        }
        setNewOrder({
            ...newOrder,
            dateDepart: period[0].startDate,
            dateRetour: period[0].endDate,
        });
    }, [period]);

    useEffect(() => {
        setTitrePage("Ajouter commande");
    }, []);

    useEffect(() => {
        console.log("New Order", newOrder)
    }, [newOrder]);


    const addCommande = async () => {
        if (!newOrder.project) {
            notify("error", "Veuillez choisir un projet pour la nouvelle commande svp.")
        } else if (totalDays == 0) {
            notify("error", "Veuillez sélectionner une pédiode de location svp.")
        } else if (!newOrder.commandePour) {
            notify("error", "Veuillez sélectionner un client pour cette commande svp.")
        }
        else {
            const newCommande = {
                dateDepart: newOrder.dateDepart,
                dateRetour: newOrder.dateRetour,
                Projet: newOrder.project._id,
                commandePour: {
                    client: newOrder.commandePour._id,
                    poste: newOrder.commandePour.poste,
                }

            }
            try {
                const response = await fetch(`${config.BASE_URL}/api/Commandes/`, {
                    method: "POST",
                    body: JSON.stringify(newCommande),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                if (!response.ok) {
                    const json = await response.json();
                    throw new Error(json.error);
                }

                notify("success", "Nouvelle commande ajouté avec succès.");
                setNewOrder({
                    dateRetour: "",
                    dateDepart: "",
                    project: "",
                    commandePour: ""
                })
                navigate(-1);
            } catch (error) {
                notify("error", error.message);
            }
        }

        setSaveCommande(false)
    }

    useEffect(() => {
        if (saveCommande) {
            addCommande()
        }
    }, [saveCommande])



    useEffect(() => {
            setNewOrder({
                dateRetour: "",
                dateDepart: "",
                project: "",
                commandePour: ""
        })
    }, [])


    useEffect(() => {
        // Update the number of months when the window is resized
        function handleResize() {
            setNumberOfMonths(calculateNumberOfMonths());
        }

        // Attach the event listener
        window.addEventListener("resize", handleResize);
        handleResize();
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Function to calculate the number of months based on screen width
    function calculateNumberOfMonths() {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1200) {
            return 3; // Set the number of months for large screens
        } else if (screenWidth >= 768) {
            return 2; // Set the number of months for medium screens
        } else {
            return 1; // Set the number of months for small screens
        }
    }

    return (
        <div className="height120">
            <div className="container">
                <div className="mt-4">
                    <div className="row">
                        <div className="col-12 col-md-3"></div>
                        <div className="col-12 col-md-6 d-flex">
                            {newOrder.project && <ProjectCard project={newOrder.project} />}
                            {!newOrder.project && (<div className="card w-100"><div className="card-body text-center">Ajouter un projet</div></div>)}
                            <div style={{ marginLeft: "10px", display: "flex", justifyContent: "center", justifyItems: "center" }}>

                                <div className={newOrder.project ? "btnEdit" : "btnPlus"} title={newOrder.project ? "Changer le projet" : "Ajouter un projet"} onClick={() => {
                                    setStateSidebarProjects({ isPaneOpen: true });
                                }}>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    {newOrder.project && (
                        <div className="row">
                            <div className="col-12 col-md-3"></div>
                            <div className="col-12 col-md-6">
                                <div className="form-floating mb-3 d-flex">
                                    <select
                                        className="form-control downIcon"
                                        id="projetType"
                                        placeholder="Now du projet"
                                        onChange={(e) => {

                                            setNewOrder({
                                                ...newOrder,
                                                commandePour: {
                                                    Name: e.target.value,
                                                    poste:e.target.options[
                                                        e.target.selectedIndex
                                                    ].getAttribute("name"),
                                                    _id: e.target.options[
                                                        e.target.selectedIndex
                                                    ].getAttribute("id"),
                                                },
                                            });
                                        }}
                                        value={newOrder.commandePour.Name}
                                    >
                                        <option></option>
                                        {newOrder.project &&
                                            newOrder.project.Equipe.map((teammate) => {
                                                return (
                                                    <option
                                                        key={teammate.client._id}
                                                        value={teammate.fullName}
                                                        name={teammate.poste.Name}
                                                        id={teammate.client._id}
                                                    >
                                                        {teammate.fullName}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <label htmlFor="projetType">Commande pour</label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="subTitleBar">
                    <div className="texte">Durée de la location : {totalDays} jours</div>
                </div>
                <div className="text-center mt-4">
                    <DateRange
                        onChange={(item) => setPeriod([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={numberOfMonths}
                        ranges={period}
                        rangeColors={["#0979be"]}
                        direction="horizontal"
                        locale={locales["fr"]}
                        minDate={new Date()}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewOrder;
