import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";
import { useState } from "react";
import { BallTriangle } from "react-loader-spinner";

import config from "../../config.js"
import projetIcon from "../../imgs/launchpad/projet.png"
import companyIcon from "../../imgs/launchpad/compagnie.png"
import ClientCardProjet from "../../components/Clients/clientCardProjet";


const ProjetDetails = ({ setTitrePage, activeProjet, setActiveProjet, setStateClientList }) => {
    const params = useParams();
    const { id } = params;

    const { user } = useGlobalContext();
    const { notify } = useNotify();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);


    const getProjetDetails = async () => {
        try {

            const response = await fetch(`${config.BASE_URL}/api/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch projets");
            }
            const json = await response.json();

            setActiveProjet(json);



        } catch (error) {
            notify("error", error.message); // Set error message in state variable
        }
        setIsLoading(false)

    }



    useEffect(() => {
        getProjetDetails()
        setTitrePage("Détails projet")
    }, [])

    return (
        <div className="height120">
<div className="container">
            {isLoading && (
                <div className="col w-100">
                    <div
                        style={{
                            display: "flex",
                            height: "400px",
                            paddingTop: "100px",
                            justifyContent: "center",
                            justifyItems: "center",
                        }}
                    >
                        <BallTriangle
                            height={200}
                            width={200}
                            radius={5}
                            color="#ff9900"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                        />
                    </div>
                </div>
            )}
            {!isLoading && activeProjet &&
                <div>
                    <div className="subTitleBar mt-4">
                        <div className="texte">
                        Information projet
                        </div>
                        
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 mt-2">
                        <div className="col">
                            <div className="card mt-3" style={{minHeight:"125px", background:"none"}}>
                                <div className="row">
                                    <div className="col-4 p-4">
                                        <img src={projetIcon} width="100%" alt="" />
                                    </div>
                                    <div className="col pt-4">
                                        <h5>
                                            {activeProjet.Nom}
                                        </h5>
                                        <div>
                                            {activeProjet.projectType.Name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card  mt-3" style={{minHeight:"125px", background:"none"}}>
                                <div className="row">
                                    <div className="col-4 p-4">
                                        <img src={companyIcon} width="100%" alt="" />
                                    </div>
                                    <div className="col pt-4">
                                        <div>
                                            {activeProjet.Compagnie.companyName}
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                            <div>
                                                {activeProjet.Compagnie.companyAdresse.adresse}
                                            </div>
                                            <div>
                                                {activeProjet.Compagnie.companyAdresse.ville}, {activeProjet.Compagnie.companyAdresse.province}
                                            </div>
                                            <div>
                                                {activeProjet.Compagnie.companyAdresse.pays}, {activeProjet.Compagnie.companyAdresse.codePostal}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="subTitleBar mt-4">
            <div className="texte">
              Équipe</div>
            <div className="btnPlus" title="Ajouter un client à l'équipe." onClick={() => { setStateClientList({ isPaneOpen: true }) }}>

            </div>

          </div>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mb-4 mt-1">
            {activeProjet.Equipe.length == 0 &&
              <div className="w-100 text-center ">Ajouter un client à l'équipe</div>
            }

            {activeProjet.Equipe &&
              activeProjet.Equipe.map((equipier) => {
                return (
                  <ClientCardProjet key={equipier.client._id} client={equipier} activeProjet={activeProjet} setActiveProjet={setActiveProjet} />
                )
                

              })}
          </div>
                    <div className="subTitleBar">
                        <div className="texte">Commandes</div>
                        <Link to="/Commandes/Ajouter" >
                        <div className="btnPlus">

                        </div>
                        </Link>
                    </div>
                    <div className="text-center mt-4">Aucunne Commande</div>
                    <div className="subTitleBar">
                        <div className="texte">Factures</div>
                    </div>
                    <div className="text-center mt-4">Aucunne Facture</div>
                </div>
            }
            <div>

            </div>
        </div>
        </div>
        


    )

}


export default ProjetDetails