import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/employe.css";
import userLogo from "../../imgs/interface/user.png";

const EmployeCard = ({ employe, setActiveEmploye }) => {

    const [point, setPoint]= useState()

    useEffect(()=>{
        if(employe.user && employe.user.userVerified)
        {
            setPoint("pointVert")
        }
        else{
            setPoint("pointRouge")
        }
    }, [employe])

  return (
    <div className="col">
      <Link className="noDecoration" to={`/Employes/Details/${employe._id}`} onClick={()=>{setActiveEmploye(employe)}}>
        <div className="card employe-Card">
          <div className="card-body">
            <div className="row">
              <div className="col-4">
                <img src={employe.user.avatar ? employe.user.avatar : userLogo} alt="" style={{borderRadius:"10px"}}/>
              </div>
              <div className="col-8">
                <div className="row">
                {employe.user && 
                (<>{employe.user.userLastName}, {employe.user.userFirstName}</>)
                }
                  
                </div>
                <div className="row">
                {employe.poste && employe.poste.Name}
                </div>
                <div className="row"  style={{fontSize:'12px'}}>
                    <div className="col" style={{padding:'0px', margin:'0px'}}>
                    {employe.user && employe.user.userEmail}
                    </div>
                </div>
                <div className="row"  style={{fontSize:'12px'}}>
                    <div className="col" style={{padding:'0px', margin:'0px'}}>
                    {employe.user && employe.user.userCell}
                    </div>
                </div>
                <div className="row" style={{paddingRight:'10px'}}>
                    <div className={point} style={{paddingRight:'20px', fontSize:'12px'}}>
                        { point == "pointRouge" ? "En attente" : "Compte confirmé"}
                    </div>
                </div>   
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default EmployeCard;
