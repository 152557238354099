import React from "react";
import { Link } from "react-router-dom";
import defaultImage from "../../imgs/interface/imgIcon.png";

import "../../css/products.css";

import { useGlobalContext } from "../../hooks/useGlobalContext";
import pointVert from '../../imgs/interface/green-dot.png'
import pointRouge from '../../imgs/interface/red-dot.png'

import { useEffect, useState } from "react";
import { useNotify } from "../../hooks/useNotify";

import config from "../../config";

const ProductCard = React.forwardRef(({ products }, ref ) => {
  
  const [enStock, setEnStock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { notify } = useNotify();

  const [image, setImage] = useState();

  useEffect(() => {
    getImage();
  }, [products]);

  const getImage = async () => {
    setIsLoading(true);
    if (products.Image) {
      setImage(products.Image);
    } else {
      const response = await fetch(
        `${config.BASE_URL}/api/images/${products._id}`
      );
      try {
        const json = await response.json();
        if (response.ok) {
          const img = new Image();
          img.onload = async () => {
            const imageUrl = await getSignedURL(json.awsKey);

            setImage(imageUrl);
            setIsLoading(false);
          };
          img.onerror = () => {
            console.log("Error loading image. Using default image.");
            setImage(defaultImage);
          };
          img.src = await getSignedURL(json.awsKey);
        } else {
          setImage(defaultImage);
        }
      } catch (error) {
        setImage(defaultImage);
      }
    }
  };

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      // Handle errors here, e.g., return a default image URL.
      return defaultImage;
    }
  };


  useEffect(() => {
    var stockLigne;
    if (products.Quantite > 1) {
      stockLigne = products.Quantite + " de disponibles";
      setEnStock(stockLigne);
    } else {
      stockLigne = products.Quantite + " de disponible";
      setEnStock(stockLigne);
    }
  }, [products.Quantite]);

  const productBody = (
    <>
    
     
      <Link className="noDecoration" to={`/Produits/${products._id}`}>
        <div className="card card-item">
          <div className="card-item-img" style={{border:"none"}}>
          {image && 
          <img src={image} alt="" style={{border:"none"}} />
          }
          </div>

          <div className="card-title productTitle text-start">
            {products.Number} - {products.Name}
          </div>

          <div className="card-title">
            <div className="disponibleCard">{enStock}</div>
            <div style={{width:'15px', float:'right', marginRight:'10px', marginTop:'5px'}}><img src={products.EnLigne?pointVert:pointRouge} /></div>
          </div>
        </div>
      </Link>
   
    
    </> 
  );

  const content = ref ? (
    <div className="col" ref={ref}>
      {productBody}
    </div>
  ) : (
    <div className="col">{productBody}</div>
  );

  return content ;
});
export default ProductCard;

