import { useRef, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import axios from "axios";

import CategoriesCheckboxes from "../../components/products/categories/categories";
import TagsCheckboxes from "../../components/products/tags/tags";
import config from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import "../../css/imgBox.css";
import "../../css/cssSwitch.css";
import "../../css/productDetails.css";

import defaultIcon from "../../imgs/interface/imgIcon.png";

import { useNotify } from "../../hooks/useNotify";
import ImgBox from "../../components/products/imgBox/imgBox";

const FormData = require("form-data");

const AddProduct = ({titrePage, setTitrePage, addProduct, setAddProduct}) => {
  
  const [error, setError] = useState(null);
  //Declaration de mes States
  const [Number, setpNumber] = useState("");
  const [Name, setName] = useState("");
  const [Year, setYear] = useState("");
  const [Brand, setBrand] = useState("");
  const [Model, setModel] = useState("");

  const [Largeur, setLargeur] = useState("");
  const [Profondeur, setProfondeur] = useState("");
  const [Hauteur, setHauteur] = useState("");
  const [Diametre, setDiametre] = useState("");
  const [Description, setDescription] = useState("");

  const [Quantite, setQuantite] = useState("");
  const [Prix, setPrix] = useState("");
  const [Valeur, setValeur] = useState("");
  const [Semaine, setSemaine] = useState("20");
  const [SemainePlus, setSemainePlus] = useState("2,5");
  const [Semaine2, setSemaine2] = useState("5");
  const [TotalSemaine, setTotalSemaine] = useState("");
  const [TotalSemainePlus, setTotalSemaineplus] = useState("");
  const [TotalMois, setTotalMois] = useState("");
  const [EnLigne, setEnLigne] = useState(false);
  const [Aquisition, setAquisition] = useState(new Date());

  const [imagesList, setImagesList] = useState([]);
  const [activeImage, setActiveImage] = useState(defaultIcon); // default image

  const [startDate, setStartDate] = useState(new Date());
  //const [allCategories, setAllCategories] = useState(null);

  const [checkedCategories, setCheckedCategories] = useState([]);
  const [checkedTags, setCheckedTags] = useState([]);

  const {
    user,
    tags,
    tagsDispatch
  } = useGlobalContext();

  useEffect(() => {
    const fetchTags = async () => {
      const response = await fetch(`${config.BASE_URL}/api/tags`);
      const json = await response.json();
      tagsDispatch({ type: "SET_TAGS", payload: json });
    };
    setTitrePage("Nouveau produit")
    if (!tags) {
      fetchTags();
    }
  }, []);

  useEffect(()=>{
    if(addProduct)
    {
      handleClick()
    }
  },[addProduct])

  const { notify } = useNotify();

  const navigate = useNavigate();

  useEffect(()=>{if(!user)
    {
      navigate("/Login")
    }},[user])

    
  const handleClick = async () => {
    const Categories = checkedCategories.map((category) => category._id);
    const Tags = checkedTags.map((tag) => tag._id);

    if (Number == "") {
      notify("error", "Vous devez inscrire un numéro");
    }else if (Name == "") {
      notify("error", "Vous devez inscrire un nom");
    }else if (Categories.length == 0) {
      notify("error", "Vous devez cocher une catégorie");
    }

    if (Number && Name && Categories.length != 0) {
      // Create new FormData object
      const form = new FormData();

      // Append form fields to FormData object
      form.append("Number", Number);
      form.append("Name", Name);
      form.append("Year", Year);
      form.append("Brand", Brand);
      form.append("Model", Model);
      form.append("Largeur", Largeur);
      form.append("Profondeur", Profondeur);
      form.append("Hauteur", Hauteur);
      form.append("Diametre", Diametre);
      form.append("Description", Description);
      form.append("Quantite", Quantite);
      form.append("Prix", Prix);
      form.append("Valeur", Valeur);
      form.append("Semaine", Semaine);
      form.append("Semaine2", Semaine2);
      form.append("SemainePlus", SemainePlus);
      form.append("Categories", Categories);
      form.append("Tags", Tags);
      form.append("EnLigne", EnLigne);
      form.append("Aquisition", Aquisition);

      const sortedImagesList = imagesList.sort((a, b) => a.order - b.order);

      // Append images to FormData object
      sortedImagesList.forEach((image) => {
        form.append("Images", image.file);
      });

      try {
        const response = await axios.post(
          `${config.BASE_URL}/api/produits`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const json = response.data;
        setCheckedCategories([]);
        setCheckedTags([]);

        setpNumber("");
        setName("");
        setYear("");
        setBrand("");
        setModel("");
        setError(null);
        notify("success", "Produit ajouter avec succès");
        navigate(-1);
      } catch (error) {
        notify("error", error.response.data.message);
      }
    }
    setAddProduct(false)
  };



  return (
    <div className="height120">
      <div className="container-fluid">
        
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
          <div className="col">
            <ImgBox titrePage={titrePage} activeImage={activeImage} setActiveImage={setActiveImage} imagesList={imagesList} setImagesList={setImagesList}/>
            
          </div>
          <div className="col">
            <div className="card shadow-sm cardProductInfo">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">Détails du produits</div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-3">
                    <input
                      className="input90 text-center"
                      type="text"
                      placeholder="NO"
                      onChange={(e) => setpNumber(e.target.value)}
                      value={Number}
                      required
                    />
                  </div>
                  <div className="col-9">
                    <input
                      type="text"
                      className="input90"
                      placeholder="Titre "
                      onChange={(e) => setName(e.target.value)}
                      value={Name}
                      required
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-12">
                    <input
                      type="text"
                      className="input90"
                      placeholder="Marque "
                      onChange={(e) => setBrand(e.target.value)}
                      value={Brand}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-3">
                    <input
                      type="text"
                      className="input90 text-center"
                      placeholder="Année "
                      onChange={(e) => setYear(e.target.value)}
                      value={Year}
                    />
                  </div>
                  <div className="col-9">
                    <input
                      type="text"
                      className="input90"
                      placeholder="Modèle "
                      onChange={(e) => setModel(e.target.value)}
                      value={Model}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-3 ">
                    Largeur
                    <br />
                    <input
                      type="number"
                      className="input70 text-center"
                      onChange={(e) => setLargeur(e.target.value)}
                      value={Largeur}
                    />
                    cm
                  </div>
                  <div className="col-3 ">
                    Profondeur
                    <br />
                    <input
                      type="number"
                      className="input70 text-center"
                      onChange={(e) => setProfondeur(e.target.value)}
                      value={Profondeur}
                    />
                    cm
                  </div>
                  <div className="col-3 ">
                    Hauteur
                    <br />
                    <input
                      type="number"
                      className="input70 text-center"
                      onChange={(e) => setHauteur(e.target.value)}
                      value={Hauteur}
                    />
                    cm
                  </div>
                  <div className="col-3 ">
                    Diamètre
                    <br />
                    <input
                      type="number"
                      className="input70 text-center"
                      onChange={(e) => setDiametre(e.target.value)}
                      value={Diametre}
                    />
                    cm
                  </div>
                </div>

                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-12">
                    <textarea
                      className="descriptiontext"
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value)}
                      value={Description}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-4 ">
                    Quantité
                    <br />
                    <input
                      type="number"
                      className="input50 text-center"
                      onChange={(e) => setQuantite(e.target.value)}
                      value={Quantite}
                    />
                  </div>
                  <div className="col-4 ">
                    Prix payé
                    <br />
                    <input
                      type="number"
                      className="input50 text-center"
                      onChange={(e) => setPrix(e.target.value)}
                      value={Prix}
                    />
                    $
                  </div>
                  <div className="col-4 ">
                    Valeur
                    <br />
                    <input
                      type="number"
                      className="input50 text-center"
                      value={Valeur}
                      onChange={(e) => {
                        setValeur(e.target.value);
                        setTotalSemaine((e.target.value * Semaine) / 100);
                        setTotalSemaineplus(
                          (e.target.value * SemainePlus) / 100
                        );
                        setTotalMois((e.target.value * SemainePlus) / 100);
                      }}
                    />
                    $
                  </div>
                </div>

                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-4 ">
                    1re Semaine
                    <br />
                    <input
                      type="number"
                      className="input50 text-center"
                      onChange={(e) => {
                        setSemaine(e.target.value);
                        setTotalSemaine((e.target.value * Valeur) / 100);
                      }}
                      value={Semaine}
                    />
                    %
                  </div>
                  <div className="col-4 ">
                    2e Semaine<br />
                    <input
                      type="number"
                      className="input50 text-center"
                      onChange={(e) => {
                        setSemaine2(e.target.value);
                        setTotalSemaineplus((e.target.value * Valeur) / 100);
                      }}
                      value={Semaine2}
                    />
                    %
                  </div>
                  <div className="col-4 ">
                    Semaine +
                    <br />
                    <input
                      type="number"
                      className="input50 text-center"
                      onChange={(e) => {
                        setSemainePlus(e.target.value);
                        setTotalMois((e.target.value * Valeur) / 100);
                      }}
                      value={SemainePlus}
                    />
                    %
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-4 ">
                    <input
                      type="number"
                      className="input50 text-center"
                      onChange={(e) => setTotalSemaine(e.target.value)}
                      value={TotalSemaine}
                    />
                    $
                  </div>
                  <div className="col-4 ">
                    <input
                      type="number"
                      className="input50 text-center"
                      onChange={(e) => setTotalSemaineplus(e.target.value)}
                      value={TotalSemainePlus}
                    />
                    $
                  </div>
                  <div className="col-4">
                    <input
                      type="number"
                      className="input50 text-center"
                      value={TotalMois}
                      onChange={(e) => {
                        setTotalMois(e.target.value);
                      }}
                    />
                    $
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div
                    className="col-4 text-sm-end"
                    style={{ lineHeight: "32px" }}
                  >
                    En ligne :
                  </div>
                  <div className="col-8">
                    <div className="toggle">
                      <input
                        type="checkbox"
                        name="statut"
                        id="statut"
                        onChange={(e) => {
                          setEnLigne(e.target.checked);
                          console.log(e.target.checked);
                        }}
                        checked={EnLigne}
                      />
                      <label htmlFor="statut">
                        <h2>OFF</h2>
                        <h1>ON</h1>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div
                    className="col-4 text-sm-end"
                    style={{ lineHeight: "32px" }}
                  >
                    Date d'aquisition :
                  </div>
                  <div className="col-8">
                    <DatePicker
                      className="input50 text-center"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setAquisition(date);
                        console.log(date);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CategoriesCheckboxes
          checkedCategories={checkedCategories}
          setCheckedCategories={setCheckedCategories}
        />

        <TagsCheckboxes
          checkedTags={checkedTags}
          setCheckedTags={setCheckedTags}
        />
      </div>

    </div>
  );
};

export default AddProduct;
