import { useState, useEffect, useMemo } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addDays,
} from "date-fns";
import { DateRange } from "react-date-range";

import * as locales from "react-date-range/dist/locale";
import { useNotify } from "../../hooks/useNotify";


import config from "../../config.js"

// Import components
import AddType from "../../components/Projets/addType";
import ClientCardProjet from "../../components/Clients/clientCardProjet";

const ModifyProject = ({ setTitrePage, setStateClientList, saveProjet, setSaveProjet }) => {



  const [activeProjet, setActiveProjet] = useState()
  const { user } = useGlobalContext();
  const [compagnies, setCompagnies] = useState()
  const [projectType, setProjectType] = useState()

  const [numberOfMonths, setNumberOfMonths] = useState(2)


  const params = useParams();
  const { id } = params;

  const [isLoading, setIsLoading] = useState(true);

  const { notify } = useNotify();

  const navigate = useNavigate();


  
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), 1),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setActiveProjet({
      ...activeProjet, // Note the lowercase 'n' in 'activeProjet'
      Start: state[0].startDate,
      End: state[0].endDate,
    });
  }, [state]);

  

  const fetchCompanies = useMemo(() => {
    return async () => {
      const response = await fetch(
        `${config.BASE_URL}/api/Company`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.ok) {
        setCompagnies(json);
      }
    };
  })

  const fetchProjectType = async () => {
    const response = await fetch(`${config.BASE_URL}/api/projectType`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();


    if (response.ok) {
      setProjectType(json)
      fetchCompanies()
      setIsLoading(false)
      
    }

  };

  const getProjetDetails = async () => {
    try {

      const response = await fetch(`${config.BASE_URL}/api/projects/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch projets");
      }
      const json = await response.json();

     // Convert date strings to Date objects
    if (json.Start && json.End) {
      json.Start = new Date(json.Start);
      json.End = new Date(json.End);
    }
    setState([
      {
        startDate: json.Start,
        endDate: json.End,
        key: "selection",
      },
    ])
    setActiveProjet(json);
    fetchProjectType()
    console.log("JSON", json)

    } catch (error) {
      notify("error", error.message); // Set error message in state variable
    }

    setIsLoading(false)
  }

  useEffect(() => {

    setTitrePage("Modifier projet")
    if (!activeProjet) {
      console.log("Getting Project Details...")
      getProjetDetails()
    }
  }, [])


  useEffect(() => {
    // Update the number of months when the window is resized
    function handleResize() {
      setNumberOfMonths(calculateNumberOfMonths());
    }

    // Attach the event listener
    window.addEventListener('resize', handleResize);
    handleResize()
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to calculate the number of months based on screen width
  function calculateNumberOfMonths() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 3; // Set the number of months for large screens
    } else if (screenWidth >= 768) {
      return 2; // Set the number of months for medium screens
    } else {
      return 1; // Set the number of months for small screens
    }
  }

  useEffect(() => {
    console.log("SAve projet", saveProjet)
    if (saveProjet) {
      console.log("Calling saving active projet")
      saveactiveProjet()
    }
  }, [saveProjet])


  const saveactiveProjet = async () => {
    console.log("SAving active projet")
    console.log("Active Projet", activeProjet)
    if (activeProjet.Nom === "") {
      notify(
        "error",
        "Veuillez inscrire un nom de projet svp!"
      );
    } else if (activeProjet.projectType.Name === "") {
      notify(
        "error",
        "Veuillez sélectionner un type de projet svp!"
      );

    }
    else if (activeProjet.Compagnie.nom === "") {
      notify(
        "error",
        "Veuillez sélectionner une compagnie pour ce projet svp!"
      );
    } else if (activeProjet.Equipe.length === 0) {
      notify(
        "error",
        "Veuillez ajouter un client à l'équipe de ce projet svp!"
      );

    } else {

      const newEquipe = activeProjet.Equipe.map((teamMate) => ({
        client: teamMate.client._id,
        fullName: teamMate.fullName,
        poste: teamMate.poste._id || teamMate.poste.id,
      }))

      
      
      const newProjet = {
        Nom: activeProjet.Nom,
        projectType: activeProjet.projectType._id,
        Compagnie: activeProjet.Compagnie._id,
        Start: activeProjet.Start,
        End: activeProjet.End,
        Equipe: newEquipe, // Use the array of client IDs
      };
      console.log("New Projet ",newProjet)
      
      try {
        const response = await fetch(
          `${config.BASE_URL}/api/projects/${activeProjet._id}`,
          {
            method: "PATCH",
            body: JSON.stringify(newProjet), // Use the updated project object
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (!response.ok) {
          const json = await response.json();
          throw new Error(json.error);
        }
      } catch (error) {
        notify("error", error.message);
      }
      

      navigate(-1);



    }




    setSaveProjet(false)


  }


  useEffect(() => {
    console.log("active again in useEffect", activeProjet)
  }, [activeProjet])

  return (
    <div className="height120">
      <div className="container-fluid">
        <div className="container projet">




          {!isLoading && activeProjet &&
            <div className="">
              <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-12 col-md-3"></div>
                <div className="col-12 col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="nomProjetID"
                      placeholder="Nom du projet"
                      onChange={(e) => {
                        setActiveProjet({
                          ...activeProjet,
                          Nom: e.target.value
                        })

                      }}
                      value={activeProjet.Nom}
                    />
                    <label htmlFor="nomProjetID">Nom du projet*</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3"></div>
                <div className="col-12 col-md-6">
                  <div className="form-floating mb-3 d-flex">
                    <select
                      className="form-control downIcon"
                      id="projetType"
                      placeholder="Now du projet"
                      onChange={(e) => {
                        setActiveProjet({
                          ...activeProjet,
                          projectType: {
                            Name: e.target.value,
                            _id: e.target.options[e.target.selectedIndex].getAttribute(
                              "id"
                            ),
                          }
                        })

                      }}
                      value={activeProjet.projectType.Name}
                    >
                      <option value=""></option>
                      {projectType &&
                        projectType.map((project) => {
                          return (
                            <option
                              key={project._id}
                              value={project.Name}
                              id={project._id}
                            >
                              {project.Name}
                            </option>
                          );

                        })}
                    </select>
                    <label htmlFor="projetType">Type de projet*</label>
                    <div style={{ marginLeft: "10px" }}>
                      <AddType notify={notify} projectType={projectType} setProjectType={setProjectType} />
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3"></div>
                <div className="col-12 col-md-6">
                  <div className="form-floating  mb-3 d-flex">
                    <select
                      className="form-control downIcon"
                      id="projetType"
                      placeholder="Nom du projet"
                      onChange={(e) => {
                        setActiveProjet({
                          ...activeProjet,
                          Compagnie: {
                            nom: e.target.value,
                            _id: e.target.options[e.target.selectedIndex].getAttribute(
                              "id"
                            ),
                          }
                        })
                      }}
                      value={activeProjet.Compagnie.nom}
                    >
                      <option value=""></option>
                      {compagnies &&
                        compagnies.map((company) => {
                          const me = false;
                          if (activeProjet && activeProjet.Compagnie._id == company._id) {
                            return (
                              <option
                                key={company._id}
                                value={company.companyName}
                                id={company._id}
                                selected="selected"
                              >
                                {company.companyName}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                key={company._id}
                                value={company.companyName}
                                id={company._id}
                              >
                                {company.companyName}
                              </option>
                            );
                          }
                        })}
                    </select>
                    <label htmlFor="projetType">Compagnie de production*</label>
                    <div style={{ marginLeft: "10px" }}>
                      <Link to="/Compagnies/Ajouter">
                        <div className="btnPlus" title="Ajouter une compagnie" style={{ marginTop: "15px" }}>

                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
              <div className="subTitleBar">
                <div className="texte">
                  Durée du projet</div>
              </div>
              <div className="text-center mt-4">
                {activeProjet &&
                  <DateRange
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={numberOfMonths}
                    ranges={state}
                    rangeColors={["#0979be"]}
                    direction="horizontal"
                    locale={locales["fr"]}
                    minDate={new Date()}
                  />
                }
              </div>
              <div className="subTitleBar mt-4">
                <div className="texte">
                  Équipe</div>
                <div className="btnPlus" title="Ajouter un client à l'équipe." onClick={() => { setStateClientList({ isPaneOpen: true }) }}>

                </div>

              </div>

              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mb-4 mt-3">
                {activeProjet.Equipe.length == 0 &&
                  <div className="w-100 text-center ">Ajouter un client à l'équipe</div>
                }

                {activeProjet.Equipe &&
                  activeProjet.Equipe.map((equipier) => {
                    return (
                      <ClientCardProjet key={equipier._id} client={equipier} activeProjet={activeProjet} setActiveProjet={setActiveProjet} />
                    )


                  })}
              </div>
            </div>
          }





        </div>

      </div>
    </div>
  );
};

export default ModifyProject;
