import React from "react";
import { Link } from "react-router-dom";
import defaultImage from "../../imgs/interface/imgIcon.png";

import "../../css/products.css";

import { useGlobalContext } from "../../hooks/useGlobalContext";

import { useEffect, useState } from "react";
import { useNotify } from "../../hooks/useNotify";

import config from "../../config";

const ProductCard = React.forwardRef(({ product, setProducts, setCommandeActive, commandeActive }, ref) => {

  const [enStock, setEnStock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { notify } = useNotify();

  const [image, setImage] = useState();

  useEffect(() => {
    getImage();
    
    console.log("Product :", product)

  }, [product]);

  const getImage = async () => {
    setIsLoading(true);
    if (product.Image) {
      setImage(product.Image);
    } else {
      const response = await fetch(
        `${config.BASE_URL}/api/images/${product._id}`
      );
      try {
        const json = await response.json();
        if (response.ok) {
          const img = new Image();
          img.onload = async () => {
            const imageUrl = await getSignedURL(json.awsKey);

            setImage(imageUrl);
            setIsLoading(false);
          };
          img.onerror = () => {
            console.log("Error loading image. Using default image.");
            setImage(defaultImage);
          };
          img.src = await getSignedURL(json.awsKey);
        } else {
          setImage(defaultImage);
        }
      } catch (error) {
        setImage(defaultImage);
      }
    }
  };

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      // Handle errors here, e.g., return a default image URL.
      return defaultImage;
    }
  };
  const handleClick = () => {
    if (!commandeActive) {
      return;
    }
  
    // Copier la commande active pour effectuer des modifications.
    const updatedCommandeActive = { ...commandeActive };
  
    if (!updatedCommandeActive.Produits) {
      updatedCommandeActive.Produits = [];
    }
  
    // Vérifier si le produit existe déjà dans la commande active.
    const existingProduct = updatedCommandeActive.Produits.find(
      (item) => item.produit._id === product._id
    );
  
    if (existingProduct) {
      // Le produit existe, incrémente la quantité de 1 dans la commande active.
      existingProduct.quant += 1;
  
      // Vérifier si la quantité du produit dans la liste des produits atteint zéro.
      if (product.Quantite === 1) {
        // Retirer complètement le produit de la liste des produits.
        setProducts((prevProducts) =>
          prevProducts.filter((item) => item._id !== product._id)
        );
      } else {
        // Décrémenter la quantité du produit dans la liste des produits de 1.
        setProducts((prevProducts) =>
          prevProducts.map((item) => {
            if (item._id === product._id) {
              item.Quantite -= 1;
            }
            return item;
          })
        );
      }
    } else {
      // Le produit n'existe pas dans la commande active.
      if (product.Quantite === 1) {
        // Si le produit a une quantité de 1, retirez-le de la liste des produits.
        setProducts((prevProducts) =>
          prevProducts.filter((item) => item._id !== product._id)
        );
      } else {
        // Sinon, ajoutez-le à la commande active avec quantité 1.
        updatedCommandeActive.Produits.push({
          produit: product,
          quant: 1,
        });
        
        // Décrémenter la quantité du produit dans la liste des produits de 1.
        setProducts((prevProducts) =>
          prevProducts.map((item) => {
            if (item._id === product._id) {
              item.Quantite -= 1;
            }
            return item;
          })
        );
      }
    }
  
    // Mettre à jour la commande active dans le composant parent.
    setCommandeActive(updatedCommandeActive);
  };
  
  
  
  useEffect(() => {
    var stockLigne;
    if (product.Quantite > 1) {
      stockLigne = product.Quantite + " de disponibles";
      setEnStock(stockLigne);
    } else {
      stockLigne = product.Quantite + " de disponible";
      setEnStock(stockLigne);
    }
  }, [product.Quantite]);

  const productBody = (
    <>
      
        <div className="card card-item-small"
        
        onClick={()=>{
          handleClick()
        }}
        >
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            <div className="col-4 p-1">
              <div className="card-item-img-small" style={{ border: "none" }}>
                {image &&
                  <img src={image} alt="" style={{ border: "none" }} />
                }
              </div>
            </div>
            <div className="col p-1">
              <div style={{ fontSize: "14px", fontWeight: "bold", whiteSpace: "nowrap" }}>{product.Number}</div>
              <div style={{ width:"135px", fontSize: "14px", overflow: "hidden", flexWrap: "wrap", whiteSpace: "nowrap" }}>{product.Name}</div>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>{enStock}</div>
            </div>

          </div>


        </div>
      


    </>
  );

  const content = ref ? (
    <div className="col" ref={ref}>
      {productBody}
    </div>
  ) : (
    <div className="col">{productBody}</div>
  );

  return content;
});
export default ProductCard;

