import { useEffect } from "react";
import { useState } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";

import projetLogo from "../../imgs/launchpad/projet.png"

import "../../css/projet.css"
import { Link } from "react-router-dom";
const ProjectCard = ({ project }) => {


  return (
    <div className="col">
      <Link to={`/Projets/Details/${project._id}`}>
        <div className="card projectCard">
          <div className="card-body">
            <div className="row">
              <div className="col-4" style={{ display: "flex", justifyContent: "center", justifyItems: "center" }}>
                <img src={projetLogo} alt="" className="projetLogo" width="100%" />
                <div>

                </div>
              </div>
              <div className="col">
                <div>
                  <h5> {project.Nom}</h5>
                  <div >{project.projectType.Name}</div>
                  <div >{project.Compagnie.companyName}</div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </Link>
    </div>

  );

};

export default ProjectCard;
