
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Container, Navbar, NavDropdown, Form } from 'react-bootstrap';
import '../../css/header.css'
import '../../css/headerCategory.css'


import MiniLaunchPad2 from './miniLaunchPad2';
import MiniLaunchPad from './miniLaunchPad';
import { useGlobalContext } from '../../hooks/useGlobalContext';

import config from "../../config";



const Header = ({ searching, setSearching, setState, setOpenPanel, titrePage, activeProduct, selectedCategory, setSelectedCategory, isSearching, setIsSearching }) => {

  const [showMiniLaunchPad, setShowMiniLaunchPad] = useState(false);
  const [showMiniLaunchPad2, setShowMiniLaunchPad2] = useState(false);
  
  const { categories, categoriesDispatch } = useGlobalContext()

  const navigate = useNavigate();


  const location = useLocation();

  const handleClick = () => {

    if (showMiniLaunchPad2) {
      setShowMiniLaunchPad2(false);
    } else {
      setShowMiniLaunchPad2(true);
      setShowMiniLaunchPad(false)
    }

  }

  return (
    <>
      <header>
        <div className="menuLeft">
          <div className="logo">
            
          </div>

        </div>
        <div className="page-title">
          {titrePage}
        </div>
        <div className="menuRight">
          <div className="user-Btn"
            onClick={handleClick}
          >
          </div>
          <Link to="/">
          <div className="btn-launcher" title="Launchpad" />
          </Link>
          

        </div>
            
      </header>

      {location.pathname == "/Produits" && ( // Conditionally render the subheader
        <Navbar expand="lg" className="subHeader">
        <Container fluid>
        <Navbar.Toggle className="custom-toggle" aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <div className="subNav">
              <div className="panel-btn" 
              onClick={()=>{
                console.log("Click")
                setState({ isPaneOpenLeft: true })
                setOpenPanel(true)
              }}
              />
    
              <div className={`catBtn ${searching.categorie === "Tous" ? "activeCat" : ""}`}
                style={{ lineHeight: "25px" }}
                onClick={(e) => {
                  if (!isSearching) {
                    setSearching({
                      ...searching,
                      page:1,
                      categorie:"Tous",
                      decor:"Tous"
                    })
                    if (location.pathname !== '/Produits') {
                      navigate('/Produits');
                    }
                  }
                }}
              >
                Tous
              </div>
              {categories &&
                categories.map((category) => {
                  if (!category.parentId) {
                    return (
                      <div className={`catBtn ${searching.categorie.Name === category.Name ? "activeCat" : ""}`}
                        key={category._id + 1}
                        style={{ lineHeight: "25px" }}
                        onClick={(e) => {
                          if (!isSearching) {
                            setSearching({
                              ...searching,
                              page:1,
                              categorie:category,
                              decor:"Tous"
                            })
                            if (location.pathname !== '/Produits') {
                              navigate('/Produits');
                            }
                          }
                        }}
                      >
                        {category.Name}
                      </div>
                    );
                  }
                })}
            </div>
          </Navbar.Collapse>
          



        </Container>
      </Navbar>
      )}


        

      

      {showMiniLaunchPad && (
        <MiniLaunchPad setShowMiniLaunchPad={setShowMiniLaunchPad} />
      )}
      {showMiniLaunchPad2 && (
        <MiniLaunchPad2 setShowMiniLaunchPad2={setShowMiniLaunchPad2} />
      )}
    </>
  )
}

export default Header