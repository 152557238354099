
import { useEffect, useState } from 'react';
import '../../css/sidebar1.css'
import config from '../../config'
import { useGlobalContext } from '../../hooks/useGlobalContext';

const SidebarFilter = ({ filtres, setFiltres, searching, setSearching }) => {

    const { tags, tagsDispatch } = useGlobalContext()

    const [collapsed, setCollapsed] = useState({
        "couleur-collapse": false,
        "materiaux-collapse": false,
        "caracteristique-collapse": false,
    });

    const toggleCollapse = (categoryName) => {
        setCollapsed((prevState) => ({
            ...prevState,
            [categoryName]: !prevState[categoryName],
        }));
    };
   


    const callSetSearching = () =>{

        setSearching({
            ...searching,
            page:1
        });


    }
    const handleSelect = (event) => {
        setSearching({
            ...searching,
            page:1
        });
        const newTag = {
          name: event.target.dataset.nom,
          _id: event.target.dataset.id,
          tagType: event.target.dataset.tagtype,
        };
      
             
        setFiltres((prevFiltres) => {
          const newFilters = { ...prevFiltres };
      
          // Check if newTag.name is "All"
          if (newTag.name === "All") {
            newFilters[newTag.tagType] = prevFiltres[newTag.tagType].includes(newTag._id) ? [] : ["All"];
          } else {
            // Toggle the selection of the individual tag
            newFilters[newTag.tagType] = prevFiltres[newTag.tagType].includes(newTag._id)
              ? prevFiltres[newTag.tagType].filter((id) => id !== newTag._id)
              : [...prevFiltres[newTag.tagType], newTag._id];
            
            // Check if all tags of the selected tagType are selected
            const allTagsSelected = newFilters[newTag.tagType].length === 0;
      
            // If allTagsSelected is true, select "All"
            if (allTagsSelected) {
              newFilters[newTag.tagType] = ["All"];
            } else {
              // Remove "All" if other tags are selected
              newFilters[newTag.tagType] = newFilters[newTag.tagType].filter((id) => id !== "All");
            }
          }
      
          return newFilters;
        });
      };
      

    /*
    const handleSelect = (event) => {
        const newTag = {
          name: event.target.dataset.nom,
          _id: event.target.dataset.id,
          tagType: event.target.dataset.tagtype,
        };
      
        if (newTag.name === "All") {
          setFiltres((prevFiltres) => {
            const newFilters = {
              ...prevFiltres,
              [newTag.tagType]: prevFiltres[newTag.tagType].includes(newTag._id) ? [] : ["All"],
            };
      
            return newFilters;
          });
          return;
        }
      
        setFiltres((prevFiltres) => {
          const newFilters = { ...prevFiltres };
      
          newFilters[newTag.tagType] = prevFiltres[newTag.tagType].includes(newTag._id)
            ? prevFiltres[newTag.tagType].filter((id) => id !== newTag._id)
            : [...prevFiltres[newTag.tagType], newTag._id];
      
          // Check if all other selections are removed and insert "All" if necessary
          const otherTagsSelected = Object.keys(newFilters).filter((key) => key !== newTag.tagType && newFilters[key].length > 0);
          if (newFilters[newTag.tagType].length === 0 && otherTagsSelected.length === 0) {
            newFilters[newTag.tagType] = ["All"];
          } else {
            newFilters[newTag.tagType] = newFilters[newTag.tagType].filter((id) => id !== "All");
          }
      
          return newFilters;
        });
      };
    
      */

    useEffect(() => {
        const fetchTags = async () => {
            const response = await fetch(`${config.BASE_URL}/api/tags`);
            const json = await response.json();
            tagsDispatch({ type: "SET_TAGS", payload: json });
        };

        if (!tags) {
            fetchTags();
        }
    }, []);

    const handleSortByChange = (event) => {
        setSearching({
            ...searching,
            page:1,
            sortBy: event.target.value,

        });
    };

    const handleLimitChange = (event) => {
        setSearching({
            ...searching,
            page:1,
            limit: event.target.value,
        });
    };


    const handleSortOrderChange = (event) => {
        setSearching({
            ...searching,
            page:1,
            orderBy: event.target.value,
        });
    };



    return (
        <div className="sidebar">
            <div className="sidebar-section">
                <div className="section-title-center">
                    Paramètres
                </div>
                <div className="row filter-row">
                    <div className="col-5 text-end filter-text">
                        Afficher
                    </div>
                    <div className="col" style={{ paddingLeft: '0px' }}>
                        <select value={searching.limit} onChange={handleLimitChange}>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
                <div className="row filter-row">
                    <div className="col-5 text-end filter-text">
                        Trier par
                    </div>
                    <div className="col" style={{ paddingLeft: '0px' }}>
                        <select
                            value={searching.sortBy}
                            onChange={handleSortByChange}
                            style={{ marginRight: "10px" }}
                        >
                            <option value="Number">Numéro</option>
                            <option value="Name">Nom</option>
                            <option value="Year">Année</option>
                            <option value="Brand">Marque</option>
                            <option value="Model">Modèle</option>
                            <option value="Valeur">Valeur</option>
                            <option value="Quantite">Quantité</option>
                            <option value="createdAt">Date de création</option>
                        </select>
                    </div>
                </div>
                <div className="row filter-row">
                    <div className="col-5 text-end filter-text">
                        En ordre
                    </div>
                    <div className="col" style={{ paddingLeft: '0px' }}>
                        <select value={searching.orderBy} onChange={handleSortOrderChange}>
                            <option value={1}>Croissant</option>
                            <option value={-1}>Décroissant</option>
                        </select>
                    </div>
                </div>
            </div>
            {tags && (
                <div className="sidebar-section">
                    <div className="section-title">Couleurs</div>
                    <div>
                        <label htmlFor="all-couleurs" className="section-link w-100">
                            <input
                                id="all-couleurs"
                                type="checkbox"
                                style={{ marginRight: '8px' }}
                                checked={filtres.Couleur.includes("All")}
                                onChange={handleSelect}
                                data-id="all-couleurs"
                                data-nom="All"
                                data-tagtype="Couleur"
                                disabled={filtres.Couleur.includes("All")}
                            />
                            Tous
                        </label>
                        {tags
                            .filter((tag) => tag.tagType === "Couleur")
                            .slice(0, 4)
                            .map((tag) => (
                                <label htmlFor={tag._id} className="section-link w-100" key={tag._id}>
                                    <input
                                        id={tag._id}
                                        type="checkbox"
                                        style={{ marginRight: '8px' }}
                                        checked={filtres.Couleur.includes(tag._id)}
                                        onChange={handleSelect}
                                        data-id={tag._id}
                                        data-nom={tag.Name}
                                        data-tagtype={tag.tagType}
                                    />
                                    {tag.Name}
                                </label>
                            ))}
                    </div>
                    {tags.filter((tag) => tag.tagType === "Couleur").length > 4 && (
                        <>
                            <div className={`collapse ${collapsed["couleur-collapse"] ? "show" : ""}`} id="couleur-collapse">
                                {tags
                                    .filter((tag) => tag.tagType === "Couleur")
                                    .slice(4)
                                    .map((tag) => (
                                        <label htmlFor={tag._id} className="section-link w-100" key={tag._id}>
                                            <input
                                                id={tag._id}
                                                type="checkbox"
                                                style={{ marginRight: '8px' }}
                                                checked={filtres.Couleur.includes(tag._id)}
                                                onChange={handleSelect}
                                                data-id={tag._id}
                                                data-nom={tag.Name}
                                                data-tagtype={tag.tagType}
                                            />
                                            {tag.Name}
                                        </label>
                                    ))}
                            </div>
                            <div
                                className={`section-${collapsed["couleur-collapse"] ? "moins" : "plus"}`}
                                onClick={() => toggleCollapse("couleur-collapse")}
                                aria-expanded={collapsed["couleur-collapse"]}
                                aria-controls="couleur-collapse"
                            >
                                {collapsed["couleur-collapse"] ? "Voir moins" : "Voir plus"}
                            </div>
                        </>
                    )}
                </div>
            )}

{tags && (
                <div className="sidebar-section">
                    <div className="section-title">Matériaux</div>
                    <div>
                        <label htmlFor="all-materiaux" className="section-link w-100">
                            <input
                                id="all-materiaux"
                                type="checkbox"
                                style={{ marginRight: '8px' }}
                                checked={filtres.Materiaux.includes("All")}
                                onChange={handleSelect}
                                data-id="all-materiaux"
                                data-nom="All"
                                data-tagtype="Materiaux"
                                disabled={filtres.Materiaux.includes("All")}
                            />
                            Tous
                        </label>
                        {tags
                            .filter((tag) => tag.tagType === "Materiaux")
                            .slice(0, 4)
                            .map((tag) => (
                                <label htmlFor={tag._id} className="section-link w-100" key={tag._id}>
                                    <input
                                        id={tag._id}
                                        type="checkbox"
                                        style={{ marginRight: '8px' }}
                                        checked={filtres.Materiaux.includes(tag._id)}
                                        onChange={handleSelect}
                                        data-id={tag._id}
                                        data-nom={tag.Name}
                                        data-tagtype={tag.tagType}
                                    />
                                    {tag.Name}
                                </label>
                            ))}
                    </div>
                    {tags.filter((tag) => tag.tagType === "Materiaux").length > 4 && (
                        <>
                            <div className={`collapse ${collapsed["materiaux-collapse"] ? "show" : ""}`} id="materiaux-collapse">
                                {tags
                                    .filter((tag) => tag.tagType === "Materiaux")
                                    .slice(4)
                                    .map((tag) => (
                                        <label htmlFor={tag._id} className="section-link w-100" key={tag._id}>
                                            <input
                                                id={tag._id}
                                                type="checkbox"
                                                style={{ marginRight: '8px' }}
                                                checked={filtres.Materiaux.includes(tag._id)}
                                                onChange={handleSelect}
                                                data-id={tag._id}
                                                data-nom={tag.Name}
                                                data-tagtype={tag.tagType}
                                            />
                                            {tag.Name}
                                        </label>
                                    ))}
                            </div>
                            <div
                                className={`section-${collapsed["materiaux-collapse"] ? "moins" : "plus"}`}
                                onClick={() => toggleCollapse("materiaux-collapse")}
                                aria-expanded={collapsed["materiaux-collapse"]}
                                aria-controls="materiaux-collapse"
                            >
                                {collapsed["materiaux-collapse"] ? "Voir moins" : "Voir plus"}
                            </div>
                        </>
                    )}
                </div>
            )}
            
            {tags && (
  <div className="sidebar-section">
    <div className="section-title">Caractéristiques</div>
    <label htmlFor="all-caracteristiques" className="section-link w-100">
      <input
        id="all-caracteristiques"
        type="checkbox"
        style={{ marginRight: '8px' }}
        checked={filtres.Caracteristique.includes("All")}
        onChange={handleSelect}
        data-id="all-caracteristiques"
        data-nom="All"
        data-tagtype="Caracteristique"
        disabled={filtres.Caracteristique.includes("All")}
      />
      Tous
    </label>
    {tags
      .filter((tag) => tag.tagType === "Caracteristique")
      .slice(0, 4)
      .map((tag) => (
        <label htmlFor={tag._id} className="section-link w-100" key={tag._id}>
          <input
            id={tag._id}
            type="checkbox"
            style={{ marginRight: '8px' }}
            checked={filtres.Caracteristique.includes(tag._id)}
            onChange={handleSelect}
            data-id={tag._id}
            data-nom={tag.Name}
            data-tagtype={tag.tagType}
          />
          {tag.Name}
        </label>
      ))}
    {tags.filter((tag) => tag.tagType === "Caracteristique").length > 4 && (
      <>
        
        <div className={`collapse ${collapsed["caracteristique-collapse"] ? "show" : ""}`} id="caracteristique-collapse">
          {tags
            .filter((tag) => tag.tagType === "Caracteristique")
            .slice(4)
            .map((tag) => (
              <label htmlFor={tag._id} className="section-link w-100" key={tag._id}>
                <input
                  id={tag._id}
                  type="checkbox"
                  style={{ marginRight: '8px' }}
                  checked={filtres.Caracteristique.includes(tag._id)}
                  onChange={handleSelect}
                  data-id={tag._id}
                  data-nom={tag.Name}
                  data-tagtype={tag.tagType}
                />
                {tag.Name}
              </label>
            ))}
        </div>
        <div
          className={`section-${collapsed["caracteristique-collapse"] ? "moins" : "plus"}`}
          onClick={() => toggleCollapse("caracteristique-collapse")}
          aria-expanded={collapsed["caracteristique-collapse"]}
          aria-controls="caracteristique-collapse"
        >
          {collapsed["caracteristique-collapse"] ? "Voir moins" : "Voir plus"}
        </div>
      </>
    )}
  </div>
)}

        </div>
    );




}

export default SidebarFilter