import { useRef, useState, useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import axios from "axios";
import { Card } from "react-bootstrap";

import CategoriesCheckboxes from "../../components/products/categories/categories";
import TagsCheckboxes from "../../components/products/tags/tags";

import "react-datepicker/dist/react-datepicker.css";
//import '../../css/products.css'

//import '../../css/addProduct.css'
import "../../css/imgBox.css";
import "../../css/cssSwitch.css";
import "../../css/productDetails.css";
import config from '../../config';

import defaultImage from "../../imgs/interface/no-image-icon.png";

import { useNotify } from "../../hooks/useNotify";
import ImgBox from "../../components/products/imgBox/imgBox";
import { BallTriangle } from "react-loader-spinner";

const FormData = require("form-data");

const ModifyProduct = ({ titrePage, setTitrePage, saveProduct, setSaveProduct, activeProduct, setActiveProduct }) => {

  const params = useParams();
  const { id } = params;

  const [error, setError] = useState(null);
  //Declaration de mes States
  const [Number, setNumber] = useState("");
  const [Name, setName] = useState("");
  const [Year, setYear] = useState("");
  const [Brand, setBrand] = useState("");
  const [Model, setModel] = useState("");
  const [Largeur, setLargeur] = useState("");
  const [Profondeur, setProfondeur] = useState("");
  const [Hauteur, setHauteur] = useState("");
  const [Diametre, setDiametre] = useState("");
  const [Description, setDescription] = useState("");
  const [Quantite, setQuantite] = useState("");
  const [Prix, setPrix] = useState("");
  const [Valeur, setValeur] = useState("");
  const [Semaine1, setSemaine1] = useState("20");
  const [Semaine2, setSemaine2] = useState("5");
  const [SemainePlus, setSemainePlus] = useState("2,5");
  const [TotalSemaine1, setTotalSemaine1] = useState("");
  const [TotalSemaine2, setTotalSemaine2] = useState("");
  const [TotalSemainePlus, setTotalSemainePlus] = useState("");
  const [EnLigne, setEnLigne] = useState(false);
  const [Aquisition, setAquisition] = useState(new Date());

  const [imagesList, setImagesList] = useState([]);
  const [activeImage, setActiveImage] = useState();

  const fileInputRef = useRef();
  const closeCurrent = useRef();
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [startDate, setStartDate] = useState(new Date());
  //const [allCategories, setAllCategories] = useState(null);

  const [checkedCategories, setCheckedCategories] = useState([]);
  const [checkedTags, setCheckedTags] = useState([]);

  const [isLoading, setIsLoading] = useState(true)

  const {
    user,
    tags,
    tagsDispatch
  } = useGlobalContext();

  const fetchProduit = async () => {
    setIsLoading(true)
    const response = await fetch(`${config.BASE_URL}/api/produits/${id}`)
    const json = await response.json()

    if (response.ok) {
      setActiveProduct(json)
      loadData()
    } else {
      console.log("Nada")
    }
  }

  useEffect(() => {
    if (!user) {
      navigate("/Login")
    }
  }, [user])

  useEffect(() => {

    fetchProduit()
    window.scrollTo(0, 0)

    setTitrePage("Modifier produit")


  }, [id])

  const fetchTags = async () => {
    const response = await fetch(`${config.BASE_URL}/api/tags`);
    const json = await response.json();
    tagsDispatch({ type: "SET_TAGS", payload: json });
  };

  const { notify } = useNotify();

  const navigate = useNavigate();

  const loadData = () => {
    setNumber(activeProduct.Number)
    setName(activeProduct.Name)
    setYear(activeProduct.Year)
    setBrand(activeProduct.Brand)
    setModel(activeProduct.Model)
    setLargeur(activeProduct.Largeur || 0)
    setProfondeur(activeProduct.Profondeur || 0)
    setHauteur(activeProduct.Hauteur || 0)
    setDiametre(activeProduct.Diametre ? activeProduct.Diametre : 0)
    setDescription(activeProduct.Description)
    setQuantite(activeProduct.Quantite)
    setPrix(activeProduct.Prix)
    setValeur(activeProduct.Valeur)
    setSemaine1(activeProduct.Semaine1)
    setSemaine2(activeProduct.Semaine2)
    setSemainePlus(activeProduct.SemainePlus)
    setTotalSemaine1(activeProduct.TotalSemaine1)
    setTotalSemainePlus(activeProduct.TotalSemainePlus)
    setTotalSemaine2(activeProduct.TotalSemaine2)
    setEnLigne(activeProduct.EnLigne)
    setAquisition(activeProduct.Aquisition)
    setCheckedCategories(activeProduct.Categories)
    setCheckedTags(activeProduct.Tags)
    setIsLoading(false)
  }

  useEffect(() => {
    if (saveProduct) {
      saveProduct2()
    }
  }, [saveProduct])


  const saveProduct2 = async () => {
    console.log("SAving...")
    console.log(checkedCategories)
    console.log(activeProduct)

    const Categories = checkedCategories.map((category) => category._id);
    const Tags = checkedTags.map((tag) => tag._id);

    if (Number === "") {
      notify("error", "Vous devez inscrire un numéro");
    } else if (Name === "") {
      notify("error", "Vous devez inscrire un nom");
    } else if (Categories.length === 0) {
      notify("error", "Vous devez cocher une catégorie");
    }

    if (Number && Name && Categories.length != 0) {
      // Create new FormData object
      const form = new FormData();

      // Append form fields to FormData object
      form.append("Number", Number);
      form.append("Name", Name);
      form.append("Year", Year);
      form.append("Brand", Brand);
      form.append("Model", Model);
      form.append("Largeur", Largeur);
      form.append("Profondeur", Profondeur);
      form.append("Hauteur", Hauteur);
      form.append("Diametre", Diametre);
      form.append("Description", Description);
      form.append("Quantite", Quantite);
      form.append("Prix", Prix);
      form.append("Valeur", Valeur);
      form.append("Semaine1", Semaine1);
      form.append("Semaine2", Semaine2);
      form.append("SemainePlus", SemainePlus);
      form.append("Categories", Categories);
      form.append("Tags", Tags);
      form.append("EnLigne", EnLigne);
      form.append("Aquisition", Aquisition);

      const sortedImagesList = imagesList.sort((a, b) => a.sortOrder - b.sortOrder);


      // Append images to FormData object
      for (let i = 0; i < sortedImagesList.length; i++) {
        const image = sortedImagesList[i];
        // check if the image is in base64 format and convert it to a Blob object

        form.append("Images", image.file);

      }

      try {
        const response = await axios.patch(
          `${config.BASE_URL}/api/produits/${activeProduct._id}`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const json = response.data;
        setCheckedCategories([]);
        setCheckedTags([]);

        setNumber("");
        setName("");
        setYear("");
        setBrand("");
        setModel("");
        setError(null);
        notify("success", "Produit sauvegardé avec succès");
        navigate(-1);
      } catch (error) {
        notify("error", error.response.data.message);
      }
    }
    setSaveProduct(false);
  };


  const [imagePreloaded, setImagePreloaded] = useState(false);

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = activeImage; // activeImage est l'URL de l'image que vous souhaitez précharger

    preloadImage.onload = () => {
      // L'image est préchargée, mettez setIsLoading à false
      setIsLoading(false)
      setImagePreloaded(true);
    };

    // Important : assurez-vous de nettoyer l'événement onload si le composant est démonté
    return () => {
      preloadImage.onload = null;
    };
  }, [activeImage]);

  useEffect(() => {

    if (activeProduct._id) {
      getImages();
    }

    setTotalSemaine1(
      (parseFloat(activeProduct.Valeur) * parseFloat(activeProduct.Semaine1)) / 100
    );
    setTotalSemaine2((parseFloat(activeProduct.Valeur) * parseFloat(activeProduct.Semaine2)) / 100);
    setTotalSemainePlus(
      (parseFloat(activeProduct.Valeur) * parseFloat(activeProduct.SemainePlus)) / 100
    );


    loadData()

    if (!tags) {
      fetchTags();
    }

  }, [activeProduct._id]);


  const getImages = async () => {
    setIsLoading(true)
    const response = await fetch(
      `${config.BASE_URL}/api/images/all/${activeProduct._id}`
    );
    const json = await response.json();
    if (response.ok && json.length > 0) {
      const newImageList = [];
      for (let i = 0; i < json.length; i++) {
        const image = json[i];
        const response = await fetch(`${config.BASE_URL}/imgs/${image.awsKey}`);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const imageObject = { image: url, file: blob, order: image.sortOrder };
        if (i === 0) {
          setActiveImage(imageObject.image);
        }
        newImageList.push(imageObject);
      }
      setImagesList(newImageList);
    } else {
      setActiveImage(defaultImage);
    }
    setIsLoading(false)
  };

  return (
    <div className="height120">
      <div className="container-fluid">
        {isLoading && (
          <div
            style={{
              display: "flex",
              height: "400px",
              paddingTop: "100px",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <BallTriangle
              height={200}
              width={200}
              radius={5}
              color="#ff9900"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
        )}
        {!isLoading && imagePreloaded &&
          <>
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
              <div className="col">
                <ImgBox titrePage={titrePage} isLoading={isLoading} setIsLoading={setIsLoading} imagesList={imagesList} setImagesList={setImagesList} />

              </div>
              <div className="col">
                <div className="card shadow-sm cardProductInfo">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">Détails du produits</div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-3">
                        <input
                          className="input90 text-center"
                          type="text"
                          placeholder="NO"
                          value={Number}
                          onChange={(e) => setNumber(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          className="input90"
                          placeholder="Titre "
                          onChange={(e) => setName(e.target.value)}
                          value={Name}
                          required
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-12">
                        <input
                          type="text"
                          className="input90"
                          placeholder="Marque "
                          onChange={(e) => setBrand(e.target.value)}
                          value={Brand}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-3">
                        <input
                          type="text"
                          className="input90 text-center"
                          placeholder="Année "
                          onChange={(e) => setYear(e.target.value)}
                          value={Year}
                        />
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          className="input90"
                          placeholder="Modèle "
                          onChange={(e) => setModel(e.target.value)}
                          value={Model}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-3 ">
                        Largeur
                        <br />
                        <input
                          type="number"
                          className="input70 text-center"
                          onChange={(e) => setLargeur(e.target.value)}
                          value={Largeur}
                        />
                        cm
                      </div>
                      <div className="col-3 ">
                        Profondeur
                        <br />
                        <input
                          type="number"
                          className="input70 text-center"
                          onChange={(e) => setProfondeur(e.target.value)}
                          value={Profondeur}
                        />
                        cm
                      </div>
                      <div className="col-3 ">
                        Hauteur
                        <br />
                        <input
                          type="number"
                          className="input70 text-center"
                          onChange={(e) => setHauteur(e.target.value)}
                          value={Hauteur}
                        />
                        cm
                      </div>
                      <div className="col-3 ">
                        Diamètre
                        <br />
                        <input
                          type="number"
                          className="input70 text-center"
                          onChange={(e) => setDiametre(e.target.value)}
                          value={Diametre}
                        />
                        cm
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-12">
                        <textarea
                          className="descriptiontext"
                          placeholder="Description"
                          onChange={(e) => setDescription(e.target.value)}
                          value={Description}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-4 ">
                        Quantité
                        <br />
                        <input
                          type="number"
                          className="input50 text-center"
                          onChange={(e) => setQuantite(e.target.value)}
                          value={Quantite}
                        />
                      </div>
                      <div className="col-4 ">
                        Prix payé
                        <br />
                        <input
                          type="number"
                          className="input50 text-center"
                          onChange={(e) => setPrix(e.target.value)}
                          value={Prix}
                        />
                        $
                      </div>
                      <div className="col-4 ">
                        Valeur
                        <br />
                        <input
                          type="number"
                          className="input50 text-center"
                          value={Valeur}
                          onChange={(e) => {
                            setValeur(e.target.value);
                            setTotalSemaine1((e.target.value * Semaine1) / 100);
                            setTotalSemaine2(
                              (e.target.value * Semaine2) / 100
                            );
                            setTotalSemainePlus((e.target.value * SemainePlus) / 100);
                          }}
                        />
                        $
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-4 ">
                        1re Semaine
                        <br />
                        <input
                          type="number"
                          className="input50 text-center"
                          onChange={(e) => {
                            setSemaine1(e.target.value);
                            setTotalSemaine1((e.target.value * Valeur) / 100);
                          }}
                          value={Semaine1}
                        />
                        %
                      </div>
                      <div className="col-4 ">
                        2e Semaine<br />
                        <input
                          type="number"
                          className="input50 text-center"
                          onChange={(e) => {
                            setSemaine2(e.target.value);
                            setTotalSemaine2((e.target.value * Valeur) / 100);
                          }}
                          value={Semaine2}
                        />
                        %
                      </div>
                      <div className="col-4 ">
                        Semaine +
                        <br />
                        <input
                          type="number"
                          className="input50 text-center"
                          onChange={(e) => {
                            setSemainePlus(e.target.value);
                            setTotalSemainePlus((e.target.value * Valeur) / 100);
                          }}
                          value={SemainePlus}
                        />
                        %
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-4 ">
                        <input
                          type="number"
                          className="input50 text-center"
                          onChange={(e) => setTotalSemaine1(e.target.value)}
                          value={TotalSemaine1}
                        />
                        $
                      </div>
                      <div className="col-4 ">
                        <input
                          type="number"
                          className="input50 text-center"
                          onChange={(e) => setTotalSemaine2(e.target.value)}
                          value={TotalSemaine2}
                        />
                        $
                      </div>
                      <div className="col-4">
                        <input
                          type="number"
                          className="input50 text-center"
                          value={TotalSemainePlus}
                          onChange={(e) => {
                            setTotalSemainePlus(e.target.value);
                          }}
                        />
                        $
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div
                        className="col-4 text-sm-end"
                        style={{ lineHeight: "32px" }}
                      >
                        En ligne :
                      </div>
                      <div className="col-8">
                        <div className="toggle">
                          <input
                            type="checkbox"
                            name="statut"
                            id="statut"
                            onChange={(e) => {
                              setEnLigne(e.target.checked);
                              console.log(e.target.checked);
                            }}
                            checked={EnLigne}
                          />
                          <label htmlFor="statut">
                            <h2>OFF</h2>
                            <h1>ON</h1>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div
                        className="col-4 text-sm-end"
                        style={{ lineHeight: "32px" }}
                      >
                        Date d'aquisition :
                      </div>
                      <div className="col-8">
                        <DatePicker
                          className="input50 text-center"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setAquisition(date);
                            console.log(date);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CategoriesCheckboxes
              checkedCategories={checkedCategories}
              setCheckedCategories={setCheckedCategories}
            />

            <TagsCheckboxes
              checkedTags={checkedTags}
              setCheckedTags={setCheckedTags}
            />
          </>
        }
      </div>
    </div>
  );
};

export default ModifyProduct;


