

import '../../css/miniLaunchPad.css'
import { Link } from 'react-router-dom'

const MiniLaunchPad = ({ setShowMiniLaunchPad, setSelectedApp }) => {


    return (
        <div className="miniLaunchPad" onMouseLeave={() => {
            setShowMiniLaunchPad(false)
        }}>
            <div className="row row-cols-3 row-cols-sm-3 row-cols-md-6 row-cols-lg-6 row-cols-xl-6">
                <div className="col">
                    <Link to="/">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-dashboard">

                            </div>
                            <div className="Title">
                                Dashboard
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Produits">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-produits">

                            </div>
                            <div className="Title">
                                Produits
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Employes">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-employes">

                            </div>
                            <div className="Title">
                                Employés
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Horaires">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-horaires">

                            </div>
                            <div className="Title">
                                Horaires
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Projets">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-projets">

                            </div>
                            <div className="Title">
                                Projets
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Clients">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-clients">

                            </div>
                            <div className="Title">
                                Clients
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Compagnies">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-compagnies">

                            </div>
                            <div className="Title">
                                Compagnies
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Factures">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-factures">

                            </div>
                            <div className="Title">
                                Factures
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Commandes">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} style={{ marginRight: '5px' }}>
                            <div className="btn-commandes">

                            </div>
                            <div className="Title">
                                Commandes
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/Parametres">
                        <div className="miniBtn" onClick={() => {
                            setShowMiniLaunchPad(false)
                        }} >
                            <div className="btn-settings">

                            </div>
                            <div className="Title">
                                Paramètres
                            </div>
                        </div>
                    </Link>
                </div>
            </div>



        </div>
    )
}

export default MiniLaunchPad