import settings from '../../imgs/interface/settings.png'
import '../../css/miniLaunchPad.css'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../hooks/useGlobalContext'
const MiniLaunchPad2 = ({setShowMiniLaunchPad2}) =>{
    
const {userDispatch}= useGlobalContext()

    const navigate = useNavigate()
    return(
        <div className="miniLaunchPad2" onMouseLeave={()=>{
            setShowMiniLaunchPad2(false)
        }}>
            <Link to="/Parametres">
            <div className="miniBtn" style={{marginRight:'5px'}} onClick={()=>{setShowMiniLaunchPad2(false)}}>
                <img src={settings} alt="" />
                <div className="Title">
                Paramètres
                </div>
            </div>
            </Link>
            <div className="miniBtn" style={{marginRight:'5px'}}
            onClick={() => {
                localStorage.removeItem("user");
                userDispatch({ type: "LOGOUT" });
                navigate("/")
              }}
              >
                <div className="btn-signout">

                </div>
                <div className="Title">
                Déconnexion
                </div>
            </div>
        </div>
    )
}

export default MiniLaunchPad2