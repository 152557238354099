import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useEffect, useState } from "react";

import AddTag from "./addTags";
import DeleteTag from "./deleteTag";

import "../../../css/products.css";

const TagsCheckboxes = ({ checkedTags, setCheckedTags }) => {

  const { tags } = useGlobalContext();
  const [activeMateriaux, setActiveMateriaux] = useState({
    name: null,
    _id: null,
  });
  const [activeCouleur, setActiveCouleur] = useState({
    name: null,
    _id: null,
  });
  const [activeCaracteristique, setActiveCaracteristique] = useState({
    name: null,
    _id: null,
  });
  const [activeDecor, setActiveDecor] = useState({
    name: null,
    _id: null,
  });

  const handleDivClick = (event) => {
    const newTag = {
      name:
        event.target.getAttribute("nom") ||
        event.currentTarget.getAttribute("nom"),
      _id:
        event.target.getAttribute("id") ||
        event.currentTarget.getAttribute("id"),
      tagType:
        event.target.getAttribute("tagtype") ||
        event.currentTarget.getAttribute("tagtype"),
    };

    
    if (newTag.tagType === "Materiaux") {
      setActiveMateriaux(newTag);
    }
    if (newTag.tagType === "Couleur") {
      setActiveCouleur(newTag);
    }
    if (newTag.tagType === "Caracteristique") {
      setActiveCaracteristique(newTag);
    }
    if (newTag.tagType === "Décor") {
      setActiveDecor(newTag);
    }

  };

  const handleSelect = (event) => {
    const newTag = {
      name: event.target.dataset.nom,
      _id: event.target.dataset.id,
      tagType: event.target.dataset.tagtype,
    };
    // Add this code
    if (event.target.checked) {
      setCheckedTags([...checkedTags, newTag]);
    } else {
      setCheckedTags(checkedTags.filter((tag) => tag._id !== newTag._id));
    }
  };


  return (
    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 ">
      <div className="col">
        <div className="card shadow-sm cardCheckboxList">
          <div className="card-body">
            <h4>Décor</h4>
          </div>
          <div className="checkboxList">
            {tags &&
              tags.map((tag) => {
                if (tag.tagType == "Décor") {
                  return (
                    <div
                      className={
                        activeDecor._id === tag._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={tag._id}
                      id={tag._id}
                      nom={tag.Name}
                      tagtype={tag.tagType}
                      onClick={handleDivClick}
                    >
                      <label htmlFor={`label-${tag._id}`}>
                        <input
                          type="checkbox"
                          id={`label-${tag._id}`}
                          defaultChecked={false}
                          checked={
                            checkedTags?.find((el) => el._id === tag._id)
                              ? true
                              : false
                          }
                          
                          onChange={handleSelect}
                          data-id={tag._id}
                          data-nom={tag.Name}
                          data-tagtype={tag.tagType}
                        />
                        {tag.Name}
                      </label>
                    </div>
                  );
                }
              })}
          </div>
          <div className="menuAddanDelete">
            <AddTag
              title="Ajouter un décor"
              tagType="Décor"
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
            <DeleteTag
              title="Supprimer un Décor"
              id={activeDecor._id}
              nom={activeDecor.name}
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card shadow-sm cardCheckboxList">
          <div className="card-body">
            <h4>Matériaux</h4>
          </div>
          <div className="checkboxList">
            {tags &&
              tags.map((tag) => {
                if (tag.tagType == "Materiaux") {
                  return (
                    <div
                      className={
                        activeMateriaux._id === tag._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={tag._id}
                      id={tag._id}
                      nom={tag.Name}
                      tagtype={tag.tagType}
                      onClick={handleDivClick}
                    >
                      <label htmlFor={`label-${tag._id}`}>
                        <input
                          type="checkbox"
                          id={`label-${tag._id}`}
                          defaultChecked={false}
                          checked={
                            checkedTags?.find((el) => el._id === tag._id)
                              ? true
                              : false
                          }
                          
                          onChange={handleSelect}
                          data-id={tag._id}
                          data-nom={tag.Name}
                          data-tagtype={tag.tagType}
                        />
                        {tag.Name}
                      </label>
                    </div>
                  );
                }
              })}
          </div>
          <div className="menuAddanDelete">
            <AddTag
              title="Ajouter un matériaux"
              tagType="Materiaux"
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
            <DeleteTag
              title="Supprimer un matériaux"
              id={activeMateriaux._id}
              nom={activeMateriaux.name}
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card shadow-sm cardCheckboxList">
          <div className="card-body">
            <h4>Couleur</h4>
          </div>
          <div className="checkboxList">
            {tags &&
              tags.map((tag) => {
                if (tag.tagType == "Couleur") {
                  return (
                    <div
                      className={
                        activeCouleur._id === tag._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={tag._id}
                      id={tag._id}
                      nom={tag.Name}
                      tagtype={tag.tagType}
                      onClick={handleDivClick}
                    >
                      <label htmlFor={`label-${tag._id}`}>
                        <input
                          type="checkbox"
                          id={`label-${tag._id}`}
                          defaultChecked={false}
                          checked={
                            checkedTags?.find((el) => el._id === tag._id)
                              ? true
                              : false
                          }
                          
                          onChange={handleSelect}
                          data-id={tag._id}
                          data-nom={tag.Name}
                          data-tagtype={tag.tagType}
                        />
                        {tag.Name}
                      </label>
                    </div>
                  );
                }
              })}
          </div>
          <div className="menuAddanDelete">
            <AddTag
              title="Ajouter une couleur"
              tagType="Couleur"
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
            <DeleteTag
              title="Supprimer une couleur"
              id={activeCouleur._id}
              nom={activeCouleur.name}
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card shadow-sm cardCheckboxList">
          <div className="card-body">
            <h4>Caractéristique</h4>
          </div>
          <div className="checkboxList">
            {tags &&
              tags.map((tag) => {
                if (tag.tagType == "Caracteristique") {
                  return (
                    <div
                      className={
                        activeCaracteristique._id === tag._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={tag._id}
                      id={tag._id}
                      nom={tag.Name}
                      tagtype={tag.tagType}
                      onClick={handleDivClick}
                    >
                      <label htmlFor={`label-${tag._id}`}>
                        <input
                          type="checkbox"
                          id={`label-${tag._id}`}
                          checked={
                            checkedTags?.find((el) => el._id === tag._id)
                              ? true
                              : false
                          }
                          
                          onChange={handleSelect}
                          data-id={tag._id}
                          data-nom={tag.Name}
                          data-tagtype={tag.tagType}
                        />
                        {tag.Name}
                      </label>
                    </div>
                  );
                }
              })}
          </div>
          <div className="menuAddanDelete">
            <AddTag
              title="Ajouter une caracteristique"
              tagType="Caracteristique"
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
            <DeleteTag
              title="Supprimer une Caracteristique"
              id={activeCaracteristique._id}
              nom={activeCaracteristique.name}
              setCheckedTags={setCheckedTags}
              checkedTags={checkedTags}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagsCheckboxes;
