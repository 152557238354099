import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import dashboard from "../imgs/launchpad/dashboard.png";
import produits from "../imgs/launchpad/produits.png";
import employes from "../imgs/launchpad/employes.png";
import parametres from "../imgs/launchpad/settings.png";
import clients from "../imgs/launchpad/clients.png";
import projets from "../imgs/launchpad/projet.png";
import horaires from "../imgs/launchpad/calendar.png";
import compagnies from "../imgs/launchpad/compagnie.png";
import commandes from "../imgs/launchpad/commandes.png";
import factures from "../imgs/launchpad/factures.png";
import userIcon from "../imgs/interface/user.png"

import MiniLaunchPad from "../components/System/miniLaunchPad";

import { useGlobalContext } from "../hooks/useGlobalContext";

import "../css/home.css";

const Home = ({setTitrePage}) => {
  const { user, userDispatch } = useGlobalContext();

  const [showMiniLaunchPad, setShowMiniLaunchPad] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem("user"));
    setTitrePage("")
    if (userLocal) {
      userDispatch({ type: "LOGIN", payload: userLocal });
    } else {
      navigate("/connexion");
    }
  }, []);

  return (

    <div className="home">
      
      <div className="middle">
        <div className="userWelcome">
          <div className="row mb-5">
            <div className="col">
            <img src={user.avatar ? user.avatar : userIcon} alt="" style={{borderRadius:"10px"}} />
            </div>
            <div className="col text-start" style={{fontSize:"22px"}} >
            Bienvenue,<br /> {user && user.userFirstName}  
            </div>
          </div>
          
        </div>

        <div className="launchPad">
          <div className="row row-cols-3 row-cols-sm-4 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
            <div className="col d-flex justify-content-center">
              <Link to="/Dashboard">
                <div className="launchBtn"
                
                >
                  <div className="card btn_dashboard">
                    
                  </div>
                  <div className="card-title">Dashboard</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Produits">
                <div className="launchBtn"
                
                >
                  <div className="card">
                    <img src={produits} alt="" />
                  </div>

                  <div className="card-title">Produits</div>
                </div>
              </Link>
            </div>

            <div className="col d-flex justify-content-center">
              <Link to="/Employes">
                <div className="launchBtn">
                  <div className="card btn_employes">
                    
                  </div>
                  <div className="card-title">Employés</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Clients">
                <div className="launchBtn"
                >
                  <div className="card btn_clients">
                    
                  </div>
                  <div className="card-title">Clients</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Compagnies">
                <div className="launchBtn"
                >
                  <div className="card btn_compagnies">
                    
                  </div>
                  <div className="card-title">Compagnies</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Projets">
                <div className="launchBtn"
                >
                  <div className="card btn_projets">
                    
                  </div>
                  <div className="card-title">Projets</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Commandes">
                <div className="launchBtn"
                >
                  <div className="card btn_commandes">
                    
                  </div>
                  <div className="card-title">Commandes</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Factures">
                <div className="launchBtn"
                >
                  <div className="card btn_factures">
                    
                  </div>
                  <div className="card-title">Factures</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Horaires">
                <div className="launchBtn" >
                  <div className="card btn_horaires">
                    
                  </div>
                  <div className="card-title">Horaires</div>
                </div>
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <Link to="/Parametres">
                <div className="launchBtn" >
                  <div className="card btn_settings">
                    
                  </div>
                  <div className="card-title">Paramètres</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showMiniLaunchPad && (
        <MiniLaunchPad setShowMiniLaunchPad={setShowMiniLaunchPad} />
      )}
      
    </div>
    
  );
};

export default Home;
