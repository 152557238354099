import { useEffect } from "react"
import SearchBar from "../../components/Factures/searchBar"



const Factures = ({setTitrePage}) =>{

    useEffect(()=>{
        setTitrePage("Factures")
    },[])



    return (
        <div  className="container">
            <SearchBar />
        </div>
    )

}

export default Factures