import { useEffect, useState } from "react"
import ProductCard from "../products/productCardSmall"
import config from "../../config";
import defaultImage from "../../imgs/interface/no-image-icon.png"


const OrderRow = ({ product, index }) => {

  const [isLoading, setIsLoading] = useState(false);


  const [image, setImage] = useState();

  useEffect(() => {
    getImage();

    console.log("Product :", product)

  }, [product]);

  const getImage = async () => {
    setIsLoading(true);
    if (product.Image) {
      setImage(product.Image);
    } else {
      const response = await fetch(
        `${config.BASE_URL}/api/images/${product.produit._id}`
      );
      try {
        const json = await response.json();
        if (response.ok) {
          const img = new Image();
          img.onload = async () => {
            const imageUrl = await getSignedURL(json.awsKey);

            setImage(imageUrl);
            setIsLoading(false);
          };
          img.onerror = () => {
            console.log("Error loading image. Using default image.");
            setImage(defaultImage);
          };
          img.src = await getSignedURL(json.awsKey);
        } else {
          setImage(defaultImage);
        }
      } catch (error) {
        setImage(defaultImage);
      }
    }
  };

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      // Handle errors here, e.g., return a default image URL.
      return defaultImage;
    }
  };

  // Vérifiez si l'index est pair ou impair
  const isEven = index % 2 === 0;

  // Conditionnez la classe CSS en fonction de l'index
  const rowClassName = isEven ? 'bg-grey-light' : 'bg-grey';

  return (
    <div className={`order-row ${rowClassName}`}>
      <div className="number">
        {index + 1}.
      </div>
      <div className="img">
        <img src={image} alt="" />
      </div>
      <div className="product-info">
        <div className="product-number">
          {product.produit.Number}
        </div>
        <div  className="product-name">
          {product.produit.Name}
        </div>
      </div>

      <div className="btn-right">
        <div style={{marginTop:"7px", marginRight:"5px"}}>
          <input type="number" value={product.quant} />
        </div>
        <div className="btn-edit-produit" title="Modifier le produit."></div>
        <div className="btn-delete-produit" title="Supprimer le produit de la commande."></div>
      </div>

    </div>
  );
}

export default OrderRow;
