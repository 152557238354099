import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../css/mobile.css";
import DeleteProduit from "../products/deleteProduit";
import DeleteEmploye from "../Employes/deleteEmploye";
import FiltersList from "./filtresList";
import DeleteCompany from "../Compagnies/deleteCompany";
import DeleteClient from "../Clients/deleteClient";
import DeleteProjet from "../Projets/deleteProjet";

const MobileFooter = ({
  isLoading,
  activeProjet,
  setSaveProjet,
  activeClient,
  setSaveClient,
  activeCompany,
  commandeActive,
  setSaveCompany,
  setSaveEmploye,
  setStateFilter,
  setAddProduct,
  setAddCompany,
  setSaveProduct,
  titrePage,
  activeProduct,
  activeEmploye,
  filtres,
  setFiltres,
  setSaveCommande,
  setStateProductsList
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="menuMobile">
      {titrePage === "Nouveau produit" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}

      {titrePage === "Détails produit" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Détails projet" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Modifier produit" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Modifier compagnie" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Nouvel employé" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Détails employé" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Modifer employé" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Nouveau projet" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Nouvelle compagnie" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Modifier client" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Modifier projet" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Nouveau client" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Détails compagnie" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Détails client" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Détails commande" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}
      {titrePage === "Modifier commande" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}

      {titrePage === "Ajouter commande" && (
        <div
          className="btn-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          Retour
        </div>
      )}

      {titrePage === "Produits" && (
        <Link to="/Produits/Ajouter" className="noDecoration">
          <div className="btn-ajouter">Ajouter</div>
        </Link>
      )}
      {titrePage === "Clients" && (
        <Link to="/Clients/Ajouter" className="noDecoration">
          <div className="btn-ajouter">Ajouter</div>
        </Link>
      )}

      {titrePage === "Compagnies" && (
        <Link to="/Compagnies/Ajouter" className="noDecoration">
          <div className="btn-ajouter">Ajouter</div>
        </Link>
      )}
      {titrePage === "Projets" && (
        <Link to="/Projets/Ajouter" className="noDecoration">
          <div className="btn-ajouter">Ajouter</div>
        </Link>
      )}

      {titrePage === "Employés" && (
        <Link to="/Employes/Nouveau" className="noDecoration">
          <div className="btn-ajouter">Ajouter</div>
        </Link>
      )}

      {titrePage === "Commandes" && (
        <Link to="/Commandes/Ajouter" className="noDecoration">
          <div className="btn-ajouter">Ajouter</div>
        </Link>
      )}
      {titrePage === "Détails commande" && (

          <div title="Ajouter un produit à la commande" className="btn-ajouter" onClick={() => { setStateProductsList({ isPaneOpen: true }); }}>Ajouter</div>

      )}

      {titrePage === "Nouveau produit" && (
        <div
          className="btn-save"
          onClick={() => {
            setAddProduct(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Ajouter commande" && (
        <div
          className="btn-save"
          onClick={() => {
           setSaveCommande(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Modifier projet" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveProjet(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Nouveau projet" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveProjet(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Nouvelle compagnie" && (
        <div
          className="btn-save"
          onClick={() => {
            setAddCompany(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Nouveau client" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveClient(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Modifier client" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveClient(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Modifier produit" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveProduct(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Nouvel employé" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveEmploye(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Modifer client" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveClient(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Modifier compagnie" && (
        <div
          className="btn-save"
          onClick={() => {
            setSaveCompany(true);
          }}
        >
          Enregistrer
        </div>
      )}
      {titrePage === "Détails produit" && (
        <Link
          to={`/Produits/Modifier/${activeProduct._id}`}
          className="noDecoration"
        >
          <div className="btn-modify">Modifier</div>
        </Link>
      )}
      {titrePage === "Détails client" && (
        <Link
          to={`/Clients/Modifier/${activeClient._id}`}
          className="noDecoration"
        >
          <div className="btn-modify">Modifier</div>
        </Link>
      )}
      {titrePage === "Détails employé" && (
        <Link to={`/Employes/Modifier`} className="noDecoration">
          <div className="btn-modify">Modifier</div>
        </Link>
      )}
      {titrePage === "Détails compagnie" && (
        <Link
          to={`/Compagnies/Modifier/${activeCompany._id}`}
          className="noDecoration"
        >
          <div className="btn-modify">Modifier</div>
        </Link>
      )}
      {titrePage === "Détails commande" && (
        <Link
          to={`/Commandes/Modifier/${commandeActive?._id}`}
          className="noDecoration"
        >
          <div className="btn-modify">Modifier</div>
        </Link>
      )}
      {titrePage === "Détails projet" && (
        <Link
          to={`/Projets/Modifier/${activeProjet._id}`}
          className="noDecoration"
        >
          <div className="btn-modify">Modifier</div>
        </Link>
      )}
      {titrePage === "Détails produit" && (
        <DeleteProduit product={activeProduct} />
      )}

      {titrePage === "Détails employé" && (
        <DeleteEmploye employe={activeEmploye} />
      )}

      {titrePage === "Détails compagnie" && (
        <DeleteCompany company={activeCompany} />
      )}
      {titrePage === "Détails commande" && (
        <DeleteCompany company={activeCompany} />
      )}
      
      {titrePage === "Détails client" && <DeleteClient client={activeClient} />}
      {titrePage === "Détails projet" && <DeleteProjet projet={activeProjet} />}

      {titrePage === "Produits" && (
        <>
          <FiltersList filtres={filtres} setFiltres={setFiltres} />
          <div
            className="btn-filter"
            onClick={() => setStateFilter({ isPaneOpen: true })}
          >
            Filtres
          </div>
        </>
      )}
    </div>
  );
};

export default MobileFooter;
