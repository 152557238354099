
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../css/products.css'
import { useGlobalContext } from '../../hooks/useGlobalContext'

import config from "../../config";


import btnPlus from '../../imgs/interface/plus.png'

const AddType = ({notify, projectType, setProjectType}) => {

    const { user } = useGlobalContext()
    const [Name, setNewTypeName] = useState("")
    const [error, setError] = useState(null)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const addType = async () => {


        const payload = { Name: Name }

        /// Vérifier si il existe déjè

        const typeExiste = projectType.filter(type => type.Name.toLowerCase() === Name.toLowerCase())
        if(typeExiste.length != 0)
        {
            notify('warning','Ce type de projet existe déjà dans la liste.')
            setNewTypeName('')
        }else
        {


            const response = await fetch(`${config.BASE_URL}/api/projectType`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setProjectType([...projectType, json])
               
                setNewTypeName('')
    
                setError(null)
                console.log("Nouveau type the projet ajouté.")
                notify('success','Type de projet ajouté.')
                handleClose()
            }
        }
    
        


    }




    return (
        <>
            <div className="btnPlus" onClick={handleShow}  title="Ajouter un type de projet" style={{marginTop:"15px"}}>
                
            </div>


            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un type de projet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating mb-3">
                        <input type="text"
                            className="form-control"
                            id="nomPoste"
                            placeholder="Now du projet"
                            onChange={(e) => setNewTypeName(e.target.value)}
                            value={Name}
                        />
                        <label htmlFor="nomPoste">Nom du type de projet</label>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                    <Button variant="primary" onClick={addType}>Ajouter</Button>
                </Modal.Footer>
            </Modal>
           
        </>
    );

}


export default AddType