import { useState, useEffect } from "react";

import "../../css/signin.css";
import logo from "../../imgs/interface/logoSmall.png";
import logo2 from "../../imgs/interface/logoMedicSmall.png";

import { useLogin } from "../../hooks/useLogin";
import { useNotify } from "../../hooks/useNotify";


const ConnexionForm = () => {

  const { login, error, isLoading } = useLogin();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const { notify } = useNotify();


  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(userEmail, userPassword);
  };

  useEffect(() => {
    if (error) {
      notify("error", error);
    }
  }, [error]);

  return (
    
      <main className="form-signin">
        <form
          onSubmit={function (e) {
            handleSubmit(e);
          }}
        >
          <div className="logoBox">
            <img className="logoSignin" src={logo} alt="" />
          </div>
          <div>&</div>
          <div className="logoBox">
            <img className="logoSignin2" src={logo2} alt="" />
          </div>

          <h2 className="mb-3 fw-normal sousTitre">Administration</h2>

          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
              value={userEmail}
              placeholder="name@example.com"
              required
            />
            <label htmlFor="floatingInput">Courriel</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              onChange={(e) => {
                setUserPassword(e.target.value);
              }}
              value={userPassword}
              placeholder="Password"
              required
            />
            <label htmlFor="floatingPassword">Mot de passe</label>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-100 difLoggin"
            id="btnConnectToAccount"
          >
            Connexion
          </button>
        </form>
      </main>
    
  );
};

export default ConnexionForm;
