import { useEffect, useState } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import SearchBarMini from "./searchBarMini";
import AddPoste from "../Projets/addPoste";
import config from "../../config";
import { useNotify } from "../../hooks/useNotify";
import { BallTriangle } from "react-loader-spinner";
import ClientCardSidebar from "./clientCardSidebar";

const AddWorker = ({
  setNewProject,
  newProject,
  activeProjet,
  setActiveProjet,
  setStateClientList,
}) => {
  const { user } = useGlobalContext();

  const { notify } = useNotify();

  const [isLoading, setIsLoading] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [jobList, setJobList] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  const [selectedClient, setSelectedClient] = useState();

  const [newWorker, setNewWorker] = useState({
    client: "",
    fullName: "",
    poste: {
      Name: "",
      id: "",
    },
  });
  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    const element = document.getElementById("sideBarWorker");

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Filter the clientList based on the newProject.Equipe
    var filteredClients = [];

    if (activeProjet) {
      filteredClients = clientList.filter((client) => {
        return !activeProjet.Equipe.some(
          (worker) => worker.client._id === client._id
        );
      });
    } else {
      filteredClients = clientList.filter((client) => {
        return !newProject.Equipe.some(
          (worker) => worker.client._id === client._id
        );
      });
    }

    setFilteredList(filteredClients);
  }, [clientList]);

  const getPostes = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/postes/clients`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch clients");
      }
      const json = await response.json();
      setJobList(json);
    } catch (error) {
      notify("error", error.message);
    }
  };

  const getClientList = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/clients`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch clients");
      }
      const json = await response.json();
      setClientList(json);
    } catch (error) {
      notify("error", error.message);
    }

    setIsLoading(false);
  };

  const updateEquipe = async () => {

    console.log("ACTIVE PROJET :", activeProjet)

    const newEquipe = activeProjet.Equipe.map((teamMate) => ({
        client: teamMate.client._id,
        fullName: teamMate.fullName,
        poste: teamMate.poste._id || teamMate.poste.id,
      }));

      console.log("ÉQuipe", newEquipe)

      const Equipe = {
        Equipe:newEquipe
      }



  try {
    const response = await fetch(`${config.BASE_URL}/api/projects/${activeProjet._id}`, {
      method: "PATCH",
      body: JSON.stringify(Equipe), // Use the updated project object
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
  } catch (error) {
    notify("error", error.message);
  }

  };

  const ajouterEquipier = () => {
    if (!newWorker.client) {
      notify("error", "Veuillez sélectionner un client svp.");
    } else if (newWorker.poste.Name === "") {
      notify("error", "Veuillez sélectionner un poste svp.");
    } else {
      if (activeProjet) {
        setActiveProjet({
          ...activeProjet,
          Equipe: [...activeProjet.Equipe, newWorker],
        });
        console.log("Calling Saving the projet in Add Worker");
      } else {
        setNewProject({
          ...newProject,
          Equipe: [...newProject.Equipe, newWorker],
        });
      }

      setStateClientList({ isPaneOpen: false });
    }
  };

  useEffect(()=>{
    updateEquipe();
  },[activeProjet.Equipe])

  useEffect(() => {
    getPostes();
    getClientList();
  }, []);

  return (
    <div
      id="sideBarWorker"
      style={{ paddingBottom: "30px", paddingTop: "20px" }}
    >
      <div
        className="activeClient"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        {!newWorker.client && (
          <div
            className="card"
            style={{
              width: "100%",
              height: "70px",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <div className="card-body text-center">Sélectionner un client</div>
          </div>
        )}
        {newWorker.client && selectedClient && (
          <ClientCardSidebar client={selectedClient} noOnClick={true} />
        )}
      </div>
      <div
        className="row mt-2"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <div className="col">
          <div className="form-floating mb-3 d-flex">
            <select
              className="form-control downIcon"
              id="workerJob"
              placeholder="Poste du client"
              onChange={(e) => {
                setNewWorker({
                  ...newWorker,
                  poste: {
                    Name: e.target.value,
                    id: e.target.options[e.target.selectedIndex].getAttribute(
                      "id"
                    ),
                  },
                });
              }}
              value={newWorker.poste.Name}
            >
              <option value=""></option>
              {jobList &&
                jobList.map((job) => {
                  return (
                    <option key={job._id} value={job.Name} id={job._id}>
                      {job.Name}
                    </option>
                  );
                })}
            </select>
            <label htmlFor="workerJob">Poste*</label>
            <div style={{ marginLeft: "10px" }}>
              <AddPoste jobList={jobList} setJobList={setJobList} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        <div
          className="btn-primary btn-add"
          onClick={() => {
            ajouterEquipier();
          }}
        >
          Ajouter
        </div>
      </div>

      <div
        className="mt-1"
        style={{ borderBottom: "#232f3e solid thin" }}
      ></div>
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <SearchBarMini />
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            paddingTop: "30px",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <BallTriangle
            height={75}
            width={75}
            radius={5}
            color="#ff9900"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      {!isLoading && (
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          {filteredList &&
            filteredList.map((client) => {
              return (
                <ClientCardSidebar
                  key={client._id}
                  client={client}
                  newWorker={newWorker}
                  setNewWorker={setNewWorker}
                  scrollToTop={scrollToTop}
                  setSelectedClient={setSelectedClient}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default AddWorker;
