import { useEffect, useState } from "react";
import MiniLaunchPad from "../../components/System/miniLaunchPad";
import MiniLaunchPad2 from "../../components/System/miniLaunchPad2";

import "../../css/header.css";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import EmployeCard from "../../components/Employes/employeCard";
import config from "../../config";
import Searchbar from "../../components/Employes/searchBar"
import { BallTriangle } from "react-loader-spinner";

const Employes = ({ setTitrePage, setActiveEmploye }) => {
  const { user } = useGlobalContext();
  const [showMiniLaunchPad, setShowMiniLaunchPad] = useState(false);
  const [showMiniLaunchPad2, setShowMiniLaunchPad2] = useState(false);
  const [employes, setEmployes] = useState();
  const [error, setError] = useState(null); // State variable for error message
    const[isLoading, setIsLoading] = useState(false)

  const getEmployes = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`${config.BASE_URL}/api/employes`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch employes");
      }
      const json = await response.json();
      setEmployes(json);
      console.log(json);
    } catch (error) {
      setError(error.message); // Set error message in state variable
    }
    setIsLoading(false)
  };

  useEffect(() => {
    getEmployes();
    setTitrePage("Employés");
  }, []);

  return (
    <div className="height120">
      <div className="cointainer-fluid">
        <div className="container">
          <Searchbar />
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
          {isLoading &&
                <div className="col w-100">
                    <div style={{ display: "flex", height: "400px", paddingTop: "100px", justifyContent: "center", justifyItems: "center" }}>
                        <BallTriangle
                            height={200}
                            width={200}
                            radius={5}
                            color="#ff9900"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                        />
                    </div>

                </div>
                }
            {!isLoading && employes &&
              employes.map((employe) => {
                return (
                  <EmployeCard
                    key={employe._id}
                    employe={employe}
                    setActiveEmploye={setActiveEmploye}
                  />
                );
              })}
          </div>
        </div>
        {showMiniLaunchPad && (
          <MiniLaunchPad setShowMiniLaunchPad={setShowMiniLaunchPad} />
        )}
        {showMiniLaunchPad2 && (
          <MiniLaunchPad2 setShowMiniLaunchPad2={setShowMiniLaunchPad2} />
        )}
        {error && <p>Error: {error}</p>} {/* Display error message if there's an error */}
      </div>
    </div>
  );
};

export default Employes;
