import { useState } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext"
import { useNotify } from "../../hooks/useNotify";
import { BallTriangle } from "react-loader-spinner";
import config from "../../config";
import ProjectCard from "./projectCard";
import { useEffect } from "react";
import ProjectCardSidebar from "./projectCardSidebar";

import SearchBar from "./searchBar";


const SidebarProjects = ({setNewOrder, newOrder}) => {

    const { user } = useGlobalContext()


    const { notify } = useNotify();


    const [isLoading, setIsLoading] = useState(true);
    const [projets, setProjets] = useState();

    useEffect(()=>{
        console.log(newOrder)
    },[newOrder])


    const loadProjets = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(`${config.BASE_URL}/api/projects`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch projects");
            }
            const json = await response.json();

            if (json.length != 0) {
                setProjets(json);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(()=>{
        loadProjets()
    },[])

    return (
        <div style={{paddingLeft:"10px", paddingRight:"10px"}}>
            <SearchBar />
            <div>
                
                {isLoading && (
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "30px",
                            justifyContent: "center",
                            justifyItems: "center",
                        }}
                    >
                        <BallTriangle
                            height={75}
                            width={75}
                            radius={5}
                            color="#ff9900"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                        />
                    </div>
                )}
            </div>
            {!isLoading && (
                <div className="row row-cols-1">
                    {projets &&
                        projets.map((projet) => (
                            <ProjectCardSidebar key={projet._id} project={projet} setNewOrder={setNewOrder} newOrder={newOrder} />
                        ))}
                </div>
            )}
        </div>
    )
}

export default SidebarProjects