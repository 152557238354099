import ConnexionForm from '../components/System/connexionForm'

const LoginPage = () => {

    return (

        <div className="loginPage">
           <ConnexionForm />
        </div>

    )
}

export default LoginPage