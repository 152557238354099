import { useState, useEffect, useMemo } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { Link, useNavigate } from "react-router-dom";
import { addDays } from "date-fns";
import { DateRange } from "react-date-range";

import * as locales from "react-date-range/dist/locale";
import { useNotify } from "../../hooks/useNotify";

import config from "../../config.js";

// Import components
import AddType from "../../components/Projets/addType";
import ClientCardProjet from "../../components/Clients/clientCardProjet";

const NewProject = ({
  setTitrePage,
  setStateClientList,
  saveProjet,
  setSaveProjet,
  newProject,
  setNewProject,
  setActiveProjet,
}) => {
  const { user } = useGlobalContext();
  const [compagnies, setCompagnies] = useState();
  const [projectType, setProjectType] = useState();

  const [numberOfMonths, setNumberOfMonths] = useState(2);

  const { notify } = useNotify();
  

  const navigate = useNavigate();

  const [state, setState] = useState([
    {
      startDate: newProject.start || addDays(new Date(), 1),
      endDate: newProject.end || addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  useEffect(() => {
    console.log("State", state)

    setNewProject({
      ...newProject, // Note the lowercase 'n' in 'newProject'
      start: state[0].startDate,
      end: state[0].endDate,
    });

  }, [state]);

  useEffect(()=>{
    console.log("New Projet : ", newProject)
  },[newProject])

  const fetchCompanies = useMemo(() => {
    return async () => {
      const response = await fetch(`${config.BASE_URL}/api/Company`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        setCompagnies(json);
      }
    };
  });

  const fetchProjectType = async () => {
    const response = await fetch(`${config.BASE_URL}/api/projectType`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();

    if (response.ok) {
      setProjectType(json);
    }
  };

  useEffect(() => {
    setActiveProjet("");
    setTitrePage("Nouveau projet");
    if (!compagnies) {
      fetchCompanies();
    }
    fetchProjectType();
  }, []);

  useEffect(() => {
    // Update the number of months when the window is resized
    function handleResize() {
      setNumberOfMonths(calculateNumberOfMonths());
    }

    // Attach the event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to calculate the number of months based on screen width
  function calculateNumberOfMonths() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 3; // Set the number of months for large screens
    } else if (screenWidth >= 768) {
      return 2; // Set the number of months for medium screens
    } else {
      return 1; // Set the number of months for small screens
    }
  }

  useEffect(() => {
    if (saveProjet) {
      console.log("Calling addProjet")
      saveNewProject()
    }
  }, [saveProjet]);

  const saveNewProject = async () => {
    if (newProject.Nom === "") {
      notify("error", "Veuillez inscrire un nom de projet svp!");
    } else if (newProject.projetType.Name === "") {
      notify("error", "Veuillez sélectionner un type de projet svp!");
    } else if (newProject.Compagnie.nom === "") {
      notify(
        "error",
        "Veuillez sélectionner une compagnie pour ce projet svp!"
      );
    } else if (newProject.Equipe.length === 0) {
      notify(
        "error",
        "Veuillez ajouter un client à l'équipe de ce projet svp!"
      );
    } else {
      const equipeIds = newProject.Equipe.map((client) => client.client._id);

      const newProjet = {
        Nom: newProject.Nom,
        projectType: newProject.projetType.id,
        Compagnie: newProject.Compagnie.id,
        Start: newProject.start,
        End: newProject.end,
        Equipe: newProject.Equipe, // Use the array of client IDs
      };

      console.log("New projet : ", newProjet);

      console.log("equipeIds : ", equipeIds);

      try {
        const response = await fetch(`${config.BASE_URL}/api/projects/`, {
          method: "POST",
          body: JSON.stringify(newProjet),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        const json = await response.json();

        if (!response.ok) {
          throw new Error(json.message);
        }

        notify("success", "Nouveau projet ajouté avec succès.");
        setNewProject({
          Nom: "",
          projetType: {
            Name: "",
            id: "",
          },
          Compagnie: {
            nom: "",
            id: "",
          },
          Start: "",
          End: "",
          Equipe: [],
        });

        navigate(-1);
      } catch (error) {
        notify("error", error.message);
      }
    }

    setSaveProjet(false);
  };

  return (
    <div className="height120">
      <div className="container-fluid">
        <div className="container projet">
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="nomProjetID"
                  placeholder="Nom du projet"
                  onChange={(e) => {
                    setNewProject({
                      ...newProject,
                      Nom: e.target.value,
                    });
                  }}
                  value={newProject.Nom}
                />
                <label htmlFor="nomProjetID">Nom du projet*</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6">
              <div className="form-floating mb-3 d-flex">
                <select
                  className="form-control downIcon"
                  id="projetType"
                  placeholder="Now du projet"
                  onChange={(e) => {
                    setNewProject({
                      ...newProject,
                      projetType: {
                        Name: e.target.value,
                        id: e.target.options[
                          e.target.selectedIndex
                        ].getAttribute("id"),
                      },
                    });
                  }}
                  value={newProject.projetType.Name}
                >
                  <option value=""></option>
                  {projectType &&
                    projectType.map((project) => {
                      return (
                        <option
                          key={project._id}
                          value={project.Name}
                          id={project._id}
                        >
                          {project.Name}
                        </option>
                      );
                    })}
                </select>
                <label htmlFor="projetType">Type de projet*</label>
                <div style={{ marginLeft: "10px" }}>
                  <AddType
                    notify={notify}
                    projectType={projectType}
                    setProjectType={setProjectType}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6">
              <div className="form-floating  mb-3 d-flex">
                <select
                  className="form-control downIcon"
                  id="projetType"
                  placeholder="Nom du projet"
                  onChange={(e) => {
                    setNewProject({
                      ...newProject,
                      Compagnie: {
                        nom: e.target.value,
                        id: e.target.options[
                          e.target.selectedIndex
                        ].getAttribute("id"),
                      },
                    });
                  }}
                  value={newProject.Compagnie.nom}
                >
                  <option value=""></option>
                  {compagnies &&
                    compagnies.map((company) => {
                      const me = false;
                      if (
                        newProject &&
                        newProject.Compagnie._id == company._id
                      ) {
                        return (
                          <option
                            key={company._id}
                            value={company.companyName}
                            id={company._id}
                            selected="selected"
                          >
                            {company.companyName}
                          </option>
                        );
                      } else {
                        return (
                          <option
                            key={company._id}
                            value={company.companyName}
                            id={company._id}
                          >
                            {company.companyName}
                          </option>
                        );
                      }
                    })}
                </select>
                <label htmlFor="projetType">Compagnie de production*</label>
                <div style={{ marginLeft: "10px" }}>
                  <Link to="/Compagnies/Ajouter">
                    <div
                      className="btnPlus"
                      title="Ajouter une compagnie"
                      style={{ marginTop: "15px" }}
                    ></div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="subTitleBar">
            <div className="texte">Durée du projet</div>
          </div>
          <div className="text-center mt-4">
            <DateRange
              onChange={(item) => setState([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={numberOfMonths}
              ranges={state}
              rangeColors={["#0979be"]}
              direction="horizontal"
              locale={locales["fr"]}
              minDate={new Date()}
            />
          </div>
          <div className="subTitleBar mt-4">
            <div className="texte">Équipe</div>
            <div
              className="btnPlus"
              title="Ajouter un client à l'équipe."
              onClick={() => {
                setStateClientList({ isPaneOpen: true });
              }}
            ></div>
          </div>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mb-4 mt-3">
            {newProject.Equipe.length == 0 && (
              <div className="w-100 text-center ">
                Ajouter un client à l'équipe
              </div>
            )}

            {newProject.Equipe &&
              newProject.Equipe.map((equipier) => {
                console.log("Équipier ID: ", equipier.client._id);

                return (
                  <ClientCardProjet
                    key={equipier.client._id}
                    client={equipier}
                    setNewProject={setNewProject}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProject;
