import { useEffect, useState } from "react"
import SearchBar from "../../components/Compagnies/searchBar"
import { BallTriangle } from "react-loader-spinner";
import config from "../../config.js"
import { useGlobalContext } from "../../hooks/useGlobalContext"
import { useNotify } from "../../hooks/useNotify"
import CompanyCard from "../../components/Compagnies/companyCard"

const Compagnies = ({ setTitrePage }) => {

    const [compagnies, setCompagnies] = useState()
    const { user } = useGlobalContext()
    const { notify } = useNotify()
    const [isLoading, setIsLoading] = useState(false)

    const loadCompanies = async () => {
        console.log("Loading companies")
        setIsLoading(true)
        try {

            const response = await fetch(`${config.BASE_URL}/api/Company`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch companies");
            }
            const json = await response.json();
            setCompagnies(json);
            console.log(json);
        } catch (error) {
            notify("error", error.message); // Set error message in state variable
        }
        setIsLoading(false)
    }
    useEffect(() => {
        setTitrePage("Compagnies")
        loadCompanies()
    }, [])



    return (
        <div className="height120">

        
        <div className="container">
            <SearchBar />

            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                {isLoading &&
                <div className="col w-100">
                    <div style={{ display: "flex", height: "400px", paddingTop: "100px", justifyContent: "center", justifyItems: "center" }}>
                        <BallTriangle
                            height={200}
                            width={200}
                            radius={5}
                            color="#ff9900"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                        />
                    </div>

                </div>
                }
                
                {!isLoading && compagnies && compagnies.map((company) => {
                    return (
                        <CompanyCard key={company._id} company={company} />
                    )
                })}
            </div>
        </div>
        </div>
    )

}

export default Compagnies