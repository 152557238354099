import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from "../imgs/interface/logoSmall.png";
import config from '../config';
import { useNotify } from '../hooks/useNotify';
import { useGlobalContext } from '../hooks/useGlobalContext';

const PasswordResetPage = () => {
    const [newPassword, setNewPassword] = useState('');
    const { email, token } = useParams();
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const { user, userDispatch } = useGlobalContext()

    const { notify } = useNotify()

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${config.BASE_URL}/api/user/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, token, newPassword }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                notify("success", "Mot de passe réinitialisé avec succès.")
                navigate('/');
            } else {
                setError(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (user) {
            localStorage.removeItem("user");
            userDispatch({ type: "LOGOUT" });
        }
    }, [])

    return (
        <div className="loginPage">
            <main className="form-signin">
                <div className="logoBox">
                    <img className="logoSignin" src={logo} alt="" />
                </div>
                <h2 className="mb-3 fw-normal sousTitre">Réinitialisation</h2>

                <p>Pour le courriel: {email}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-floating">
                        <input
                            type="password"
                            className="form-control"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            placeholder="Password"
                            required
                            style={{ borderRadius: '5px' }}
                        />
                        <label htmlFor="floatingPassword">Nouveau mot de passe</label>
                    </div>
                    <button
                        type="submit"
                        className="w-100 difLoggin"
                        id="btnConnectToAccount"
                    >
                        Réinitialiser
                    </button>
                </form>
                {message && <p>{message}</p>}
                {error && <p>{error}</p>}
            </main>
        </div>
    );
};

export default PasswordResetPage;

