import { useEffect, useState } from "react"
import SearchBar from "../../components/Clients/searchBar"
import { BallTriangle } from "react-loader-spinner";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";
import config from "../../config";
import ClientCard from "../../components/Clients/clientCard";


const Clients = ({ setTitrePage, setActiveClient }) => {

    const { user } = useGlobalContext();
    const [isLoading, setIsLoading] = useState(false)
    const [clients, setClients] = useState()

    const { notify } = useNotify();

    const getClients = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(`${config.BASE_URL}/api/clients`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch clients");
            }
            const json = await response.json();
            setClients(json);
            console.log(json);
        } catch (error) {
            notify("error", error.message)
        }
        setIsLoading(false)
    };

    useEffect(() => {
        setIsLoading(true)
        setTitrePage("Clients")
        getClients()
    }, [])



    return (
        <div className="height120">

        
        <div className="container">
            <SearchBar />
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                {isLoading &&
                    <div className="col w-100">
                        <div style={{ display: "flex", height: "400px", paddingTop: "100px", justifyContent: "center", justifyItems: "center" }}>
                            <BallTriangle
                                height={200}
                                width={200}
                                radius={5}
                                color="#ff9900"
                                ariaLabel="ball-triangle-loading"
                                wrapperClass={{}}
                                wrapperStyle=""
                                visible={true}
                            />
                        </div>

                    </div>
                }
                {!isLoading && clients &&
              clients.map((client) => {
                return (
                  <ClientCard
                    key={client._id}
                    client={client}
                    setActiveClient={setActiveClient}
                  />
                );
              })}
            </div>
        </div>
        </div>
    )

}

export default Clients